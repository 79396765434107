import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  CardBody,
  CardText,
  CardImg,
  Row,
  Col,
} from "reactstrap";

import { Modal } from "reactstrap";
import axios from "axios";
import cookie from "js-cookie";

import "../css/MyAccount.css";

import LinkToBrokerForm from "./LinkForBrokerForm";
import { AppContext } from "../../AppContext/AppContext";

class LinkToBrokerModal extends Component {
  state = {
    value: ''
  }

  componentWillUnmount() {
    this.props.resetModal("LinkToBroker");
    this.props.resetModal('ChangeBrokerModal')
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  submit(values) {
    const token = cookie.get("token");
    axios.post(
      `https://server.gcmx.bluetag.tech/api/UserManager/ChangeBroker?BrokerID=${values.selectedBroker}&CanOrder=${values.canOrder}&CanWithdraw=${values.canWithdraw}&Reason=${this.state.value}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        this.props.handleMessage(res.data);
        this.props.setShowAlert();
        setTimeout(() => {
          this.props.setShowAlert();
        }, 3000);
      })
      .catch((err) => { });
  }


  static contextType = AppContext;

  render() {
    const { toggleDarkMode, brokerFormDetails } = this.context;
    const currentBroker = this.props.data.find((broker) => broker.broker_id === this.props.userProfile.linked_broker_id)

    const selectedBroker = this.props.data.find((broker) => broker.broker_id === brokerFormDetails.selectedBroker)
    return (
      <>
        <Modal
          size="xl"
          className="modal-dialog-centered"
          isOpen={this.props.LinkToBroker}
          toggle={() => this.props.toggleModal("LinkToBroker")}
        >
          <div
            className="modal-body"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6
                  className="modal-title"
                  id="modal-title-default"
                  style={toggleDarkMode ? { color: "white" } : null}
                >
                  Link to Broker
                </h6>
              </div>
              <div className="col-1">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("LinkToBroker")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>

            {this.props.userProfile.linked_broker_id && (
              <>
                <p style={{marginLeft: "40px", fontWeight: 'bold'}}>Current Broker</p>
                <Row key={1}>
                  <Col lg={3}>
                    <Card
                      className="cardItem"
                      style={{
                        padding: "1px",
                        marginLeft: '47px',
                        backgroundColor: toggleDarkMode ? "#495057" : "",
                      }}
                    >
                      <CardImg
                        top
                        src={currentBroker.photo || "https://i.ibb.co/Mn7dbq3/avatar.jpg"}
                        width="100%"
                        height="150"
                      />
                      <CardBody
                        style={{
                          backgroundColor: toggleDarkMode ? "black" : ""
                        }}
                      >
                        <CardText className="cardTextName">
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px",  lineHeight: "12px"}}
                            >
                            {currentBroker.broker_name}

                          </span>
                        </CardText>
                        <CardText
                          className="cardText"
                          style={{ fontSize: "12px" }}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                          >
                            ID: {currentBroker.broker_id}
                          </span>
                        </CardText>
                        <CardText
                          className="cardText"
                          style={{ color: "#EAA221", fontSize: "10px" }}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                          >
                            4.0
                          </span>{" "}
                          <i className="fas fa-star"></i>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <LinkToBrokerForm
              toggleModal={this.props.toggleModal}
              userBroker={this.props.userProfile}
              modalProps={this.props}
              data={this.props.data}
            />
            <Modal
              size="l"
              className="modal-dialog-centered"
              isOpen={this.props.ChangeBroker}
              toggle={() => this.props.toggleModal("ChangeBrokerModal")}
            >
              <div
                className="modal-body"
                style={
                  toggleDarkMode
                    ? { backgroundColor: "black", color: "white" }
                    : null
                }
              >
                <div className="row">
                  <div className="col" style={{ textAlign: "center" }}>
                    <h6
                      className="modal-title"
                      id="modal-title-default"
                      style={toggleDarkMode ? { color: "white" } : null}
                    >
                      
                    </h6>
                  </div>
                  <div className="col-1">
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.props.toggleModal("ChangeBrokerModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                </div>
                <p>DISCLAIMER: <b>Please kindly ensure that the broker below is the broker chosen by you as GCMX will not bear the burden of any loss incurred as a result of a wrong broker! </b></p>
                <Row key={1}>
                  <Col lg={6}>
                    <Card
                      className="cardItem"
                      style={{
                        padding: "1px",
                        marginLeft: '30px',
                        backgroundColor: toggleDarkMode ? "#495057" : "",
                      }}
                    >
                      <CardImg
                        top
                        src={selectedBroker?.photo || "https://i.ibb.co/Mn7dbq3/avatar.jpg"}
                        width="100%"
                        height="150"
                      />
                      <CardBody
                        style={{
                          backgroundColor: toggleDarkMode ? "black" : ""
                        }}
                      >
                        <CardText className="cardTextName">
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px",  lineHeight: "12px"}}
                          >
                            {selectedBroker?.broker_name}

                          </span>
                        </CardText>
                        <CardText
                          className="cardText"
                          style={{ fontSize: "12px" }}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                          >
                            ID: {selectedBroker?.broker_id}
                          </span>
                        </CardText>
                        <CardText
                          className="cardText"
                          style={{ color: "#EAA221", fontSize: "10px" }}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                          >
                            4.0
                          </span>{" "}
                          <i className="fas fa-star"></i>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row>
                  <div className="col">
                    <input
                      type="text"
                      placeholder="Type reason for changing broker..."
                      className="form-control textbox-custom"
                      value={this.state.value}
                      onChange={this.handleChange}

                      style={
                        toggleDarkMode
                          ? {
                            backgroundColor: "#495057",
                            color: "white"
                          }
                          : {
                            border: "1px solid red",
                          }
                      }
                    />
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-block btn-success button-default"
                      style={{ background: "#24B086" }}
                      type="submit"
                      disabled={this.state.value === ''}
                      onClick={() => { this.submit(brokerFormDetails); this.props.toggleModal("LinkToBroker") && this.props.toggleModal("ChangeBrokerModal") }}
                    >
                      <span style={{ fontSize: "14px" }}>SUBMIT</span>
                    </button>
                  </div>
                </Row>
              </div>
            </Modal>
          </div>
        </Modal>
      </>
    );
  }
}

export default LinkToBrokerModal;


