/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
 
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { loggedIn, userHasPermission } from "../services/auth.service";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";
import "./Admin.css";
import { AppContext } from "../AppContext/AppContext";
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // @ts-ignore
    this.refs.mainContent.scrollTop = 0;
  }
  // getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       document.body.style.background = "#fafafa";
  //       var Rjh= prop.component;
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        document.body.style.background = "#fafafa";
        var Componemt = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(props) =>
              loggedIn() ? (
                userHasPermission(prop.path) ? (
                  <Componemt {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/auth/error-401",
                      state: { from: props.location },
                    }}
                  />
                )
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth/login",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  static contextType = AppContext;
  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        {window.location.pathname !== "/admin/market" &&
          window.location.pathname !== "/admin/orderbook" &&
          window.location.pathname !== "/admin/error-401" && (
            <>
              <Sidebar
                {...this.props}
                routes={routes}
                logo={{
                  innerLink: "/admin/market",
                  imgSrc: require("../components/img/logo.svg"),
                  imgAlt: "...",
                }}
              />
            </>
          )}

        <div
          className="main-content"
          ref="mainContent"
          style={{ backgroundColor: toggleDarkMode ? "#495057" : null }}
        >
          <AdminNavbar />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/market" />
          </Switch>
          <AdminFooter />
        </div>
      </>
    );
  }
}

export default Admin;
