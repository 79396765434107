/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  Row,
  Container,
  Col,
  Label,
  Spinner,
} from "reactstrap";

import "./overview.css";
import Visa from "../../assets/img/icons/common/visa.svg";
//import  Master from '../assets/img/icons/common/mastercard.svg';
import Master from "../../assets/img/icons/common/mastercard.svg";
import VisaDark from "../../assets/img/icons/common/visa-dark.svg";
import MasterDark from "../../assets/img/icons/common/mastercard-dark.svg";
import axios from "axios";
import settings from "../../settings";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";
import { whatRoleIsThis } from "../../services/auth.service";

//Bank3D deposits money in Kobo and the data type is a string.
//Therefore customer's estimated balance is in kobo.
const Bank3D = window.Bank3D;
function rand(length, current) {
  current = current ? current : "";
  return length
    ? rand(
        --length,
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".charAt(
          Math.floor(Math.random() * 60)
        ) + current
      )
    : current;
}
const Deposit = (props) => {
  //console.log(props);
  const { fetchEWRRetrieval, toggleDarkMode } = useContext(AppContext);
  const types = props.location.pathname.includes("spot") ? "spot" : "";
  //console.log(types);
  const [ewrError, setewrError] = useState("");
  const [submitEwr, setsubmitEwr] = useState(false);
  const [state, setState] = useState({
    market: [
      {
        title: "Spot",
        naira: "0.00",
        dollar: "0.00",
      },
      {
        title: "Future",
        naira: "0.00",
        dollar: "0.00",
      },
    ],
    depositAmount: "",
    ewrNumber: "",
  });

  const onHandleSubmit = (event) => {
    event.preventDefault();
    //console.log(state);
    if (parseInt(state.depositAmount) >= 500 && parseInt(state.depositAmount) <= 100000000)/* state.depositAmount.length */ {
      //console.log(parseInt(state.depositAmount));
      const amount = parseInt(state.depositAmount) * 100;
      const payment = Bank3D.createPayment({
        reference: rand(10), // generates a pseudo-unique reference. Please replace with a reference you generated.
        currencyCode: "NGN",
        merchantKey: "PayzoneAPP", // use your test or live merchant key
        amount: amount,
        email: "test@gmail.com",
        phone: "0801234567889",
        color: "#24B086",
        mode: "test",
        onReady: function () {
          // The popup's iframe has loaded and it's preloader is visible
          // Use this to stop you own custom preloader
        },
        onClose: function () {
          // The user closed the popup or transaction was not completed
        },
        callback: function (res) {
          //console.log(res);
          const values = {
            amount: res.amount,
            transactionType: "debit",
            channel: "Bank3D",
            channelReferenceNumber: res.reference,
          };
          console.log(values);
          const token = cookie.get("token");
          const headers = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          axios
            .post(
              settings.baseApi + "/Transaction/CardDeposit",
              values,
              headers
            )
            .then((resp) => {
              //console.log(resp);
              props.history.push({
                pathname: "/admin/overview",
              });
            })
            .catch((err) => {
              //console.log(err.error);
            });
          // Transaction was completed and it was successfull.
          // This is the stage where it's necessary to verify the payment using "reference" argument
        },
      });
      payment.open();
    }
    if (state.ewrNumber.length) {
      setsubmitEwr(true);
      fetchEWRRetrieval(state.ewrNumber)
        .then((res) => {
          //console.log(res.data);
          setsubmitEwr(false);
          props.history.push({
            pathname: "/admin/ewr",
            ewrNumber: state.ewrNumber,
            data: res.data,
          });
        })
        .catch((err) => {
          //console.log(err);
          setewrError("Unable to retrieve Contract");
          setTimeout(() => {
            setewrError("");
          }, 6000);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let link;

  (parseInt(state.depositAmount) >= 500 && parseInt(state.depositAmount) <= 100000000)?
    link = <Link
      to={{
        pathname: "/admin/checkout",
        state: { amount: state.depositAmount }
      }}
      className="btn btn-block mb-2 text-light btn-success btn-next"
    >
      Deposit
    </Link>
  : link = <Link
      to={{
        
      }}
      className="btn btn-block mb-2 text-light btn-success btn-next"
    >
      Deposit
    </Link>

  


  return (
    <>
      <Container className={toggleDarkMode ? "bg-dark" : "bg-light"} fluid>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb pl-0">
            <li className="breadcrumb-item">
              <Link
                to={
                  whatRoleIsThis() === "Broker"
                    ? "/admin/broker-wallet"
                    : "/admin/overview"
                }
                style={toggleDarkMode ? { color: "white" } : null}
              >
                Overview
              </Link>
            </li>
            <li
              className="breadcrumb-item active"
              aria-current="page"
              style={toggleDarkMode ? { color: "white" } : null}
            >
              Deposit
            </li>
          </ol>
        </nav>
        <h6 className="" style={toggleDarkMode ? { color: "white" } : null}>
          Deposit
        </h6>
        <Row>
          {types != "spot" && (
            <>
              <div className="col mb-5 mb-xl-0">
                <Card
                  className={
                    toggleDarkMode
                      ? "deposit-card pb-5 dark"
                      : "deposit-card pb-5"
                  }
                >
                  <div className="py-2 ">
                    <div className="text-muted pb-5 mb-4">
                      <div className="paywith px-4">
                        <small
                          className="small-title"
                          style={toggleDarkMode ? { color: "white" } : null}
                        >
                          Pay With
                        </small>
                      </div>
                      <Row className="paywith mb-5">
                        <Col md="8" className="px-4 pt-2">
                          <div className="">
                            <input
                              onChange={() => console.log("value")}
                              type="radio"
                              id="test1"
                              name="radio-group"
                              checked
                            />
                            <label
                              htmlFor="test1"
                              style={toggleDarkMode ? { color: "white" } : null}
                            >
                              Credit or Debit Card
                            </label>
                          </div>
                        </Col>
                        <Col md="4" className="px-4 pt-2 ">
                          <img
                            className="debit-card"
                            src={toggleDarkMode ? MasterDark : Master}
                            alt="Mastercard Logo"
                            style={
                              toggleDarkMode
                                ? { backgroundColor: "dark" }
                                : null
                            }
                          />
                          <img
                            className="debit-card"
                            src={toggleDarkMode ? VisaDark : Visa}
                            alt="Visa Logo"
                          />{" "}
                          <br />
                          <small
                            className="small-text"
                            style={toggleDarkMode ? { color: "white" } : null}
                          >
                            All Major Cards Accepted
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col mb-5 mb-xl-0">
                <Card
                  className={
                    toggleDarkMode
                      ? "deposit-card pt-3 dark"
                      : "deposit-card pt-3"
                  }
                >
                  <div className="text-muted">
                    <Row>
                      <Col md="12" className="px-4 deposit-note">
                        <p
                          className="mb-0"
                          style={{
                            color: toggleDarkMode ? "white" : null,
                          }}
                        >
                          Please note: &nbsp; Minimum deposit: NGN 500.00
                        </p>
                        <p
                          className="text-center"
                          style={{
                            color: toggleDarkMode ? "white" : null,
                          }}
                        >
                          &nbsp; Maximum deposit: NGN 100,000,000.00
                        </p>
                      </Col>
                    </Row>
                    <Form onSubmit={onHandleSubmit}>
                      <div id="bank3DEmbeddedContainer"> </div>
                      <Row>
                        <Col md="12" className="px-4 my-3">
                          <Label className="mb-0">
                            <span
                              style={{
                                color: toggleDarkMode ? "white" : null,
                              }}
                            >
                              How much will you like to deposit?
                            </span>
                          </Label>
                          <Input
                            name="depositAmount"
                            onChange={handleInputChange}
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="mt-5 px-4">
                          <Button onClick={onHandleSubmit} className="btn btn-block mb-2  btn-success btn-next">
                            Next
                          </Button>
                          {/* {link} */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
              </div>
                
            </>
          )}
          {types == "spot" && (
            <>
              <div className="col mb-5 mb-xl-0">
                <Card
                  className={
                    toggleDarkMode
                      ? "deposit-card pb-5 dark"
                      : "deposit-card pb-5"
                  }
                >
                  <div className="py-2 ">
                    <div className="text-muted pb-5 mb-4">
                      <div className="paywith px-4">
                        <small
                          className="small-title"
                          style={toggleDarkMode ? { color: "white" } : null}
                        >
                          Pay With
                        </small>
                      </div>
                      <Row className="paywith mb-5">
                        <Col md="9" className="px-4 pt-2">
                          <div className="">
                            <input
                              onChange={() => console.log("value")}
                              type="radio"
                              id="ewr"
                              name="radio-group"
                              checked
                            />
                            <label
                              htmlFor="ewr"
                              style={toggleDarkMode ? { color: "white" } : null}
                            >
                              Contract
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col mb-5 mb-xl-0">
                <Card
                  className={
                    toggleDarkMode
                      ? "deposit-card pt-3 dark"
                      : "deposit-card pt-3"
                  }
                >
                  <div className="text-muted">
                    <Row>
                      {ewrError && (
                        <Col md="12" className="px-4 text-center">
                          <span className="text-danger">{ewrError}</span>
                        </Col>
                      )}
                    </Row>
                    <Form onSubmit={onHandleSubmit}>
                      <Row>
                        <Col md="12" className="px-4 my-3">
                          <Label
                            className={
                              toggleDarkMode
                                ? "dark mb-0 ewr-label"
                                : "mb-0 ewr-label"
                            }
                          >
                            Enter Contract Number
                          </Label>
                          <Input
                            name="ewrNumber"
                            onChange={handleInputChange}
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="mt-5 px-4">
                          <Button className="btn btn-block mb-2  btn-success btn-next">
                            Next{" "}
                            {submitEwr && (
                              <Spinner
                                style={{ marginBottom: "4px" }}
                                size="sm"
                                color="light"
                              />
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
              </div>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Deposit;
