import { AppContext } from '../../AppContext/AppContext';
import React, { useContext, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Modal } from 'reactstrap';
import "./transactionsummary.css";
import WithdrawalOTPForm from './OTPForm';
import "bootstrap/dist/css/bootstrap.min.css";

const TransactionSummary = () => {
  const location = useLocation();
  const { toggleDarkMode, fetchOTP } = useContext(AppContext);
  const [OTPForm, setOTPForm] = useState(false);
  const [success, setSuccess] = useState(false);
  console.log(location.state.acctDetail);
  console.log(location.state.data);
  console.log(location.state.amount);
  return (
    <>
      <Container fluid id="body">
        <Row>
          <Col>
            <h4>
              Transaction Summary
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div className="col-6 mt-5 mb-xl-0">
            <Card className="summary-card">
              <p><span className='form-entry'>Customer Name:</span> {location.state.data.fullname}</p>
              <p><span className='form-entry'>Customer ID:</span> {location.state.data.customer_id}</p>
              <p><span className='form-entry'>Commodity:</span></p>
              <p className='amount'><span className='form-entry'>Amount to be withdrawn:</span> {location.state.amount + ".00"}</p>
              <div className='account-details'>
                <p>Account Details</p>
                <p>{location.state.acctDetail}</p>
                <button className="borderless-button">Confirm Account Details</button>
              </div>
              <button className='submit-button' onClick={() => { fetchOTP(); setOTPForm(!OTPForm) }}>Submit</button>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={OTPForm}
        toggle={() => setOTPForm(!OTPForm)}
      >
        <div
          className="modal-body"
          style={
            toggleDarkMode
              ? { backgroundColor: "black", color: "white" }
              : null
          }
        >
          <div className="row justify-content-right">
            <div className="col">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  setOTPForm(!OTPForm)
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          {success ?
            <>
              <div className="success-message" style={{ textAlign: "center" }}>
                <i className="far fa-check-circle"></i>

                <h1 style={{ color: toggleDarkMode ? "white" : "" }}>Success</h1>
              </div>
              <div className="row justify-content-center">
                <div className="col-6">
                  <button
                    className="btn btn-block btn-success button-default"
                    style={{ background: "#24B086", marginBottom: "45px" }}
                    onClick={() => {
                      setOTPForm(!setOTPForm);
                      setSuccess(!success);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </> : <WithdrawalOTPForm success={success} setSuccess={setSuccess} acctDetail={location.state.acctDetail} data={location.state.data} amount={location.state.amount} />}
        </div>
      </Modal>
    </>
  )
}

export default TransactionSummary;