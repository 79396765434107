var deliveryHistory = [
  /* {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Rice",
    grade: "A",
    quantity_requested: "300,000",
    delivery_fee: "7,000,000",
    delivery_date: "09-08-2020",
    status: "Delivered",
    action: "View Details",
  },
  {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Soya Beans",
    grade: "A",
    quantity_requested: "300,000",
    delivery_fee: "250,000",
    delivery_date: "09-08-2020",
    status: "Delivered",
    action: "View Details",
  },
  {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Sesame Seeds",
    grade: "A",
    quantity_requested: "300,000",
    delivery_fee: "500,000,000",
    delivery_date: "-",
    status: "In Dispute",
    action: "View Details",
  },
  {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Maize",
    grade: "B",
    quantity_requested: "300,000",
    delivery_fee: "500,000",
    delivery_date: "-",
    status: "En route",
    action: "View Details",
  },
  {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Rice",
    grade: "A",
    quantity_requested: "300,000",
    delivery_fee: "10,000,000",
    delivery_date: "-",
    status: "Pending",
    action: "View Details",
  },
  {
    date_requested: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    delivery_id: "GEZAWA/DEL/001",
    commodities: "Soya Beans",
    grade: "A",
    quantity_requested: "300,000",
    delivery_fee: "5,000,000",
    delivery_date: "-",
    status: "Pending",
    action: "View Details",
  }, */
];

export default deliveryHistory;
