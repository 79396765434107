import styled from "styled-components";

export const NavWrapper = styled.nav`
background: var(--light);
.nav-link {
  color: var(--lightPink) !important;
  font-size: 1.3rem;
  text-transform: capitalize;
  
`;
