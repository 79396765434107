import React, { useContext, useEffect, useState } from "react";
import "./Spots.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASEURL } from "./baseUrl";
import { getToken } from "../../services/auth.service";
import { AppContext } from "../../AppContext/AppContext";

const MarketTab = ({ product, context }) => {
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [error, setError] = useState(false);
  const [totalBuyAmount, setTotalBuyAmount] = useState(0);
  const [totalSellAmount, setTotalSellAmount] = useState(0);
  const [errorMessages, setErrorMessages] = useState({});
  const { register, handleSubmit, errors, reset: buyReset } = useForm();
  const {
    register: sellRegister,
    handleSubmit: handleSellSubmit,
    reset: sellReset,
  } = useForm();
  const token = getToken();
  let buyUrl, sellUrl;
  if (context) {
    buyUrl = "BuyOrderForBroker";
    sellUrl = "SellOrderForBroker";
  } else {
    buyUrl = "BuyOrder";
    sellUrl = "SellOrder";
  }

  const buySubmit = (data) => {
    //console.log(data);
    setTotalBuyAmount(parseInt(data.Price) * parseInt(data.Quantity) + 500);
    let newData = {
      ...data,
      CustomerID: product.customer_id,
      InstructionID: product.instruction_id,
    };

    setLoading(true);
    axios
      .post(`${BASEURL}/${buyUrl}`, context ? data : null, {
        params: !context ? newData : {},
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res);
        setMessage(res.data);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
        setLoading(false);
        setTotalBuyAmount(0);
      })
      .catch((e) => {
        //console.log(e.response.data);
        setError(true);
        setErrorMessages(e?.response?.data?.errors || e?.response?.data);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
        setLoading(false);
        setTotalBuyAmount(0);
      });
    buyReset();
  };
  const sellSubmit = (data) => {
    //console.log(data);
    setTotalSellAmount(parseInt(data.Price) * parseInt(data.Quantity) + 500);
    let newData = {
      ...data,
      CustomerID: product.customer_id,
      InstructionID: product.instruction_id,
    };
    setSellLoading(true);
    axios
      .post(`${BASEURL}/${sellUrl}`, context ? data : null, {
        params: !context ? newData : {},
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res);
        setMessage(res.data);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
        setSellLoading(false);
        setTotalSellAmount(0);
      })
      .catch((e) => {
        setError(true);
        setErrorMessages(e?.response?.data?.errors || e?.response?.data);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
        setSellLoading(false);
        setTotalSellAmount(0);
      });
    sellReset();
  };

  let values;
  if (error) {
    values = typeof errorMessages === "object" && Object.values(errorMessages);
  }

  const { toggleDarkMode } = useContext(AppContext);

  return (
    <div className="limit_container">
      {showAlert && (
        <div
          className={error ? "alert alert-danger" : "alert alert-success"}
          role="alert"
        >
          {!error
            ? message
            : !values
            ? errorMessages
            : values.map((value) => <span>{value}</span>)}
        </div>
      )}
      <div className="container">
        <div className="row" style={{ marginRight: 0 }}>
          <div className="sm-6" style={{ marginRight: 25, width: "47%" }}>
            <form onSubmit={handleSubmit(buySubmit)}>
              <div className="form-group">
                <div className="customerBody">
                  <span
                    className="customerTitle"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    Buy {product.commodity} (kg)
                  </span>
                  <span
                    className="customerTitle"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    {product.price}
                  </span>
                </div>
                <input
                  disabled
                  name="Price"
                  ref={register}
                  type="text"
                  className={
                    toggleDarkMode
                      ? "MyformControl price-input dark"
                      : "MyformControl price-input"
                  }
                  id="exampleInputEmail1"
                  placeholder="Market Price"
                  aria-describedby="emailHelp"
                  style={{
                    color: toggleDarkMode ? "white" : "#333333",
                    backgroundColor: toggleDarkMode ? "#495057" : "#e8e8e8",
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  name="Quantity"
                  ref={register}
                  type="text"
                  placeholder="Quantity (kg)"
                  className={
                    toggleDarkMode
                      ? "MyformControl price-input dark"
                      : "MyformControl price-input"
                  }
                  id="exampleInputPassword1"
                  style={{
                    marginTop: -8,
                    backgroundColor: toggleDarkMode ? "#495057" : "",
                    color: toggleDarkMode ? "white" : "",
                  }}
                  defaultValue={
                    product?.trade_side === "Buy"
                      ? Math.floor(product?.quantity)
                      : null
                  }
                />
              </div>
              <div className="form-group" style={{ marginTop: -7 }}>
                <div className="customerBody">
                  <span
                    className="limitSubtitle1"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    Amount (NGN)
                  </span>
                  <span
                    className="limitSubtitle2"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    {totalBuyAmount}
                  </span>
                </div>
              </div>
              <button type="submit" className="submitBtn ">
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "  Buy "
                )}
              </button>
            </form>
          </div>
          <div className="sm-6" style={{ marginLeft: 0, width: "47%" }}>
            <form onSubmit={handleSellSubmit(sellSubmit)}>
              <div className="form-group">
                <div className="customerBody">
                  <span
                    className="customerTitle"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    Sell {product.commodity} (kg)
                  </span>
                  <span
                    className="customerTitle"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    {product.price}
                  </span>
                </div>
                <input
                  disabled
                  name="Price"
                  ref={sellRegister}
                  type="text"
                  className={
                    toggleDarkMode
                      ? "MyformControl price-input dark"
                      : "MyformControl price-input"
                  }
                  id="exampleInputEmail1"
                  placeholder="Market Price"
                  aria-describedby="emailHelp"
                  style={{
                    color: toggleDarkMode ? "white" : "#333333",
                    backgroundColor: toggleDarkMode ? "#495057" : "#e8e8e8",
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  name="Quantity"
                  ref={sellRegister}
                  type="text"
                  className={
                    toggleDarkMode
                      ? "MyformControl price-input dark"
                      : "MyformControl price-input"
                  }
                  placeholder="Quantity (kg)"
                  id="exampleInputPassword1"
                  style={{
                    marginTop: -8,
                    backgroundColor: toggleDarkMode ? "#495057" : "",
                    color: toggleDarkMode ? "white" : "",
                  }}
                  defaultValue={
                    product?.trade_side === "Sell"
                      ? Math.floor(product?.quantity)
                      : null
                  }
                />
              </div>
              <div className="form-group" style={{ marginTop: -7 }}>
                <div className="customerBody">
                  <span
                    className="limitSubtitle1"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    Amount (NGN)
                  </span>
                  <span
                    className="limitSubtitle2"
                    style={toggleDarkMode ? { color: "white" } : null}
                  >
                    {totalSellAmount}
                  </span>
                </div>
              </div>
              <button type="submit" className="submitSellBtn">
                {sellLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "  Sell "
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketTab;
