import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { useState } from "react";
import { Table } from "reactstrap";
import Pagination from "../Market/Pagination";

const Instruction = ({ instruction }) => {
  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost1 = currentPage * postsPerPage;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage;
  const currentPosts1 = instruction.slice(indexOfFirstPost1, indexOfLastPost1);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <div>
      <Table bordered className="mb-2">
        <thead>
          <tr
            style={{
              color: toggleDarkMode ? "white" : null,
              backgroundColor: toggleDarkMode ? "#495057" : null,
            }}
          >
            <th scope="col">Date</th>
            <th scope="col">Instruction ID</th>
            <th scope="col">Commodities</th>
            <th scope="col">Side</th>
            <th scope="col">Type</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price (NGN)</th>
            <th scope="col">Amount (NGN)</th>
            <th scope="col">Executed</th>
            <th scope="col">Status</th>
            <th scope="col">Placed By</th>
          </tr>
        </thead>
        <tbody
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {currentPosts1.map((instruct, index) => (
            <tr key={index}>
              <td>{instruct.date}</td>
              <td>{instruct.instruction_id}</td>
              <td>{instruct.commodity} (kg)</td>
              <td style={{ color: "tomato" }}>{instruct.trade_side} </td>
              <td>{instruct.market_type}</td>
              <td>{instruct.quantity}</td>
              <td>{instruct.price}</td>
              <td>{instruct.amount}</td>
              <td>Nil</td>
              <td>{instruct.status}</td>
              <td>{instruct.customer_name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={instruction.length}
        paginate={paginate}
      />
    </div>
  );
};

export default Instruction;
