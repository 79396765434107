import React, { useState, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "./Spots.css";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";

function ActionFormatter(cell, row, props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  //console.log(row);
  return (
    <div>
      <Link to={{ pathname: "/admin/orderbook", state: { row } }}>
        <button className="btn btn-outline-success btn-sm">Trade</button>
      </Link>
    </div>
  );
}

const columns = [
  {
    dataField: "commodity",
    text: "Commodities",
    sort: true,
    headerStyle: { minWidth: "100px" },
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "uom",
    text: "UoM",
    sort: true,
    headerStyle: { minWidth: "50px" },
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "grade",
    text: "Grade",
    sort: true,
    headerStyle: { minWidth: "60px" },
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "total_quantity",
    text: "Total Qty",
    sort: true,
    headerStyle: { minWidth: "100px" },
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "total_available",
    text: "Total Available",
    sort: true,
    headerStyle: { minWidth: "120px" },
    style: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  {
    dataField: "in_order",
    text: "In order",
    headerStyle: { minWidth: "100px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  {
    dataField: "loan",
    text: "Loan",
    headerStyle: { minWidth: "100px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  {
    dataField: "last_price",
    text: "Last Price",
    headerStyle: { minWidth: "100px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "_24_hr_change",
    text: "24th Change",
    headerStyle: { minWidth: "100px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#FD665E",
    },
  },
  {
    dataField: "_24_hr_high",
    text: "24th High",
    headerStyle: { minWidth: "50px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "_24_hr_low",
    text: "24th Low",
    headerStyle: { minWidth: "50px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "product_value",
    text: "Product Value",
    headerStyle: { minWidth: "50px" },
    sort: true,
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  {
    dataField: "action",
    text: "Action",
    formatter: (row, value) => (
      <div className="dropdown dropdown-action text-right">
        <a
          href="#"
          className="action-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          Action
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <Link
            className="dropdown-item"
            to={{
              pathname: "/admin/orderbook",
              state: { row: value, context: "personalBroker" },
            }}
          >
            {" "}
            Trade
          </Link>
          <Link
            className="dropdown-item"
            to={{ pathname: "/admin/request-delivery", state: { row: value } }}
          >
            {" "}
            Take Delivery
          </Link>
        </div>
      </div>
    ),
    style: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
];
const { SearchBar, ClearSearchButton } = Search;

const defaultSorted = [
  {
    dataField: "commodities",
    order: "desc",
  },
];

const SpotTable = ({ products }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <ToolkitProvider keyField="id" data={products} columns={columns} search>
      {(props) => (
        <div>
          <SearchBar
            {...props.searchProps}
            style={{ marginBottom: 15, paddingLeft: "12%" }}
            className={toggleDarkMode ? "inputSearch dark" : "inputSearch"}
          />

          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            filter={filterFactory()}
            headerClasses={
              toggleDarkMode ? "header-class dark" : "header-class"
            }
            bodyClasses={toggleDarkMode ? "dark-table" : ""}
            classes="table table-responsive"

            // defaultSorted={defaultSorted}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default SpotTable;
