import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  CardTitle,
  Spinner,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import settings from "../../../settings";
import { AppContext } from "../../../AppContext/AppContext";
const Register = () => {
  let history = useHistory();
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    //console.log(showPhoneError);
  }, [showPhoneError]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const { toggleDarkMode } = useContext(AppContext);
  const navStyles = {
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };
  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        othername: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        agree: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        //console.log(values);
        if (values.phone.length < 7) {
          setShowPhoneError(true);
        } else {
          setSubmit(true);
          setTimeout(() => {
            axios
              .post(settings.baseApi + "/UserManager/CreateUser", values)
              .then((res) => {
                //console.log(res);
                setSubmit(false);
                if (res.data === "User already exist") {
                  setShow(true);
                  setMessage("User already exist");
                } else if (res.data === "Successful") {
                  history.push("auth/login");
                }
              })
              .catch((err) => {
                //console.log(err.response.data);
                setShow(true);
                setMessage("Error creating user");
              });
            setSubmitting(false);
          }, 500);
        }
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string().required("Firstname is required"),
        lastname: Yup.string().required("Lastname is required"),
        othername: Yup.string().required("Lastname is required"),
        email: Yup.string()
          .email("E-mail is not valid!")
          .required("Email is required"),
        agree: Yup.boolean()
          .required("Required")
          .oneOf([true], "You must accept the GCMX Terms of Service."),
        password: Yup.string()
          .required("No password provided.")
          .min(6, "Password is too short - should be 6 chars minimum"),
        confirmPassword: Yup.string()
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          })
          .required("Please confirm your password"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
        } = props;

        return (
          <div className="d-flex justify-content-center w-100 mt-3">
            <Col lg="6" md="12">
              <Card
                className={
                  toggleDarkMode
                    ? "shadow border-0"
                    : "bg-white shadow border-0"
                }
                style={toggleDarkMode ? { backgroundColor: "black" } : null}
              >
                <CardBody className="px-lg-5 py-lg-5">
                  <div
                    className="text-muted text-center mt-2 mb-4"
                    style={{ fontFamily: "Poppins" }}
                  >
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Create a free account
                    </h3>
                    <small
                      style={{
                        fontFamily: "Poppins",
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Welcome to Gezawa Commodity Exchange
                    </small>
                  </div>
                  <div className="text-center text-danger">
                    {showPhoneError && <small>Phone Number is required</small>}
                    {show && <small>{message}</small>}
                  </div>
                  <form onSubmit={handleSubmit}>
                    <Nav tabs className={toggleDarkMode ? "" : "nav-border"}>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1");
                          }}
                          style={navStyles}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            Name
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggle("2");
                          }}
                          style={navStyles}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            Email
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row className="mt-3">
                          <Col md="12">
                            <FormGroup>
                              <Label for="firstname">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  First Name
                                </span>
                              </Label>
                              <Input
                                value={values.firstname}
                                type="text"
                                name="firstname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.firstname &&
                                  touched.firstname &&
                                  "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.firstname && touched.firstname && (
                                <div className="input-feedback float-left">
                                  {errors.firstname}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="lasstname">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Last Name
                                </span>
                              </Label>
                              <Input
                                value={values.lastname}
                                type="text"
                                name="lastname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.lastname && touched.lastname && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.lastname && touched.lastname && (
                                <div className="input-feedback float-left">
                                  {errors.lastname}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="othername">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Other Name
                                </span>
                              </Label>
                              <Input
                                value={values.othername}
                                type="text"
                                name="othername"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.othername &&
                                  touched.othername &&
                                  "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.othername && touched.othername && (
                                <div className="input-feedback float-left">
                                  {errors.othername}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="phone">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Phone
                                </span>
                              </Label>
                              <PhoneInput
                                country={"ng"}
                                name="phone"
                                value={values.phone}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  values.phone = e.currentTarget.value;
                                }}
                                className={
                                  errors.phone && touched.phone && "error"
                                }
                                inputStyle={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                                buttonStyle={
                                  toggleDarkMode
                                    ? { backgroundColor: "#495057" }
                                    : null
                                }
                                dropdownStyle={
                                  toggleDarkMode
                                    ? { backgroundColor: "#495057" }
                                    : null
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <div
                              className="nextBtn w-100"
                              onClick={() => {
                                toggle("2");
                              }}
                              style={{
                                marginBottom: "10px",
                                paddingTop: "13px",
                                cursor: "pointer",
                              }}
                            >
                              Next
                            </div>
                            <div className="p-content">
                              <p
                                style={{
                                  color: toggleDarkMode ? "white" : null,
                                }}
                              >
                                Already registered?{" "}
                                <a
                                  className="login-from-register"
                                  href="/auth/login"
                                >
                                  Log in
                                </a>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row className="mt-3">
                          <Col md="12">
                            <FormGroup>
                              <Label for="email" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Email Address
                                </span>
                              </Label>
                              <Input
                                value={values.email}
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.email && touched.email && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.email && touched.email && (
                                <div className="input-feedback float-left">
                                  {errors.email}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="password" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Password
                                </span>
                              </Label>
                              <Input
                                value={values.password}
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.password && touched.password && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.password && touched.password && (
                                <div className="input-feedback float-left">
                                  {errors.password}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="confirmPassword" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Confirm Password
                                </span>
                              </Label>
                              <Input
                                value={values.confirmPassword}
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.confirmPassword &&
                                  touched.confirmPassword &&
                                  "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.confirmPassword &&
                                touched.confirmPassword && (
                                  <div className="input-feedback float-left">
                                    {errors.confirmPassword}
                                  </div>
                                )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup check>
                              <Label check className="w-100">
                                <Input
                                  value={values.agree}
                                  type="checkbox"
                                  name="agree"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.agree && touched.agree && "error"
                                  }
                                  style={
                                    toggleDarkMode
                                      ? { backgroundColor: "#495057" }
                                      : null
                                  }
                                />
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  I have read and agreed to the GCMX Terms of
                                  Service
                                </span>
                              </Label>
                              {errors.agree && touched.agree && (
                                <div className="input-feedback float-left">
                                  {errors.agree}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <button
                              className="nextBtn"
                              //onClick={this.continue}
                              type="submit"
                              style={{ marginBottom: "10px" }}
                            >
                              Create Account{" "}
                              {submit && (
                                <Spinner
                                  style={{ marginBottom: "4px" }}
                                  size="sm"
                                  color="light"
                                />
                              )}
                            </button>
                            <div className="p-content">
                              <p
                                style={{
                                  color: toggleDarkMode ? "white" : null,
                                }}
                              >
                                Already registered?{" "}
                                <a
                                  className="login-from-register"
                                  href="/auth/login"
                                >
                                  Log in
                                </a>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </div>
        );
      }}
    </Formik>
  );
};

export default Register;
