/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "./Sidebar.css";
import { userHasPermission } from "../../services/auth.service";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = (e, routes) => {
    let route = routes.filter((route) => route.name === e.target.textContent);
    route[0].collapse = !route[0].collapse;
    //prop.collapse = !prop.collapse;
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = (routes) => {
    let route = routes.filter((route) => route.collapse === true);
    if (route.length > 0) route[0].collapse = !route[0].collapse;

    this.state.collapseOpen = false;

    this.setState({ collapseOpen: false }, () => {
      //console.log(this.state.collapseOpen);
    });
  };

  componentDidUpdate(prevState) {
    // if (this.state.collapseOpen == prevState.value) {
    //   console.log("Kasala don burst o!");
    // }
  }

  getRoutes = (routes) => {
    return routes
      .map((prop) => {
        // console.log(prop)
        if (userHasPermission(prop.path)) {
          if (!prop.children) {
            return prop;
          } else {
            prop.children = prop.children
              .map((child) => {
                if (userHasPermission(child.path)) {
                  return child;
                } else {
                  //return child;
                  return null;
                }
              })
              .filter((rt) => rt != null);
            return prop.children.length > 0 ? prop : null;
          }
        } else {
          return null;
        }
      })
      .filter((rt) => rt != null && rt.isSideBar === true);
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, toggle) => {
    const sidebarRoutes = routes.slice(0, 7);
    return sidebarRoutes.map((prop, key) => {
      return (
        <>
          {!prop.children ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={() => this.closeCollapse(sidebarRoutes)}
                activeClassName={toggle ? "active dark-active" : "active"}
                style={{
                  color: toggle ? "white" : null,
                }}
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          ) : (
            <NavItem key={key}>
              <a
                className="nav-link"
                onClick={(e) => {
                  this.closeCollapse(sidebarRoutes);
                  this.toggleCollapse(e, sidebarRoutes);
                }}
                style={{
                  color: toggle ? "white" : null,
                }}
              >
                <i className={prop.icon} />
                {prop.name}
              </a>
              <Collapse isOpen={this.state.collapseOpen && prop.collapse}>
                <div className="dropdown-container">
                  {prop.children.map((prop, key) => {
                    return (
                      <NavLink
                        key={key}
                        to={prop.layout + prop.path}
                        tag={NavLinkRRD}
                        // onClick={this.closeCollapse}
                        activeClassName={
                          toggle ? "active dark-active" : "active"
                        }
                        style={{
                          color: toggle ? "white" : null,
                        }}
                      >
                        <span className="sidebar-subtext">{prop.name}</span>
                      </NavLink>
                    );
                  })}
                </div>
              </Collapse>
            </NavItem>
          )}
        </>
      );
    });
  };
  static contextType = AppContext;
  render() {
    const { bgColor, routes, logo } = this.props;
    const { toggleDarkMode } = this.context;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
        color={toggleDarkMode ? "dark" : null}
        {...(toggleDarkMode ? "dark" : null)}
      >
        <Container className="sidebar-container" fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
                style={{ marginTop: "21px" }}
              />
            </NavbarBrand>
          ) : null}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              style={{
                marginTop: "20px",
                color: toggleDarkMode ? "white" : null,
              }}
              navbar
            >
              {this.createLinks(this.getRoutes(routes), toggleDarkMode)}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
