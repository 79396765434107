import React from "react";

class HowItWorks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="d-flex justify-content-center align-item-center">
                <h1>Coming Soon</h1>
            </div>
         );
    }
}
 
export default HowItWorks;