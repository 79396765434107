import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import SpotTable from "./SpotTable";
import "./Spots.css";
import classnames from "classnames";
import axios from "axios";
import { getToken } from "../../services/auth.service";
import Pagination from "../Market/Pagination";
import { AppContext } from "../../AppContext/AppContext";

const SpotMarket = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getToken();
  // //console.log(token);
  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [dollar, setDollar] = useState()
  const fetchSportMarketData = useCallback(() => {
    setLoading(true);
    axios
      .get("https://server.gcmx.bluetag.tech/api/Transaction/SpotMarket", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        //console.log("spotmarket", result.data);
        setProducts(result.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error.response.data);
        setLoading(false);
      });
  }, [token]);

  const { customersWallet, toggleDarkMode } = useContext(AppContext);

  useEffect(() => {
    fetchSportMarketData();
    let stringBalance = (parseInt(customersWallet.estimated_balance) * 10000).toString()
    axios.get(`https://server.gcmx.bluetag.tech/api/Common/CurrencyConverter?currenctFrom=NGN&currencyTo=USD&amount=${stringBalance}`)
      .then(res => {
        setDollar(res.data)
      })
      .catch(error => console.log(error))
  }, [fetchSportMarketData,customersWallet]);

  
  //console.log("customerwallet", customersWallet);

  const indexOfLastPost1 = currentPage * postsPerPage;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage;
  const currentPosts1 = products.slice(indexOfFirstPost1, indexOfLastPost1);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div
        className={
          toggleDarkMode ? "header bg-dark pb-8" : "header bg-light pb-8"
        }
        style={{ fontFamily: "Poppins" }}
      >
        <Container fluid>
          <div
            className="header-body"
            style={{ color: toggleDarkMode ? "white" : null }}
          >
            {/* Card stats */}
            <Row>
              <Col>
                <div className="spotMainTitle">
                  <p
                    className={
                      toggleDarkMode ? "page-title dark" : "page-title"
                    }
                  >
                    Spot Market
                  </p>
                  <div className="exportPlusIcon">
                    <i
                      className={classnames("fa fa-download", "exportIcon")}
                      aria-hidden="true"
                      style={{ color: toggleDarkMode ? "white" : null }}
                    ></i>
                    <span
                      className="exportText"
                      style={{ color: toggleDarkMode ? "white" : null }}
                    >
                      Export spot market
                    </span>
                  </div>
                </div>
                <Card
                  className="mb-2 mb-xl-0"
                  style={{
                    backgroundColor: toggleDarkMode ? "black" : null,
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <span
                          className="spotSubtitle"
                          style={{ color: toggleDarkMode ? "white" : null }}
                        >
                          Commodity Value
                        </span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <span
                            className="spotValue"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            ₦{" "}
                            {customersWallet
                              ? customersWallet?.spot_market
                              : "--"}
                          </span>
                          <span
                            className="spotLine"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            |
                          </span>
                          <span
                            className="spotValue2"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            ${" "}
                            {customersWallet
                              ? customersWallet?.spot_market_second_value
                              : "--"}
                          </span>
                        </div>
                      </Col>
                      <Col md="6">
                        <span
                          className="spotSubtitle"
                          style={{ color: toggleDarkMode ? "white" : null }}
                        >
                          Customer Balance
                        </span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <span
                            className="spotValue"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            {/* <img
                              className="spotNaira"
                              src="https://www.flaticon.com/svg/static/icons/svg/32/32974.svg"
                              alt="naira"
                              width="18px"
                            /> */}
                            {customersWallet?.estimated_balance
                              ? `₦ ${parseInt(customersWallet?.estimated_balance) * 10000}`
                              : "₦0"}{" "}
                          </span>
                          <span
                            className="spotLine"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            |
                          </span>
                          <span
                            className="spotValue2"
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            {dollar
                              ? `$${parseInt(dollar)}`
                              : "$0"}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div style={{ marginBottom: "25px" }}></div>
            <Row>
              <Col>
                <Card
                  className=" mb-2 mb-xl-0"
                  style={{
                    backgroundColor: toggleDarkMode ? "black" : null,
                  }}
                >
                  <CardBody>
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <>
                        <div className="mb-2">
                          <SpotTable products={currentPosts1} />
                        </div>
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={products.length}
                          paginate={paginate}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SpotMarket;
