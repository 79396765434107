import React, { useState, useEffect, useContext } from "react";
import SpotMarket from "./SpotMarket";
import FuturesMarket from "./FuturesMarket";
import { Col } from "react-bootstrap";
import Pagination from "./Pagination";
import "./market.css";
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
//import { Card } from "reactstrap";
import spotMarket from "../../dummyData/spotMarket";
import futuresMarket from "../../dummyData/futuresMarket";
import classnames from "classnames";
import { AppContext } from "../../AppContext/AppContext";

export default function SpotMarketTable({ spotMarketData }) {
  //const [spotMarketData, setMData] = useState(spotMarket);
  const [futuresMarketData, setFData] = useState(futuresMarket);
  const [spotQuery, setSQuery] = useState("");
  const [futuresQuery, setFQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { toggleDarkMode } = useContext(AppContext);

  const navStyles = {
    padding: "13px 50px",
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };

  function spotSearch(rows) {
    const columns = rows[0] && Object.keys(rows[0]);
    return rows.filter((row) =>
      columns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(spotQuery.toLowerCase()) > -1
      )
    );
  }

  function futuresSearch(rows) {
    const columns = rows[0] && Object.keys(rows[0]);
    return rows.filter((row) =>
      columns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(futuresQuery.toLowerCase()) > -1
      )
    );
  }
  function callback(e) {
    //console.log(e);
  }
  const indexOfLastPost1 = currentPage * postsPerPage;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage;
  const currentPosts1 = spotMarketData.slice(
    indexOfFirstPost1,
    indexOfLastPost1
  );

  const indexOfLastPost2 = currentPage * postsPerPage;
  const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage;
  const currentPosts2 = futuresMarketData.slice(
    indexOfFirstPost2,
    indexOfLastPost2
  );

  // changes the pages
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    // <Card>
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
            style={navStyles}
          >
            <span style={{ color: toggleDarkMode ? "white" : null }}>
              Spot Market
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
            style={navStyles}
          >
            <span style={{ color: toggleDarkMode ? "white" : null }}>
              Futures Market
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="tab-content"
        style={{ fontFamily: "Poppins" }}
      >
        <TabPane tabId="1">
          <div>
            {" "}
            <Col className="d-flex">
              <Col md={10} className="justify-content-left">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={spotMarketData.length}
                  paginate={paginate}
                />
              </Col>
              <Col md={2} className="justify-content-end">
                <Input
                  type="text"
                  placeholder="&#xF002; Search"
                  className={
                    toggleDarkMode
                      ? "search-input search-input-dark"
                      : "search-input search-input-light"
                  }
                  value={spotQuery}
                  onChange={(e) => setSQuery(e.target.value)}
                  style={{ fontFamily: "Poppins", marginBottom: "10px" }}
                />
              </Col>
            </Col>
            <SpotMarket
              spotMarketData={spotSearch(currentPosts1)}
              loading={loading}
            />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div>
            <Col className="d-flex">
              <Col md={10} className="justify-content-left">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={futuresMarketData.length}
                  paginate={paginate}
                />
              </Col>
              <Col md={2} className="justify-content-end">
                <Input
                  type="text"
                  placeholder=" &#xF002; Search"
                  className="search-input"
                  value={futuresQuery}
                  onChange={(e) => setFQuery(e.target.value)}
                  style={{ fontFamily: "FontAwesome", marginBottom: "10px" }}
                />
              </Col>
            </Col>
          </div>
          <FuturesMarket futuresMarketData={futuresSearch(currentPosts2)} />
        </TabPane>
      </TabContent>
    </>
    // </Card>
  );
}
