import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import "./request.css";

const RequestDeliveryForm = ({
  setRequestDeliveryModal,
  requestDeliveryModal,
  deliveryConfirmation,
  setDeliveryConfirmation,
  OTPForm,
  setOTPForm,
}) => {
  const { deliveryDetails, toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <h6 style={{ color: toggleDarkMode ? "white" : "" }}>
                Your Withdrawal Request
              </h6>
              <div>
                <p>
                  {deliveryDetails.withdrawal_quantity} tons of Grade{" "}
                  {deliveryDetails.grade} {deliveryDetails.commodity_name}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 style={{ color: toggleDarkMode ? "white" : "" }}>
                Delivery Cost
              </h6>
              <div>
                <p style={{ color: toggleDarkMode ? "white" : "" }}>
                  Total = 25,000
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className="col-9">
              <h6 style={{ color: toggleDarkMode ? "white" : "" }}>
                Shipping Details
              </h6>
            </Col>
            <Col className="col">
              <button
                className="edit-delivery-details"
                onClick={() => {
                  setRequestDeliveryModal(!requestDeliveryModal);
                  setDeliveryConfirmation(!deliveryConfirmation);
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            </Col>
          </Row>
          <div>
            <p style={{ color: toggleDarkMode ? "white" : "" }}>
              {deliveryDetails.shipping_address},<br />
              {deliveryDetails.town},<br />
              {deliveryDetails.city},<br />
              {deliveryDetails.state} State, Nigeria.
            </p>
          </div>
        </Col>
      </Row>
      <br />
      <div className="row justify-content-center">
        <div className="col-10">
          <button
            className="btn btn-block btn-success button-default"
            style={{ background: "#24B086" }}
            onClick={() => {
              setOTPForm(!OTPForm);
              setDeliveryConfirmation(!deliveryConfirmation);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default RequestDeliveryForm;
