import React, { useContext, useState } from "react";
import { Modal } from "reactstrap";
import RequestDeliveryForm from "./RequestDeliveryForm";
import DeliveryOTPForm from "./OTPForm";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";

const RequestDeliveryModals = ({
  requestDeliveryModal,
  setRequestDeliveryModal,
  deliveryConfirmation,
  setDeliveryConfirmation,
  OTPForm,
  setOTPForm,
  success,
  setSuccess,
}) => {
  let item;

  const { toggleDarkMode } = useContext(AppContext);

  let history = useHistory();
  if (OTPForm) {
    item = (
      <div className="modal-view-2">
        <div
          className="modal-body text-center"
          style={
            toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
          }
        >
          <div className="row justify-content-right">
            <div className="col">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setRequestDeliveryModal(!requestDeliveryModal)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          <i
            style={{
              fontSize: "30px",
              textAlign: "center",
              color: "#24b086",
            }}
            className="fas fa-mobile"
          ></i>
          <h3 style={{ color: toggleDarkMode ? "white" : "" }}>
            Verification Code
          </h3>
          <p style={{ color: toggleDarkMode ? "white" : "" }}>
            Verification code has been sent to your phone number
          </p>
          <h5 style={{ color: toggleDarkMode ? "white" : "" }}>
            Please enter the code below
          </h5>
          <DeliveryOTPForm
            OTPForm={OTPForm}
            setOTPForm={setOTPForm}
            success={success}
            setSuccess={setSuccess}
          />
        </div>
      </div>
    );
  } else if (success) {
    item = (
      <div className="modal-view-3">
        <div
          className="modal-body"
          style={
            toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
          }
        >
          <div className="row justify-content-right">
            <div className="col">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setRequestDeliveryModal(!requestDeliveryModal)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          <div className="success-message">
            <i className="far fa-check-circle"></i>

            <h1 style={{ color: toggleDarkMode ? "white" : "" }}>Success</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <button
                className="btn btn-block btn-success button-default"
                style={{ background: "#24B086", marginBottom: "45px" }}
                onClick={() => {
                  setSuccess(!success);
                  setRequestDeliveryModal(!requestDeliveryModal);
                  history.push("/admin/delivery-history");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={requestDeliveryModal}
        toggle={() => setRequestDeliveryModal(!requestDeliveryModal)}
      >
        {deliveryConfirmation ? (
          <div className="modal-view-1">
            <div
              className="modal-body"
              style={
                toggleDarkMode
                  ? { backgroundColor: "black", color: "white" }
                  : null
              }
            >
              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <h6
                    className="modal-title"
                    id="modal-title-default"
                    style={{ color: toggleDarkMode ? "white" : "" }}
                  >
                    Delivery Confirmation
                  </h6>
                </div>
                <div className="col-1">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      setRequestDeliveryModal(!requestDeliveryModal)
                    }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <RequestDeliveryForm
                requestDeliveryModal={requestDeliveryModal}
                setRequestDeliveryModal={setRequestDeliveryModal}
                deliveryConfirmation={deliveryConfirmation}
                setDeliveryConfirmation={setDeliveryConfirmation}
                OTPForm={OTPForm}
                setOTPForm={setOTPForm}
              />
            </div>
          </div>
        ) : (
          item
        )}
      </Modal>
    </>
  );
};

export default RequestDeliveryModals;
