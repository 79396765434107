import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { Modal } from "reactstrap";
import logo from "../../components/img/logo.png";
import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
  },
  address: {
    fontSize: 10,
    textAlign: "center",
    color: "#24b086",
  },
  bold: {
    fontWeight: "extrabold",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    color: "#24b086",
    margin: 10,
    padding: 10,
  },
  receiptHeader: {
    fontSize: 14,
    textAlign: "center",
    borderBottom: "1px solid lightgrey",
    padding: 10,
  },
  mainBody: {
    fontSize: 12,
    margin: 5,
    padding: 5,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    backgroundColor: "#24b086",
    color: "white",
    margin: 5,
    padding: 10,
  },
  image: {
    width: 55,
    height: 55,
  },
  halfWidth: {
    width: "50%",
    margin: 2,
    padding: 5,
  },
  mainHeader: {
    fontSize: 23,
    fontWeight: 800,
    padding: "15 50",
  },
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <View>
          <View style={styles.headerRow}>
            <Image source={logo} style={styles.image} />

            <Text style={styles.mainHeader}>
              <span>GEZAWA COMMODITY EXCHANGE</span>
            </Text>
          </View>

          <View style={styles.address}>
            <Text>1 Gumel Road, Gezawa Town, Kano - Nigeria</Text>
            <br />
            <Text>
              A Securities & Exchange Commission Nigeria licensed Commodity
              Exchange
            </Text>
            <br />
            <Text>Email:info@gcmx.ng</Text>
            <br />
            <Text>http://www.gcmx.ng</Text>
            <br />
            <Text>Tel: +2349011404003, (064)433004</Text>
            <br />
            <Text>RC 1413053</Text>
          </View>
        </View>

        <View style={styles.mainBody}>
          <View style={styles.receiptHeader}>
            <Text>
              <h4>Goods Issued Note - GCMX/GIN/2021/0001</h4>
            </Text>
          </View>

          <View>
            <View style={styles.row}>
              <Text style={styles.halfWidth}>Date Issued: 2021:11:06</Text>
              <Text style={styles.halfWidth}>
                Physical Settlement Date: 2021:11:06
              </Text>
            </View>
            <View style={styles.header}>
              <Text>Product Description</Text>
            </View>
            <View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Contract ID: </Text>
                <Text style={styles.halfWidth}>Contract Value: </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Product Name: Rice</Text>
                <Text style={styles.halfWidth}>
                  Product Variant: Rice Paddy
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Unit of Measurement: Tonnes
                </Text>
                <Text style={styles.halfWidth}>
                  Net Quantity: 350,000 Tonnes
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Grade: Grade A</Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text>Storage Description</Text>
            </View>
            <View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Warehouse Location: Tonnes</Text>
                <Text style={styles.halfWidth}>Warehouse Fee: </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Storage Grace Period(Until): Grade A
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text>Customer Details</Text>
            </View>
            <View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  <h5>Buyer Details</h5>
                </Text>
                <Text style={styles.halfWidth}>
                  <h5>Seller Details</h5>
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Buyer Name: Business Misouri
                </Text>
                <Text style={styles.halfWidth}>
                  Seller Name: Business Misouri
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Broker ID: GEZAWA/BK005</Text>
                <Text style={styles.halfWidth}>Broker ID: GEZAWA/BK005</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>Broker Name: Gcmx Broker</Text>
                <Text style={styles.halfWidth}>Broker Name: Gcmx Broker</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Broker Phone No: 0807645637
                </Text>
                <Text style={styles.halfWidth}>
                  Broker Phone No: 0807645637
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Broker Email: Gcmxbroker@gmail.com
                </Text>
                <Text style={styles.halfWidth}>
                  Broker Email: Gcmxbroker@gmail.com
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text>Contract Details</Text>
            </View>
            <View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Broker Fee 0.5% (NGN): 37, 0000
                </Text>
                <Text style={styles.halfWidth}>
                  Contract Value: 75, 000,000.00
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.halfWidth}>
                  Exchange Fee 1.0% (NGN): 70, 000
                </Text>
                <Text style={styles.halfWidth}>Net Cash: 73, 000,000.00</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const GINModal = ({ toggle, setToggle }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={toggle}
      toggle={() => setToggle(!toggle)}
      size="lg"
    >
      <div
        className="modal-body"
        style={
          toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
        }
      >
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}>
            <h6
              className="modal-title"
              id="modal-title-default"
              style={toggleDarkMode ? { color: "white" } : null}
            >
              Goods Issued Note (GIN)
            </h6>
          </div>
          <div className="col-1">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setToggle(!toggle)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </div>

        <PDFViewer showToolbar={true} width={"100%"} height={480}>
          <MyDocument />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default GINModal;
