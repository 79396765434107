import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";

const OTPCode = withRouter(({ history }) => {
  return (
    <Formik
      initialValues={{
        OTP: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          axios
            .get(
              `https://server.gcmx.bluetag.tech/api/UserManager/Verify?key=${values.OTP}`
            )
            .then((res) => {
              //console.log(res);
              //console.log("Success");
              history.push("/auth/change-password");
            })
            .catch((err) => {
              //console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        OTP: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <div>
            <h5>Confirm OTP</h5>
            <p>Enter the OTP sent in your email</p>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="OTP">OTP</Label>
                <input
                  type="text"
                  name="OTP"
                  id="OTP"
                  className="forgot-password-input"
                  onChange={handleChange}
                />
                {errors.OTP && touched.OTP && (
                  <div className="input-feedback float-left">{errors.OTP}</div>
                )}
              </FormGroup>
              <button className="forgot-password-btns" type="submit">
                Submit
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
});

export default OTPCode;
