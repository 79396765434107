import React, { useContext } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import logo from "../img/logo.svg";
import "./AuthNavbar.css";
import { NavWrapper } from "./NavWrapper";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Nav,
  Navbar,
  NavItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
  SplitButton,
} from "react-bootstrap";
import { AppContext } from "../../AppContext/AppContext";

class AuthNavbar extends React.Component {
  static contextType = AppContext;

  render() {
    //const { toggleDarkMode } = useContext(AppContext);
    const { toggleDarkMode, setToggleDarkMode } = this.context;
    return (
      <NavWrapper>
        <nav
          className={
            toggleDarkMode
              ? "navbar navbar-expand-lg navbar-light navbar-main-dark"
              : "navbar navbar-expand-lg navbar-light navbar-main"
          }
          //className="navbar navbar-expand-lg navbar-light navbar-main"
        >
          <NavLink className="navbar-brand" to="/auth/market">
            <img className="logo" alt="..." src={logo} />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <NavItem className="nav-item active">
                <Link className="nav-link" to="/auth/about">
                  About Us <span className="sr-only">(current)</span>
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <NavLink to="/auth/market" className="nav-link">
                  Market
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <Link className="nav-link" to="/auth/product">
                  Product
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Link className="nav-link" to="/auth/how-it-works">
                  How it works
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Link className="nav-link" to="/auth/contact">
                  Contact
                </Link>
              </NavItem>
            </ul>
            <div className="form-inline my-2 my-lg-0">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/auth/login">
                    Log In
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/auth/register">
                    Register
                  </Link>
                </li>
                <li>
                  {toggleDarkMode ? (
                    <NavLink className="nav-link" to={{}}>
                      <button
                        onClick={() => setToggleDarkMode(!toggleDarkMode)}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <i
                          className="fa-solid fa-sun"
                          style={{ color: "white" }}
                        ></i>
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink className="nav-link" to={{}}>
                      <button
                        onClick={() => setToggleDarkMode(!toggleDarkMode)}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <i className="fa-solid fa-moon"></i>
                      </button>
                    </NavLink>
                  )}
                </li>
                <li className="nav-item dropdown mr-4">
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="fas fa-globe" />
                    </span>

                    <span>English</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown01"
                    style={
                      toggleDarkMode
                        ? { backgroundColor: "#495057", color: "#fff" }
                        : null
                    }
                  >
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Hausa
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Igbo
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Yoruba
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Pidgin
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </NavWrapper>
    );
  }
}

export default AuthNavbar;
