import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { FormGroup } from "reactstrap";
import Timer from "react-compound-timer/build";
import ReactCodeInput from "react-verification-code-input";
import { AppContext } from "../../AppContext/AppContext";

const OTPForm2 = ({ modalProps }) => {
  const {
    changePasswordForm,
    changePassword,
    fetchEmailOTP,
    verifyEmailOTP,
    toggleDarkMode,
  } = useContext(AppContext);

  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [otpError, setOtpError] = useState();
  return (
    <Formik
      initialValues={{
        OTPCode: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = cookie.get("token");

        setTimeout(() => {
          verifyEmailOTP(values.OTPCode)
            .then((res) => {
              console.log(res.data);
              //console.log("Success");
              changePassword(changePasswordForm, modalProps);
              modalProps.handleMessage("Successful");
              modalProps.setShowAlert();
              setTimeout(() => {
                modalProps.setShowAlert();
              }, 5000);
            })
            .catch((err) => {
              setOtpError(err);
              setAlert("Incorrect Code");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        OTPCode: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <Timer
              initialTime={120000}
              direction="backward"
              // @ts-ignore
              style={{
                marginBottom: "15px",
                color: toggleDarkMode ? "white" : "",
              }}
            >
              {() => (
                <React.Fragment>
                  <Timer.Minutes /> : <Timer.Seconds /> till expiration
                </React.Fragment>
              )}
            </Timer>
            <br />
            <FormGroup>
              <ReactCodeInput
                className={
                  showAlert
                    ? toggleDarkMode
                      ? "v-code v-code-error dark mt-1"
                      : "v-code v-code-error mt-1"
                    : toggleDarkMode
                    ? "v-code dark mt-1"
                    : "v-code mt-1"
                }
                //   onChange={(e) => {
                //     handleChange(e);
                //     values.OTPCode = e.currentTarget.value;
                //   }}
                title=""
                onChange={(vals) => (values.OTPCode = vals)}
                // onComplete={(val) => //console.log("complete", val)}
              />
              <br />
              {showAlert && <div className="alert alert-danger">{alert}</div>}
            </FormGroup>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Next
                </button>
                <br />
                <p>
                  Did you get the code?{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => fetchEmailOTP()}
                  >
                    Resend
                  </a>
                </p>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OTPForm2;
