import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";

const AccountTypeForm = ({
  modalProps,
  handleSuccessMessage,
  setShowAlert,
  setFailureMessage,
}) => {
  const { updateBrokerLinkIndicator, toggleDarkMode } = useContext(AppContext);
  return (
    <Formik
      initialValues={{
        brokerID: "",
        phone: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = cookie.get("token");

        setTimeout(() => {
          const user = {
            BrokerID: values.brokerID,
            Phone: values.phone,
          };
          axios
            .get(
              `https://server.gcmx.bluetag.tech/api/UserManager/BrokerUpgradeRequest?BrokerID=${values.brokerID}&Phone=${values.phone}`,
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res) => {
              //console.log(res);
              //console.log("Success");
              updateBrokerLinkIndicator(
                "Your request has been sent. Check back on subsequent logins."
              );
              handleSuccessMessage();
              setShowAlert();
              modalProps.toggleModal("AccountType");
              setTimeout(() => {
                setShowAlert();
              }, 5000);
            })
            .catch((err) => {
              //console.log(err.response?.data);
              setFailureMessage(err.response?.data);
              setShowAlert();
              setTimeout(() => {
                setShowAlert();
              }, 5000);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        brokerID: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="brokerID">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Broker ID
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="brokerID"
                    id="brokerID"
                    value={values.brokerID}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.brokerID && touched.brokerID && (
                    <div className="input-feedback float-left">
                      {errors.brokerID}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="phone">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Phone Number
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="phone"
                    id="phone"
                    value={values.phone}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.phone && touched.phone && (
                    <div className="input-feedback float-left">
                      {errors.phone}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountTypeForm;
