import React, { Component } from "react";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Redirect } from "react-router-dom";
import { logout } from "../../../services/auth.service";
import { getPath } from "../../../services/utility.service";

class Logout extends Component {
  componentDidMount() {
    //console.log("Component don mount o!");
    logout();
  }

  render() {
    return (
      <>
        <Redirect to={getPath("login")} />
      </>
    );
  }
}

export default Logout;
