import React, { useState, useContext } from "react";
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import LimitTab from "./LimitTab";
import MarketTab from "./MarketTab";
import { AppContext } from "../../AppContext/AppContext";

const BrokerNavComponent = ({ product, context }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { toggleDarkMode } = useContext(AppContext);

  const navStyles = {
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };

  return (
    <div>
      <Card
        className=""
        style={
          toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
        }
      >
        <CardBody>
          <Row>
            <Col>
              <Nav
                pills
                className="middleComponentTab"
                style={{ marginBottom: -100 }}
              >
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "1" },

                      "middleComponentNavLink"
                    )}
                    onClick={() => {
                      toggle("1");
                    }}
                    style={navStyles}
                  >
                    <span style={{ color: toggleDarkMode ? "white" : "" }}>
                      Limit
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "2" },
                      "middleComponentNavLink"
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                    style={navStyles}
                  >
                    <span style={{ color: toggleDarkMode ? "white" : "" }}>
                      Market
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "3" },
                      "middleComponentNavLink"
                    )}
                    onClick={() => {
                      toggle("3");
                    }}
                    style={navStyles}
                  >
                    <span style={{ color: toggleDarkMode ? "white" : "" }}>
                      Stop-Limit
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <LimitTab product={product} context={context} />
                </TabPane>
                <TabPane tabId="2">
                  <MarketTab product={product} context={context} />
                </TabPane>
                <TabPane tabId="3"></TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BrokerNavComponent;
