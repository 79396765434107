import React, { Component } from "react";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class CurrencyOption extends Component {
  constructor(props) {
    super(props);
    this.updateCurrency = this.updateCurrency.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.state = {
      data: [],
      selectedCurrency: this.props.data.second_currency,
      message: "",
      showAlert: false,
    };
  }

  handleMessage = (response) => {
    //console.log("Message set");
    this.setState({
      message: response,
    });
  };

  setShowAlert = () => {
    //console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  async handleChange(event) {
    event.preventDefault();
    //console.log(event.target.name);
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentDidMount() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/CurrencyList",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ data: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      // console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  async updateCurrency() {
    const token = cookie.get("token");
    try {
      const response = await axios.post(
        `https://server.gcmx.bluetag.tech/api/UserManager/UpdateCurrency?Currency=${this.state.selectedCurrency}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        //console.log(response.data);
        this.handleMessage(response.data);
        this.setShowAlert();
        setTimeout(() => {
          this.setShowAlert();
        }, 5000);
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message}
          </div>
        )}
        <Card className={toggleDarkMode ? "dark" : ""} id="currency-option">
          <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
            <Row>
              <Col>Currency Option</Col>
              <Col className="col-2">
                <i
                  className={
                    toggleDarkMode ? "far fa-edit dark" : "far fa-edit"
                  }
                ></i>
              </Col>
            </Row>
          </span>
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <Row>
              <Col className="col-6">
                <select
                  name="selectedCurrency"
                  value={this.state.selectedCurrency}
                  onChange={this.handleChange}
                  className="dropdown-custom"
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                >
                  {this.state.data.map((d) => (
                    <option key={d.name} value={d.name}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <div className="row">
              <div className="col-2 offset-9 currency-save">
                <button
                  className="btn button-default"
                  style={{ background: "#24B086" }}
                  onClick={() => this.updateCurrency()}
                >
                  <span style={{ fontSize: "14px" }}>Save</span>
                </button>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default CurrencyOption;
