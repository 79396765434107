import { AppContext } from "../../AppContext/AppContext";
import React, { useContext, useState } from "react";
import { Table } from "reactstrap";
import Pagination from "../Market/Pagination";

const OrderHistory = ({ orderHistory }) => {
  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost1 = currentPage * postsPerPage;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage;
  const currentPosts1 = orderHistory.slice(indexOfFirstPost1, indexOfLastPost1);
  //console.log("order history", orderHistory);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { toggleDarkMode } = useContext(AppContext);

  return (
    <div>
      <Table bordered className="mb-3">
        <thead
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "#495057" : null,
          }}
        >
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Instruction ID</th>
            <th scope="col">Commodities</th>
            <th scope="col">Side</th>
            <th scope="col">Type</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price (NGN)</th>
            <th scope="col">Amount (NGN)</th>
            <th scope="col">Executed</th>
            <th scope="col">Status</th>
            <th scope="col">Placed by</th>
          </tr>
        </thead>
        <tbody
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {currentPosts1.map((orderH, index) => (
            <tr key={index}>
              <td>{orderH.date}</td>
              <td>{orderH.instruction_id}</td>
              <td>{orderH.commodity} (kg)</td>
              <td style={{ color: "tomato" }}>{orderH.trade_side} </td>
              <td>{orderH.market_type}</td>
              <td>{orderH.quantity}</td>
              <td>{orderH.price}</td>
              <td>{orderH.amount}</td>
              <td>Nil</td>
              <td>{orderH.status}</td>
              <td>{orderH.customer_name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={orderHistory.length}
        paginate={paginate}
      />
    </div>
  );
};

export default OrderHistory;
