import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";
import { ref } from "yup";

const ChangePasswordForm = ({ modalProps }) => {
  const { fetchEmailOTP, updateChangePasswordForm, toggleDarkMode } =
    useContext(AppContext);
  return (
    <Formik
      initialValues={{
        oldpassword: "",
        newpassword: "",
        confirmnewpassword: "",
      }}
      // @ts-ignore
      onSubmit={(values, { setSubmitting }) => {
        modalProps.toggleModal2("modalview2");
        modalProps.toggleModal2("modalview");

        const user = {
          OldPassword: values.oldpassword,
          NewPassword: values.newpassword,
        };
        updateChangePasswordForm(user);
        setTimeout(() => {
          fetchEmailOTP()
            .then((res) => {
              console.log(res.data);
              //console.log("Success");
            })
            .catch((err) => {
              console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        oldpassword: Yup.string().required("Required"),
        newpassword: Yup.string().required("Required"),
        confirmnewpassword: Yup.string()
          .oneOf([ref("newpassword")], "Passwords do not match")
          .required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row style={{ marginBottom: "5px" }}>
              <Col>
                <FormGroup>
                  <Label htmlFor="oldpassword" style={{ marginBottom: "2px" }}>
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Enter Old Password
                    </span>
                  </Label>
                  <Input
                    type="password"
                    className="form-control textbox-custom2"
                    placeholder="Enter Old Password"
                    name="oldpassword"
                    onChange={handleChange}
                    value={values.oldpassword}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                </FormGroup>
                {errors.oldpassword && touched.oldpassword && (
                  <div className="input-feedback float-left">
                    {errors.oldpassword}
                  </div>
                )}
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="newpassword" style={{ marginBottom: "2px" }}>
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Enter New Password
                    </span>
                  </Label>
                  <Input
                    type="password"
                    className="form-control textbox-custom2"
                    placeholder="Enter New Password"
                    name="newpassword"
                    onChange={handleChange}
                    value={values.newpassword}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.newpassword && touched.newpassword && (
                    <div className="input-feedback float-left">
                      {errors.newpassword}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: "5px" }}>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="confirmnewpassword"
                    style={{ marginBottom: "2px" }}
                  >
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Confirm New Password
                    </span>
                  </Label>
                  <Input
                    type="password"
                    className="form-control textbox-custom2"
                    placeholder="Confirm New Password"
                    name="confirmnewpassword"
                    onChange={handleChange}
                    value={values.confirmnewpassword}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.confirmnewpassword && touched.confirmnewpassword && (
                    <div className="input-feedback float-left">
                      {errors.confirmnewpassword}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ padding: "10px", color: "#BD9524" }}>
                  <i className="fas fa-exclamation-triangle"></i> A one-time 6
                  digit verification code will be sent to your registered phone
                  number{" "}
                </p>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
