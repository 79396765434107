import { AppContext } from "../../AppContext/AppContext";
import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  Row,
  Container,
  Col,
  NavLink
} from "reactstrap";
import './overview.css';
import './withdrawal.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCodeInput from 'react-verification-code-input';
import WithdrawalForm from "./WithdrawalForm";
import axios from "axios";
import cookie from "js-cookie";
import { useEffect } from "react";

const Withdrawal = () => {

  const [data, setData] = useState({});
  const [balance, setBalance] = useState();

  // const storeUser = () => {
  //   if (condition) { }
  // }

  useEffect(() => {
    fetchUserProfile();
    fetchFundDetails();
  }, [])

  async function fetchUserProfile() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/UserProfile",
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        // response.data.card_number = maskFn(response.data.card_number);
        //console.log(response.data)
        setData(response.data);
      } else {
        //console.log("Failure");
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  }

  async function fetchFundDetails() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/CustomerFundBalance",
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        // response.data.card_number = maskFn(response.data.card_number);
        console.log(response.data.balance)
        setBalance(response.data.balance);
      } else {
        //console.log("Failure");
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  }

  return (
    <>
      <Container fluid id="body">
        <Row>
          <div className="col mb-5 mb-xl-0">
            <h3 className="my-3">Withdrawal</h3>
            <Card className="deposit-card">
              <div className="px-4 py-2 ">
                <div className="text-muted">
                  <small className="small-title">Estimated Balance</small>
                  <Row>
                    <Col md="9">
                      <p><span className="h6">₦ 0.00 | </span> <small>$0.00</small></p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </div>
        </Row>

        <Row className="justify-content-center">
          <div className="col-6 mt-5 mb-xl-0">
            <Card className="withdrawal-card">
              <WithdrawalForm data={data} balance={balance} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Withdrawal;
