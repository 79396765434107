import { AppContext } from "../../AppContext/AppContext";
import React, { Component } from "react";
import { Card, Row, Col } from "reactstrap";
import { whatRoleIsThis } from "../../services/auth.service";
import "./kyc.css";

export class AccountOverview extends Component {
  static context = AppContext;
  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        <Card
          id="account-overview"
          style={
            toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
          }
        >
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <Row>
              <Col md={2}>
                <img className="card-photo" width="79" height="90" />
                {/* <i className="fas fa-user profile-pic"></i> */}
              </Col>
              <Col md={10}>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col>
                        <h2
                          style={{
                            textAlign: "left",
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          {this.props.data.fullname}
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="bio">{this.props.data.state}</span>
                      </Col>
                      <Col>
                        <span className="bio">
                          {this.props.data.nationality}
                        </span>
                      </Col>
                      <Col>
                        <span className="bio">
                          {this.props.data.marital_status}
                        </span>
                      </Col>
                      <Col>
                        <span className="bio">{this.props.data.age}</span>
                      </Col>
                      <Col>
                        <span className="bio">{this.props.data.sex}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          style={{
                            padding: "10px 0",
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: "0px",
                          }}
                        >
                          {this.props.data.position_in_company} at{" "}
                          {this.props.data.name_of_employer}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: "8px",
                          }}
                        >
                          Tax ID {this.props.data.tax_id}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>NIN *********7635</Col>
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <p style={{ marginBottom: "0px" }}>
                                {this.props.data.card_issue_date}
                              </p>
                            </Row>
                            <Row>
                              <p style={{ fontSize: "14px", fontWeight: 400 }}>
                                <i>Issue Date</i>
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <p style={{ marginBottom: "0px" }}>
                                {this.props.data.card_expiry_date}
                              </p>
                            </Row>

                            <Row>
                              <p style={{ fontSize: "14px", fontWeight: 400 }}>
                                <i>Expiry Date</i>
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    {whatRoleIsThis() === "Customer" ? (
                      <>
                        <Row>
                          <Col>
                            <p
                              style={{
                                margin: "20px 0",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              Customer Account
                            </p>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <p
                            style={{
                              margin: "20px 0",
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            Broker Account
                          </p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>{this.props.data.email}</Col>
                  <Col>
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    {this.props.data.address}
                  </Col>
                  <Col>{this.props.data.phone}</Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

export default AccountOverview;
