/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
// reactstrap components
import { NavItem } from "react-bootstrap";
import logo from "../img/logo.svg";
import "./adminNavbar.css";
import { NavWrapper } from "./NavWrapper";
import { AppContext } from "../../AppContext/AppContext";

class AdminNavbar extends React.Component {
  static contextType = AppContext;

  render() {
    //const { toggleDarkMode } = useContext(AppContext);
    const { toggleDarkMode, setToggleDarkMode } = this.context;
    return (
      <NavWrapper>
        <nav
          className={
            toggleDarkMode
              ? "navbar navbar-expand-lg navbar-light navbar-main-dark"
              : "navbar navbar-expand-lg navbar-light navbar-main"
          }
          //className="navbar navbar-expand-lg navbar-light navbar-main"
        >
          {window.location.pathname === "/admin/market" && (
            <>
              <NavLink className="navbar-brand" to="/admin/market">
                <img className="logo" alt="..." src={logo} />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </>
          )}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <NavItem className="nav-item active">
                <NavLink className="nav-link" to="/auth/about">
                  About Us <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <NavLink to="admin/market" className="nav-link">
                  Market
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <NavLink className="nav-link" to="/auth/product">
                  Product
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <NavLink className="nav-link" to="/auth/how-it-works">
                  How it works
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <NavLink className="nav-link" to="/auth/contact">
                  Contact
                </NavLink>
              </NavItem>
            </ul>
            <div className="form-inline my-2 my-lg-0">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Wallet
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown01"
                    style={
                      toggleDarkMode
                        ? { backgroundColor: "#495057", color: "#fff" }
                        : null
                    }
                  >
                    <Link
                      className="dropdown-item"
                      to="/admin/overview"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Overview
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/spotmarket"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Spot Market
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/future-market"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Future Market
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Order
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown01"
                    style={
                      toggleDarkMode
                        ? { backgroundColor: "#495057", color: "#fff" }
                        : null
                    }
                  >
                    <Link
                      className="dropdown-item"
                      to="/admin/order-history"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Order History
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/delivery-history"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Delivery History
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/instruction-history"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Instruction History
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/transaction-history"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Transaction History
                    </Link>
                  </div>
                </li>
                {/* <li className="nav-item active">
                  <NavLink className="nav-link" to="/admin/myaccount">
                    <i className="far fa-user-circle"></i>
                  </NavLink>
                </li> */}
                <li className="nav-item active">
                  {toggleDarkMode ? (
                    <NavLink className="nav-link" to={{}}>
                      <button
                        onClick={() => setToggleDarkMode(!toggleDarkMode)}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <i
                          className="fa-solid fa-sun"
                          style={{ color: "white" }}
                        ></i>
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink className="nav-link" to={{}}>
                      <button
                        onClick={() => setToggleDarkMode(!toggleDarkMode)}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <i className="fa-solid fa-moon"></i>
                      </button>
                    </NavLink>
                  )}
                </li>
                <li className="nav-item dropdown">{/* mr-4 */}
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="fas fa-globe" />
                    </span>
                    <span>English</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown01"
                    style={
                      toggleDarkMode
                        ? { backgroundColor: "#495057", color: "#fff" }
                        : null
                    }
                  >
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Hausa
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Igbo
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Yoruba
                    </Link>
                    <Link
                      to="*"
                      className="dropdown-item"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Pidgin
                    </Link>
                  </div> 
                </li>
                <li className="nav-item dropdown mr-4">
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="far fa-user-circle"></i>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown01"
                    style={
                      toggleDarkMode
                        ? { backgroundColor: "#495057", color: "#fff" }
                        : null
                    }
                  >
                    <Link
                      className="dropdown-item"
                      to="/admin/myaccount"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      My Account
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/admin/logout"
                      style={toggleDarkMode ? { color: "#fff" } : null}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </NavWrapper>
    );
  }
}

export default AdminNavbar;
