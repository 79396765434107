// @ts-nocheck
/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/

import Market from "views/Market/Market.js";
import MyAccount from "views/Account/MyAccount.js";
import Register from "views/Authentication/Registration/Register.js";
import Login from "views/Authentication/Login/Login.js";
import Logout from "views/Authentication/Logout/Logout.js";
//import SpotMarket from "views/examples/MarketTables/SpotMarket.js";

import SpotMarket from "views/spotmarket/SpotMarket";
import OrderBook from "views/spotmarket/OrderBook";

// import SpotMarket from "views/spotmarket/SpotMarket";
// import OrderBook from "views/spotmarket/OrderBook";
import Overview from "views/Overview/Overview";
import Deposit from "views/Overview/Deposit";
import Withdrawal from "views/Overview/Withdrawal";
import TransactionSummary from "views/Overview/TransactionSummary";
import CustomerWallet from "views/Overview/CustomerWallet";

import Checkout from "views/Overview/Checkout";
import EWR from "views/Overview/Ewr";
import OrderHistory from "./views/OrderHistory/OrderHistory";
import TradeHistory from "views/TradeHistory/TradeHistory";
import InstructionHistory from "views/InstructionHistory/InstructionHistory";
import HowItWorks from "views/NavbarLinks/HowItWorks";
import Contact from "views/NavbarLinks/Contact";
import Products from "views/NavbarLinks/Products";
import About from "views/NavbarLinks/About";
import Error401 from "views/Error401";
import BrokerWallet from "views/Overview/BrokerWallet";
import CustomerDetails from "views/Overview/CustomerDetails";
import DeliveryHistory from "views/DeliveryHistory/DeliveryHistory";
import TransactionHistory from "views/TransactionHistory/TransactionHistory";
import RequestDelivery from "views/Delivery/RequestDelivery";
import DeliveryClearance from "views/Delivery/DeliveryClearance";
import ForgotPassword from "views/Authentication/ForgotPassword/ForgotPassword";
import ChangePassword from "views/Authentication/ForgotPassword/ChangePassword";
import OTPCode from "views/Authentication/ForgotPassword/OTPCode";
import Kyc from "views/KYC/Kyc";
import CustomerDetailsKYC from "views/KYC/CustomerDetails";

var routes = [
  {
    path: "/myaccount",
    name: "My Account",
    icon: "far fa-user-circle",
    component: MyAccount,
    layout: "/admin",
    isSideBar: true,
  },
  {
    path: "/overview",
    name: "Overview",
    icon: "far fa-folder-open",
    component: Overview,
    layout: "/admin",
    collapse: false,
    isSideBar: true,
    // children: [
    //   {
    //     path: "/broker-wallet",
    //     name: "Broker Wallet",
    //     icon: "far fa-user-circle",
    //     component: BrokerWallet,
    //     layout: "/admin",
    //   },
    //   {
    //     path: "/customer-wallet",
    //     name: "Customer Wallet",
    //     icon: "far fa-user-circle",
    //     component: CustomerWallet,
    //     layout: "/admin",
    //   },
    // ],
  },
  {
    path: "/spotmarket",
    name: "Spot Market",
    icon: "far fa-credit-card",
    component: SpotMarket,
    layout: "/admin",
    isSideBar: true,
  },
  {
    path: "/future-market",
    name: "Future Market",
    icon: "fa fa-credit-card-alt",
    component: SpotMarket,
    layout: "/admin",
    isSideBar: true,
  },
  {
    path: "/history",
    name: "History",
    icon: "far fa-file",
    component: Register,
    layout: "/admin",
    isSideBar: true,
    collapse: false,
    children: [
      {
        path: "/order-history",
        name: "Order History",
        icon: "far fa-user-circle",
        component: OrderHistory,
        layout: "/admin",
      },
      {
        path: "/instruction-history",
        name: "Instruction History",
        icon: "far fa-user-circle",
        component: InstructionHistory,
        layout: "/admin",
      },
      {
        path: "/delivery-history",
        name: "Delivery History",
        icon: "far fa-user-circle",
        component: DeliveryHistory,
        layout: "/admin",
      },
      {
        path: "/transaction-history",
        name: "Transaction History",
        icon: "far fa-user-circle",
        component: TransactionHistory,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/kyc",
    name: "KYC",
    icon: "fas fa-users",
    component: Kyc,
    layout: "/admin",
    isSideBar: true,
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fas fa-sign-out-alt",
    component: Logout,
    layout: "/admin",
    isSideBar: true,
  },
  {
    path: "/trade-history",
    name: "Trade History",
    icon: "far fa-user-circle",
    component: TradeHistory,
    layout: "/admin",
    routeName: "",
  },
  {
    path: "/customer-kyc-details",
    name: "Customer Details",
    icon: "far fa-user-circle",
    component: CustomerDetailsKYC,
    layout: "/admin",
    routeName: "",
  },
  {
    path: "/instruction-history",
    name: "Instruction History",
    icon: "far fa-user-circle",
    component: InstructionHistory,
    layout: "/admin",
  },
  {
    path: "/order-history",
    name: "Order History",
    icon: "far fa-user-circle",
    component: OrderHistory,
    layout: "/admin",
  },
  {
    path: "/delivery-history",
    name: "Delivery History",
    icon: "far fa-user-circle",
    component: DeliveryHistory,
    layout: "/admin",
  },
  {
    path: "/transaction-history",
    name: "Transaction History",
    icon: "far fa-user-circle",
    component: TransactionHistory,
    layout: "/admin",
  },
  {
    path: "/broker-wallet",
    name: "Broker Wallet",
    icon: "far fa-user-circle",
    component: BrokerWallet,
    layout: "/admin",
  },
  {
    path: "/customer-wallet",
    name: "Customer Wallet",
    icon: "far fa-user-circle",
    component: CustomerWallet,
    layout: "/admin",
  },
  {
    path: "/customer-wallet-details",
    name: "Customer Wallet Details",
    icon: "far fa-user-circle",
    component: CustomerDetails,
    layout: "/admin",
  },
  {
    path: "/market",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Market,
    layout: "/auth",
    routeName: "marketAuth",
  },
  {
    path: "/market",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Market,
    layout: "/admin",
    routeName: "marketAdmin",
  },
  {
    path: "/how-it-works",
    name: "How It Works",
    icon: "ni ni-tv-2 text-primary",
    component: HowItWorks,
    layout: "/auth",
  },
  {
    path: "/contact",
    name: "Contact",
    icon: "ni ni-tv-2 text-primary",
    component: Contact,
    layout: "/auth",
  },
  {
    path: "/product",
    name: "Product",
    icon: "ni ni-tv-2 text-primary",
    component: Products,
    layout: "/auth",
  },
  {
    path: "/about",
    name: "About",
    icon: "ni ni-tv-2 text-primary",
    component: About,
    layout: "/auth",
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/deposit",
    name: "Deposit",
    icon: "ni ni-circle-08 text-pink",
    component: Deposit,
    layout: "/admin",
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    icon: "ni ni-circle-08 text-pink",
    component: Withdrawal,
    layout: "/admin",
  },
  {
    path: "/transaction-summary",
    name: "Transaction Summary",
    icon: "ni ni-circle-08 text-pink",
    component: TransactionSummary,
    layout: "/admin",
  },
  {
    path: "/checkout",
    name: "Overview",
    icon: "ni ni-circle-08 text-pink",
    component: Checkout,
    layout: "/admin",
  },
  {
    path: "/ewr",
    name: "EWR",
    icon: "ni ni-circle-08 text-pink",
    component: EWR,
    layout: "/admin",
  },
  {
    path: "/orderbook",
    name: "OrderBook",
    component: OrderBook,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    routeName: "login",
  },
  {
    path: "/forgot",
    name: "Forgot Password",
    //icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth",
  },

  {
    path: "/change-password",
    name: "Change Password",
    //icon: "ni ni-key-25 text-info",
    component: ChangePassword,
    layout: "/auth",
  },
  {
    path: "/error-401",
    name: "Error page",
    icon: "ni ni-key-25 text-info",
    component: Error401,
    layout: "/auth",
  },
  {
    path: "/error-401",
    name: "Error page",
    icon: "ni ni-key-25 text-info",
    component: Error401,
    layout: "/admin",
  },
  {
    path: "/request-delivery",
    name: "Request Delivery",
    icon: "ni ni-key-25 text-info",
    component: RequestDelivery,
    layout: "/admin",
  },
  {
    path: "/delivery-clearance",
    name: "Delivery Clearance",
    icon: "ni ni-key-25 text-info",
    component: DeliveryClearance,
    layout: "/admin",
  },
  {
    path: "/otp-code",
    name: "OTP Confirmation",
    icon: "ni ni-key-25 text-info",
    component: OTPCode,
    layout: "/auth",
  },
];
const overviewChildren = [
  {
    path: "/broker-wallet",
    name: "Broker Wallet",
    icon: "far fa-user-circle",
    component: BrokerWallet,
    layout: "/admin",
  },
  {
    path: "/customer-wallet",
    name: "Customer Wallet",
    icon: "far fa-user-circle",
    component: CustomerWallet,
    layout: "/admin",
  },
];
const index = routes.findIndex((e) => e.path == "/overview");
if (localStorage.getItem("permissions")) {
  const role = JSON.parse(localStorage.getItem("permissions"))[0]?.role;
  if (role == "Broker") {
    routes[index].children = overviewChildren;
  }
}
export default routes;
