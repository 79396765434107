import { AppContext } from '../../AppContext/AppContext';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import Timer from 'react-compound-timer/build';
import ReactCodeInput from 'react-verification-code-input';
import { Form, FormGroup } from 'reactstrap';
import * as Yup from "yup";
import axios from "axios";
import cookie from "js-cookie";

const WithdrawalOTPForm = ({ success, setSuccess, acctDetail, data, amount }) => {
  const { fetchOTP, verifyOTP, toggleDarkMode } = useContext(AppContext);
  const [otpError, setOtpError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [Alert, setAlert] = useState("");

  async function withdrawFunds(payload) {
    const token = cookie.get("token");
    try {
      const response = await axios.post(
        "https://server.gcmx.bluetag.tech/api/Transaction/FundWithdrawal",
        payload,
        {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        // response.data.card_number = maskFn(response.data.card_number);
        //console.log(response.data)
        setSuccess(!success);
      } else {
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      console.log(JSON.parse(error));
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <i
          style={{
            fontSize: "30px",
            textAlign: "center",
            color: "#24b086",
          }}
          className="fas fa-mobile"
        ></i>
        <h3 style={{ color: toggleDarkMode ? "white" : "" }}>
          Verification Code
        </h3>
        <p style={{ color: toggleDarkMode ? "white" : "" }}>
          Verification code has been sent to your phone number
        </p>
        <h5 style={{ color: toggleDarkMode ? "white" : "" }}>
          Please enter the code below
        </h5>
      </div>
      <Formik
        initialValues={{
          OTPCode: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          const info = {
            customer_Id: data.customer_id,
            customer_name: data.fullname,
            amount: parseInt(amount, 10)
          }
          console.log(info);
          setTimeout(() => {
            verifyOTP(values.OTPCode)
              .then((res) => {
                console.log(res.data);
                //console.log("Success");
                withdrawFunds(info);
              })
              .catch((err) => {
                //console.log(err.response?.data);
                setOtpError(err);
                setAlert("Incorrect Code");
                setShowAlert(true);
                setTimeout(() => {
                  setShowAlert(false);
                }, 2000);
              });
            setSubmitting(false);
          }, 1500);
        }}
        validationSchema={Yup.object().shape({
          OTPCode: Yup.string().required("Required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            status,
            setFieldValue,
            setErrors,
          } = props;

          return (
            <Form onSubmit={handleSubmit}>
              <div style={{ textAlign: "center" }}>
                <Timer
                  initialTime={120000}
                  direction="backward"
                  // @ts-ignore
                  style={{
                    marginBottom: "15px",
                    color: toggleDarkMode ? "white" : "",
                    textAlign: "center"
                  }}
                >
                  {() => (
                    <React.Fragment>
                      <Timer.Minutes /> : <Timer.Seconds /> till expiration
                    </React.Fragment>
                  )}
                </Timer>
              </div>

              <br />
              <FormGroup>
                <ReactCodeInput
                  className={
                    showAlert
                      ? toggleDarkMode
                        ? "v-code v-code-error dark"
                        : "v-code v-code-error"
                      : toggleDarkMode
                        ? "v-code dark"
                        : "v-code"
                  }
                  //   onChange={(e) => {
                  //     handleChange(e);
                  //     values.OTPCode = e.currentTarget.value;
                  //   }}
                  //title="OTPCode"
                  onChange={(vals) => (values.OTPCode = vals)}
                //onComplete={(val) => //console.log("complete", val)}
                />
                {errors.OTPCode && touched.OTPCode && (
                  <div className="input-feedback float-left">
                    {errors.OTPCode}
                  </div>
                )}
              </FormGroup>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <button
                    className="btn btn-block btn-success button-default"
                    style={{ background: "#24B086" }}
                    type="submit"
                  >
                    Next
                  </button>
                  <br />
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <p>
                  Did you get the code?{" "}
                  <a style={{ cursor: "pointer" }} onClick={() => fetchOTP()}>
                    Resend
                  </a>
                </p>
              </div>

            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default WithdrawalOTPForm;