import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  Spinner,
  CardText,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { login, fetchPermissions } from "../../../services/auth.service";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { useHistory, withRouter } from "react-router-dom";
import { getPath } from "../../../services/utility.service";
import loginInfo from "../../../dummyData/dummyLogin";
import settings from "../../../settings";
import { AppContext } from "../../../AppContext/AppContext";
const Login = withRouter(({ history }) => {
  //   const history = useHistory();
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    //console.log(showPhoneError);
  }, [showPhoneError]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const { toggleDarkMode } = useContext(AppContext);
  const navStyles = {
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };
  return (
    <Formik
      initialValues={{
        phone: "",
        email: "",
        password: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (values.phone.length < 7 && !validateEmail(values.email)) {
          setShowPhoneError(true);
        } else {
          setSubmit(true);
          setTimeout(() => {
            axios
              .post(settings.baseApi + "/UserManager/Authenticate", values)
              .then((res) => {
                const token = res.data.token;
                //const token = loginInfo.data.token;
                const pList = res.data.role_permission_list;
                //const pList = loginInfo.data.permissions;
                const permissions = JSON.stringify(pList);
                login(token, values.email, values.phone, permissions);
                //login(token, values.email, values.phone, pList);
                setSubmit(false);
                history.push("/admin/market");
              })
              .catch((err) => {
                //console.log(err.response?.data);
                history.push("auth/register");
                setShow(true);
                setMessage(err.response?.data);
              });
            setSubmitting(false);
          }, 500);
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("E-mail is not valid!")
          .required("Email is required"),
        password: Yup.string().required("No password provided."),
        //   .min(6, "Password is too short - should be 6 chars minimum"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <div className="d-flex justify-content-center w-100 mt-6">
            <Col lg="6" md="12">
              <Card
                className={
                  toggleDarkMode
                    ? "shadow border-0"
                    : "bg-white shadow border-0"
                }
                style={toggleDarkMode ? { backgroundColor: "black" } : null}
              >
                <CardBody className="px-lg-5 py-lg-3">
                  <div
                    className={
                      toggleDarkMode
                        ? "text-center my-3"
                        : "text-muted text-center my-3"
                    }
                    style={{
                      fontFamily: "Poppins",
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Log In
                    </h3>
                  </div>
                  <div className="text-center text-danger">
                    {showPhoneError && (
                      <small>Login with Email or Phone Number</small>
                    )}
                    {show && <small>{message}</small>}
                  </div>
                  <form onSubmit={handleSubmit}>
                    <Nav tabs className={toggleDarkMode ? "" : "nav-border"}>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1");
                          }}
                          style={navStyles}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            Email
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggle("2");
                          }}
                          style={navStyles}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            Mobile
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row className="mt-3">
                          <Col md="12">
                            <FormGroup>
                              <Label for="email" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Email Address
                                </span>
                              </Label>
                              <Input
                                value={values.email}
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.email && touched.email && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.email && touched.email && (
                                <div className="input-feedback float-left">
                                  {errors.email}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label for="password" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Password
                                </span>
                              </Label>
                              <Input
                                value={values.password}
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.password && touched.password && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                              />
                              {errors.password && touched.password && (
                                <div className="input-feedback float-left">
                                  {errors.password}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <button
                              className="nextBtn w-100"
                              type="submit"
                              style={{
                                marginBottom: "10px",
                                paddingTop: "13px",
                                cursor: "pointer",
                              }}
                            >
                              Login{" "}
                              {submit && (
                                <Spinner
                                  style={{ marginBottom: "4px" }}
                                  animation="border"
                                  size="sm"
                                  variant="secondary"
                                />
                              )}
                            </button>
                          </Col>
                        </Row>
                        <Row className="align-items-center my-3">
                          <Col md={9}>
                            <a
                              href="/auth/forgot"
                              className={
                                toggleDarkMode
                                  ? "dark-forgot-password-link"
                                  : "forgot-password-link"
                              }
                            >
                              Forgot Password?
                            </a>
                          </Col>
                          <Col md={3}>
                            {" "}
                            <a href="/auth/register" className="register-link">
                              Register
                            </a>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row className="mt-3">
                          <Col md="12">
                            <FormGroup>
                              <Label for="phone">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Phone
                                </span>
                              </Label>
                              <PhoneInput
                                country={"ng"}
                                // @ts-ignore
                                name="phone"
                                value={values.phone}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  values.phone = e.currentTarget.value;
                                }}
                                className={
                                  errors.phone && touched.phone && "error"
                                }
                                inputStyle={
                                  toggleDarkMode
                                    ? {
                                        backgroundColor: "#495057",
                                        color: "white",
                                      }
                                    : null
                                }
                                buttonStyle={
                                  toggleDarkMode
                                    ? { backgroundColor: "#495057" }
                                    : null
                                }
                                dropdownStyle={
                                  toggleDarkMode
                                    ? { backgroundColor: "#495057" }
                                    : null
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label for="password" className="w-100">
                                <span
                                  style={{
                                    color: toggleDarkMode ? "white" : null,
                                  }}
                                >
                                  Password
                                </span>
                              </Label>
                              <Input
                                value={values.password}
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.password && touched.password && "error"
                                }
                                style={
                                  toggleDarkMode
                                    ? { backgroundColor: "#495057" }
                                    : null
                                }
                              />
                              {errors.password && touched.password && (
                                <div className="input-feedback float-left">
                                  {errors.password}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <button
                              className="nextBtn w-100"
                              //onClick={this.continue}
                              type="submit"
                              style={{ marginBottom: "10px" }}
                            >
                              Login{" "}
                              {submit && (
                                <Spinner
                                  style={{ marginBottom: "4px" }}
                                  size="sm"
                                  color="light"
                                />
                              )}
                            </button>
                          </Col>
                        </Row>
                        <Row className="align-items-center my-3">
                          <Col md={9}>
                            <a
                              href="/auth/forgot"
                              className={
                                toggleDarkMode
                                  ? "dark-forgot-password-link"
                                  : "forgot-password-link"
                              }
                            >
                              Forgot Password?
                            </a>
                          </Col>
                          <Col md={3}>
                            {" "}
                            <a href="/auth/register" className="register-link">
                              Register
                            </a>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </div>
        );
      }}
    </Formik>
  );
});

export default Login;
