import React, { Component } from "react";
import { Card, Input, Label, Container, Row, Col } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import TradeHistoryTable from "../TradeHistory/TradeHistoryTable";
import "./trade.css";
import axios from "axios";
import tradeHistory from "../../dummyData/tradeHistory";
import Pagination from "../Market/Pagination";
import { whatRoleIsThis } from "../../services/auth.service";

class TradeHistory extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      data: tradeHistory,
      startDate: new Date("1980-01-01T00:00:00.000Z"),
      endDate: new Date(),
      startDateView: new Date("1980-01-01T00:00:00.000Z"),
      endDateView: new Date(),
      commoditySelect: "",
      marketSelect: "",
      tradeSideSelect: "",
      statusSelect: "",
      customerID: "",
      customerName: "",
      currentPage: 1,
      postsPerPage: 5,
      loading: false,
    };
  }

  handleDateRangeChange = (event, picker) => {
    this.setState({ startDate: picker.startDate, endDate: picker.endDate });
  };
  handleCommodityChange = (e) => {
    this.setState({ commoditySelect: e.target.value });
    // console.log("i changed on click");
  };
  handleMarketChange = (e) => {
    this.setState({ marketSelect: e.target.value });
  };

  handleTradeSideChange = (e) => {
    this.setState({ tradeSideSelect: e.target.value });
  };
  handleStatusChange = (e) => {
    this.setState({ statusSelect: e.target.value });
  };

  // componentDidMount() {
  //   fetch(tradeHistory)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       this.setState({ data: data });
  //     })
  //     .catch(console.log);
  // }

  search = () => {
    this.setState({ loading: true, data: [] });
    var params = JSON.stringify({
      StartDate: this.state.startDate,
      EndDate: this.state.endDate,
      Commodity: this.state.commoditySelect,
      Market: this.state.marketSelect,
      TradeSide: this.state.tradeSideSelect,
      Status: this.state.statusSelect,
      CustomerName: this.state.customerName,
      CustomerID: this.state.customerID,
    });
    axios
      .post("api name here", params, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var result = JSON.parse(res.data.data);
        this.setState({
          startDateView: this.state.startDate,
          endDateView: this.state.endDate,
          data: result,
        });
      })
      .catch(console.log);
  };

  async handleChange(event) {
    event.preventDefault();
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { data, currentPage, postsPerPage } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    return (
      <>
        {/* <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../components/img/logo.svg"),
            imgAlt: "...",
          }}
        /> */}
        <Container fluid className="bg-light mainPage">
          <Row>
            <Col lg={5}>
              <h4>Trade History</h4>
            </Col>
            {whatRoleIsThis() === "Broker" ? (
              <Col lg={2}>
                <Input type="checkbox" name="customers" id="customers" />
                <Label for="customers">All customers</Label>
              </Col>
            ) : (
              <Col lg={2}></Col>
            )}
            <Col lg={2}>
              <Input type="checkbox" name="hideCanceled" id="hideCanceled" />
              <Label for="forCanceled">Hide all canceled</Label>
            </Col>
            <Col lg={2.5}>
              <a href="#">
                <i className="fas fa-download"></i>{" "}
                <Label>Export complete order history</Label>
              </a>
            </Col>
          </Row>
          <Card className="search-bar">
            {/* <Col lg={12}> */}
            {whatRoleIsThis() === "Broker" ? (
              <>
                <Row>
                  <Col lg={5}>
                    <Label for="customerName">Customer Name</Label>
                    <Input
                      type="text"
                      name="customerName"
                      id="customerName"
                      onChange={this.handleChange}
                      placeholder="Enter Customer Name"
                    />
                  </Col>
                  <Col lg={5}>
                    <Label for="customerID">Customer ID</Label>
                    <Input
                      type="text"
                      name="customerID"
                      id="customerID"
                      onChange={this.handleChange}
                      placeholder="Enter Customer ID"
                    />
                  </Col>
                </Row>
                <br />
              </>
            ) : null}
            <Row>
              <Col md={3}>
                <Label>Date</Label>
                <DateRangePicker
                  // onApply={handleDateChange()}
                  initialSettings={{
                    startDate: "01/12/2020",
                    endDate: "12/25/2020",
                  }}
                  onApply={this.handleDateRangeChange}
                >
                  <button
                    className="btn-block btn-datePick"
                    style={{ height: "38px" }}
                  >
                    Start Date-End Date
                    <i
                      style={{ color: "#24B086" }}
                      className="fa fa-calendar ml-2 d-none d-lg-inline-flex calendar-icon"
                    />
                  </button>
                </DateRangePicker>
              </Col>
              <Col>
                <Label>Commodity</Label>
                <div>
                  <select
                    name="commodity"
                    className="select-options"
                    value={this.state.commoditySelect}
                    onChange={this.handleCommodityChange}
                  >
                    <option>All</option>
                    <option value="Rice">Rice</option>
                    <option value="Soya Beans">Soya Beans</option>
                    <option value="Sesame seeds">Sesame Seeds</option>
                    <option value="Maize">Maize</option>
                  </select>
                </div>
              </Col>
              <Col>
                <Label>Market</Label>
                <div>
                  <select
                    name="market"
                    className="select-options"
                    value={this.state.marketSelect}
                    onChange={this.handleMarketChange}
                  >
                    <option>All</option>
                    <option value="Spot Market">Spot Market</option>
                    <option value="Futures Market">Futures Market</option>
                  </select>
                </div>
              </Col>
              <Col>
                <Label>Trade Side</Label>
                <div>
                  <select
                    name="tradeSide"
                    className="select-options"
                    value={this.state.tradeSideSelect}
                    onChange={this.handleTradeSideChange}
                  >
                    <option value="">All</option>
                    <option value="Sell">Sell</option>
                    <option value="Buy">Buy</option>
                  </select>
                </div>
              </Col>
              {whatRoleIsThis() === "Customer" ? (
                <Col>
                  <Label>Status</Label>
                  <div>
                    <select
                      name="status"
                      className="select-options"
                      value={this.state.statusSelect}
                      onChange={this.handleStatusChange}
                    >
                      <option value="">All</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </div>
                </Col>
              ) : (
                <>
                  <Col style={{ fontFamily: "Poppins" }}>
                    <button className="button-search">Reset</button>
                  </Col>
                  <Col
                    style={{
                      fontFamily: "Poppins",
                    }}
                  >
                    <button className="button-search" onChange={this.search}>
                      Search
                    </button>
                  </Col>
                </>
              )}
            </Row>
            {whatRoleIsThis() === "Customer" ? (
              <Row className="justify-content-end">
                <Col md={2} style={{ fontFamily: "Poppins" }}>
                  <button className="button-search">Reset</button>
                </Col>
                <Col
                  md={2}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  <button className="button-search" onChange={this.search}>
                    Search
                  </button>
                </Col>
              </Row>
            ) : null}
            {/* </Col> */}
          </Card>
          <Card>
            {/* <ReactPaginate pageCount={this.pageCount()} /> */}
            {/* <ul>{renderTableData}</ul>
            <ul>{showPageNumbers}</ul> */}
            <TradeHistoryTable data={currentPosts} />
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
            />
            <br />
          </Card>
        </Container>
      </>
    );
  }
}

export default TradeHistory;
