import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import AddressModal from "./AddressModal";

class Address extends Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.state = {
      message: "",
      showAlert: false,
    };
  }

  handleMessage = (response) => {
    //console.log("Message set");
    this.setState({
      message: response,
    });
  };

  setShowAlert = () => {
    //console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  render() {
    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message}
          </div>
        )}
        <Card id="address">
          <span className="cardHeader">
            <Row>
              <Col>Address</Col>
              <Col className="col-2">
                <button onClick={() => this.props.toggleModal("Address")}>
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div className="card-content">
            <p style={{ fontSize: "14px" }}>{this.props.data.address}</p>
          </div>
        </Card>
        <AddressModal
          data={this.props.data}
          address={this.props.address}
          toggleModal={this.props.toggleModal}
          resetModal={this.props.resetModal}
          setShowAlert={this.setShowAlert}
          handleMessage={this.handleMessage}
        />
      </>
    );
  }
}

export default Address;
