import React, { useContext, useState } from "react";
import { Table } from "reactstrap";
import { whatRoleIsThis } from "../../services/auth.service";
import "./instruction.css";
import "../css/tabs.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";

export default function InstructionHistoryTable({ data, marketInfo }) {
  const [showInfo, setShowInfo] = useState(false);
  const [theKey, setTheKey] = useState();
  const { userProfile, toggleDarkMode } = useContext(AppContext);

  const commodityDetails = (d) =>
    marketInfo.filter(
      (c) => c.commodity_id === d.commodity_id && c.grade === d.grade
    );

  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Date</th>
            <th>Instruction ID</th>
            {whatRoleIsThis() === "Customer" ? (
              <>
                <th>Commodities</th>
                <th>UoM</th>
                <th>Grade</th>
                <th>Market</th>
              </>
            ) : (
              <>
                <th>Customer Name</th>
                <th>Customer ID</th>
              </>
            )}
            <th>Trade side</th>
            {whatRoleIsThis() === "Customer" ? (
              <>
                <th>Quantity</th>
                <th>Price (NGN)</th>
                <th>Executed</th>
                <th>Amount</th>
              </>
            ) : null}
            <th>Status</th>
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {data.map((d, key) => (
            <>
              <tr style={{ fontFamily: "Poppins" }} key={d.instruction_id}>
                <td>{d.date}</td>
                <td>{d.instruction_id}</td>
                {whatRoleIsThis() === "Customer" ? (
                  <>
                    <td>{d.commodity}</td>
                    <td>{d.uom}</td>
                    <td>{d.grade}</td>
                    <td>{d.market_type}</td>
                  </>
                ) : (
                  <>
                    <td>{d.customer_name}</td>
                    <td>{d.customer_id}</td>
                  </>
                )}
                <td
                  className={
                    d.tradeSide == "Sell" ? "text-danger" : "text-success"
                  }
                >
                  {d.trade_side}
                </td>
                {whatRoleIsThis() === "Customer" ? (
                  <>
                    <td className="text-danger">{d.quantity}</td>
                    <td>{d.price}</td>
                    <td>{d.executed}</td>
                    <td>{d.amount}</td>
                  </>
                ) : null}
                <td>
                  {d.status}{" "}
                  {whatRoleIsThis() === "Broker" ? (
                    <button
                      className="history-dropdown"
                      onClick={() => {
                        setShowInfo(!showInfo);
                        setTheKey(key);
                      }}
                    >
                      <i className="fas fa-caret-down"></i>
                    </button>
                  ) : null}
                </td>
              </tr>
              {showInfo && theKey === key ? (
                <tr>
                  <td colSpan={whatRoleIsThis() === "Customer" ? 12 : 6}>
                    <div className="dropdownStyle">
                      <span>Trading Total: NGN {d.amount}</span>
                      <span>
                        Hello {userProfile.fullname}, {d.comment}.
                      </span>
                      <span>
                        <Link
                          to={{
                            pathname: "/admin/orderbook",
                            state: {
                              row: {
                                ...d,
                                ...marketInfo.filter(
                                  (c) =>
                                    c.commodity_id === d.commodity_id &&
                                    c.grade === d.grade
                                )[0],
                              },
                            },
                          }}
                        >
                          <button className="button-search">Trade</button>
                        </Link>
                      </span>
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
}
