import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import AccountDetailsModals from "./AccountDetailsModals";

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.state = {
      modalview: false,
      modalview2: false,
      modalview3: false,
      message: "",
      showAlert: false,
    };
  }

  handleMessage = (response) => {
    //console.log("Message set");
    this.setState({
      message: response,
    });
  };

  setShowAlert = () => {
    //console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  resetModal = (state) => {
    this.setState({
      [state]: false,
    });
  };

  render() {
    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message}
          </div>
        )}
        <Card id="account-details" style={{ marginRight: "0px" }}>
          <span className="cardHeader">
            <Row>
              <Col>Account Details</Col>
              <Col className="col-2">
                <button
                  onClick={() => this.props.toggleModal("AccountDetails")}
                >
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div className="card-content">
            <p style={{ fontSize: "14px" }}>{this.props.data.fullname}</p>
            <p style={{ fontSize: "14px" }}>
              <a>{this.props.data.email}</a>
            </p>
            <Row>
              <Col>
                <span style={{ fontSize: "14px" }}>
                  {/*+234 1234567890*/}
                  {this.props.data.phone}
                </span>
              </Col>
              <Col className="col-5">
                <button
                  onClick={() => {
                    this.props.toggleModal("ChangePassword");
                    this.toggleModal("modalview");
                  }}
                >
                  <span
                    style={{
                      // @ts-ignore
                      fontWeight: "600",
                    }}
                  >
                    CHANGE PASSWORD
                  </span>
                </button>
              </Col>
            </Row>
          </div>
        </Card>
        <AccountDetailsModals
          data={this.props.data}
          accountDetails={this.props.accountDetails}
          modalview={this.state.modalview}
          modalview2={this.state.modalview2}
          modalview3={this.state.modalview3}
          changePassword={this.props.changePassword}
          toggleModal={this.props.toggleModal}
          toggleModal2={this.toggleModal}
          resetModal={this.props.resetModal}
          resetModal2={this.resetModal}
          setShowAlert={this.setShowAlert}
          handleMessage={this.handleMessage}
          fetchUserProfile={this.props.fetchUserProfile}
        />
      </>
    );
  }
}

export default AccountDetails;
