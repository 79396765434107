import React, { useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const AddressForm = ({ modalProps, countryList }) => {
  return (
    <Formik
      initialValues={{
        country: modalProps.data.country,
        address: modalProps.data.address,
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = cookie.get("token");

        setTimeout(() => {
          const user = {
            country: values.country,
            address: values.address,
          };
          axios
            .post(
              "https://server.gcmx.bluetag.tech/api/UserManager/UpdateContactAddress",
              user,
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res) => {
              //console.log(res);
              //console.log("Success");
              modalProps.toggleModal("Address");
              modalProps.handleMessage(res.data);
              modalProps.setShowAlert();
              setTimeout(() => {
                modalProps.setShowAlert();
              }, 5000);
            })
            .catch((err) => {
              //console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <select
                    value={values.country}
                    name="country"
                    id="country"
                    className="dropdown-custom"
                    onChange={handleChange}
                  >
                    {countryList.map((d) => (
                      <option>{d.name}</option>
                    ))}
                  </select>
                  {errors.country && touched.country && (
                    <div className="input-feedback float-left">
                      {errors.country}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="address">New Address</Label>
                  <textarea
                    className="form-control"
                    placeholder="Enter your Full Address"
                    name="address"
                    id="address"
                    value={values.address}
                    onChange={handleChange}
                  />
                  {errors.address && touched.address && (
                    <div className="input-feedback float-left">
                      {errors.address}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
