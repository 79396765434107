import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Container,
  Col,
  Label,
  Spinner,
} from "reactstrap";
import "./overview.css";
import Visa from "../../assets/img/icons/common/visa.svg";
import Master from "../../assets/img/icons/common/mastercard.svg";
import { Formik } from "formik";

import * as Yup from "yup";
import axios from "axios";
import settings from "../../settings";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";
import ReactCodeInput from "react-verification-code-input";
const EWR = (props) => {
  const { fetchOTP, verifyOTP, toggleDarkMode } = useContext(AppContext);
  const [otpError, setotpError] = useState("");
  const [verficationSuccess, setverficationSuccess] = useState(false);
  const [submitEwr, setsubmitEwr] = useState(false);
  const ewrData = props.location.data;
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const amount = props.location?.amount;
  const ewrNumber = props.location?.ewrNumber;

  const [vstate, setVstate] = useState("");
  // const {verifyOTP, resendOTP, patientInfo} = useContext(AppContext)
  const [errMsg, seterrMsg] = useState();
  const [ewrForm, setEwrForm] = useState({});
  const [showError, setshowError] = useState(false);
  const [showSuccessOTP, setshowSuccessOTP] = useState(false);
  const [otpResent, setotpResent] = useState(false);
  const [errorOtp, seterrorOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);

  const [tempCode, settempCode] = useState("");
  const toggle = () => setModal(!modal);
  const [state, setState] = useState({
    facility: "",
    customerName: "",
    address: "",
    phone: "",
    commodity: "",
    grade: "",
    origin: "",
    quantity: "",
    dateIssued: "",
  });
  if (!ewrNumber) {
    props.history.push({
      pathname: "/admin/deposit/spot",
    });
  }

  useEffect(() => {
    if (modal) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
  });
  useEffect(() => {
    //console.log(tempCode);
  }, [modal, errorOtp, tempCode]);

  const navigateNext = () => {
    props.history.push({
      pathname: "/admin/overview",
    });
  };
  const resendOtp = () => {
    fetchOTP().then((e) => {
      setotpResent(true);
      setTimeout(() => {
        setotpResent(false);
      }, 7000);
      setMinutes(2);
      setSeconds(59);
      // toggle()
      setsubmitEwr(false);
      //console.log(e);
      setotpError(e.data?.error);
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log(vstate);
    const otp = vstate;
    //console.log(ewrForm);

    verifyOTP(otp)
      .then((res) => {
        //console.log(res);
        if (res.data == "Incorrect Code") {
          seterrorOtp(true);
          setTimeout(() => {
            seterrorOtp(false);
          }, 5000);
        } else {
          const token = cookie.get("token");
          const headers = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          axios
            .post(
              settings.baseApi + "/Transaction/ContractDeposit",
              ewrForm,
              headers
            )
            .then((res) => {
              //console.log(res);
              setverficationSuccess(true);
            })
            .catch((err) => {
              //console.log(err);
              //console.log(err.response?.data);
              // setShow(true);
              // setMessage(err.response?.data);
            });
          // setSubmitting(false);
        }
      })
      .catch((err) => {
        seterrorOtp(true);
        //console.log(err);
      });
  };

  return (
    <>
      <Container className={toggleDarkMode ? "bg-dark" : "bg-light"} fluid>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link
                to="/admin/overview"
                style={toggleDarkMode ? { color: "white" } : null}
              >
                Overview
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to="/admin/deposit/spot"
                style={toggleDarkMode ? { color: "white" } : null}
              >
                Deposit
              </Link>
            </li>
            <li
              className="breadcrumb-item active"
              aria-current="page"
              style={toggleDarkMode ? { color: "white" } : null}
            >
              Contract
            </li>
          </ol>
        </nav>
        <Row className="justify-content-center">
          <div className="col-8 text-center mb-5 mb-xl-0">
            <p
              className={
                toggleDarkMode
                  ? "dark checkout-title text-center"
                  : "checkout-title text-center"
              }
            >
              Contract
            </p>
            <Card
              className={
                toggleDarkMode
                  ? "deposit-card pb-5 text-center px-3 dark"
                  : "deposit-card pb-5 text-center px-3"
              }
            >
              <p className="py-3 text-amount text-center">
                <strong className="amount-green">{ewrNumber}</strong>
              </p>
              <Formik
                initialValues={{
                  contract_number: ewrNumber,
                  warehouse: ewrData?.storage_facility || "",
                  customer_name: ewrData?.name_of_customer || "",
                  commodity_id: ewrData?.commodity_id,
                  customerAddress: ewrData?.address || "",
                  customerPhone: ewrData?.phone || "",
                  grade: ewrData?.grade || "",
                  origin: ewrData?.origin || "",
                  dateIssued: ewrData?.date_issued || "",
                  quantity: ewrData?.quantity || "",
                  commodity: ewrData?.product_name || "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    //console.log(values);
                    setEwrForm(values);
                    setsubmitEwr(true);
                    fetchOTP().then((e) => {
                      toggle();
                      setsubmitEwr(false);
                      //console.log(e);
                      setotpError(e.data?.error);
                    });
                    setSubmitting(false);
                  }, 1500);
                }}
                validationSchema={Yup.object().shape({
                  warehouse: Yup.string().required(
                    "Storage Facility is required"
                  ),
                  customer_name: Yup.string().required(
                    "Customer name must be provided."
                  ),
                  customerAddress: Yup.string().required(
                    "Customer address must be provided"
                  ),
                  customerPhone: Yup.string().required(
                    "Customer phone number must be provided"
                  ),
                  commodity: Yup.string().required(
                    "Product name must be provided"
                  ),
                  grade: Yup.string().required(
                    "Product grade must be provided"
                  ),
                  origin: Yup.string().required(
                    "Product origin must be provided"
                  ),
                  quantity: Yup.string().required(
                    "Product quantity must be provided"
                  ),
                  dateIssued: Yup.string().required(
                    "Product quantity must be provided"
                  ),
                  //   .min(6, "Password is too short - should be 6 chars minimum"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    setFieldValue,
                    setErrors,
                  } = props;

                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup row>
                        <Label className="ewr-label" for="facility" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Storage Facility:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.warehouse}
                            type="text"
                            name="warehouse"
                            id="warehouse"
                            placeholder="Wing A 2678"
                            className={
                              errors.warehouse && touched.warehouse && "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.warehouse && touched.warehouse && (
                            <div className="input-feedback float-left">
                              {errors.warehouse}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="ewr-label ewr-green"
                          for="facility"
                          sm={4}
                        >
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Received By
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="customerName" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Name of Customer:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.customer_name}
                            type="text"
                            name="customerName"
                            id="customerName"
                            placeholder="Mr. Onyedikachukwu Maduke"
                            className={
                              errors.customer_name &&
                              touched.customer_name &&
                              "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.customer_name && touched.customer_name && (
                            <div className="input-feedback float-left">
                              {errors.customer_name}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="address" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Address of Customer:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.customerAddress}
                            type="text"
                            name="customerAddress"
                            id="customerAddress"
                            placeholder="Mr. Onyedikachukwu Maduke"
                            className={
                              errors.customerAddress &&
                              touched.customerAddress &&
                              "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.warehouse && touched.customerAddress && (
                            <div className="input-feedback float-left">
                              {errors.customerAddress}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="phone" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Phone Number:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.customerPhone}
                            type="text"
                            name="customerPhone"
                            id="customerPhone"
                            placeholder="08063324652"
                            className={
                              errors.customerPhone &&
                              touched.customerPhone &&
                              "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.customerPhone && touched.customerPhone && (
                            <div className="input-feedback float-left">
                              {errors.customerPhone}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="ewr-label ewr-green"
                          for="facility"
                          sm={4}
                        >
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Product Description
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="commodity" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Product Name:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.commodity}
                            type="text"
                            name="commodity"
                            id="commodity"
                            placeholder="Rice"
                            className={
                              errors.commodity && touched.commodity && "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.commodity && touched.commodity && (
                            <div className="input-feedback float-left">
                              {errors.commodity}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="grade" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Grade:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.grade}
                            type="text"
                            name="grade"
                            id="grade"
                            placeholder="A"
                            className={errors.grade && touched.grade && "error"}
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.grade && touched.grade && (
                            <div className="input-feedback float-left">
                              {errors.grade}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="origin" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Origin:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.origin}
                            type="text"
                            name="origin"
                            id="origin"
                            placeholder="Nigeria"
                            className={
                              errors.origin && touched.origin && "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.origin && touched.origin && (
                            <div className="input-feedback float-left">
                              {errors.origin}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="quantity" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Quantity:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.quantity}
                            type="text"
                            name="quantity"
                            placeholder="1000"
                            className={
                              errors.quantity && touched.quantity && "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.quantity && touched.quantity && (
                            <div className="input-feedback float-left">
                              {errors.quantity}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="ewr-label" for="dateIssued" sm={4}>
                          <span
                            style={{
                              color: toggleDarkMode ? "white" : null,
                            }}
                          >
                            Date Issued:
                          </span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dateIssued}
                            type="date"
                            name="dateIssued"
                            placeholder="1000"
                            className={
                              errors.dateIssued && touched.dateIssued && "error"
                            }
                            style={
                              toggleDarkMode
                                ? {
                                    backgroundColor: "#495057",
                                    color: "white",
                                  }
                                : null
                            }
                          />
                          {errors.dateIssued && touched.dateIssued && (
                            <div className="input-feedback float-left">
                              {errors.dateIssued}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <Row form className="my-2">
                        <Col className="mb-2" md={6}>
                          <Link
                            to="/admin/deposit/spot"
                            className={
                              toggleDarkMode
                                ? "pt-2 btn btn-block dark btn-back"
                                : "pt-2 btn btn-block btn-back"
                            }
                          >
                            Go Back
                          </Link>
                        </Col>
                        <Col md={6}>
                          <Button
                            type="submit"
                            className={
                              toggleDarkMode
                                ? "btn btn-block btn-success dark btn-checkout"
                                : "btn btn-block btn-success btn-checkout"
                            }
                          >
                            Next{" "}
                            {submitEwr && (
                              <Spinner
                                style={{ marginBottom: "4px" }}
                                size="sm"
                                color="success"
                              />
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
          <Modal
            isOpen={modal}
            toggle={toggle}
            //className={className}
            style={toggleDarkMode ? { backgroundColor: "black" } : null}
          >
            <ModalHeader
              toggle={toggle}
              style={toggleDarkMode ? { backgroundColor: "black" } : null}
            ></ModalHeader>
            <ModalBody
              style={toggleDarkMode ? { backgroundColor: "black" } : null}
            >
              {!verficationSuccess ? (
                <div
                  className="container"
                  style={toggleDarkMode ? { color: "white" } : null}
                >
                  <div className="row d-flex justify-content-center">
                    {otpError && (
                      <Col className="text-center" md="12">
                        <span className="text-danger">{otpError}</span>
                      </Col>
                    )}
                    <Col className="text-center" md="12">
                      <h3 className="text-center">
                        <i className="fas fa-mobile-alt phone"></i>
                      </h3>
                      {!errorOtp && (
                        <h5
                          className="text-center"
                          style={toggleDarkMode ? { color: "white" } : null}
                        >
                          Verification Code
                        </h5>
                      )}
                      {errorOtp && (
                        <h5 className="text-center text-danger">
                          Incorrect OTP Code
                        </h5>
                      )}
                      <small
                        className={
                          otpResent
                            ? "text-success pt-2 text-center h6"
                            : "pt-2 text-center"
                        }
                        style={toggleDarkMode ? { color: "white" } : null}
                      >
                        The verfication code has been{" "}
                        {otpResent ? "resent" : "sent"} <br /> to your phone
                        number
                      </small>
                      <p className="pt-2">
                        <strong>Please enter the code below</strong>
                      </p>
                      {/* <span className="pt-2 count-down"> {minCountdown}:{countdown} mins</span> */}
                      <span className="pt-2 count-down">
                        {" "}
                        {minutes === 0 && seconds === 0 ? (
                          <span className="text-danger"> 0:0 min </span>
                        ) : (
                          <span
                            className={minutes === 0 ? "text-danger" : null}
                          >
                            {" "}
                            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        )}
                      </span>
                    </Col>
                    <form
                      onSubmit={handleSubmit}
                      className="form-row verify-form justify-content-center mx-1 my-3 v-code"
                    >
                      <ReactCodeInput
                        className={
                          errorOtp
                            ? toggleDarkMode
                              ? "v-code v-code-error dark"
                              : "v-code v-code-error"
                            : toggleDarkMode
                            ? "v-code dark"
                            : "v-code"
                        }
                        onChange={(vals) => setVstate(vals)}
                      />

                      <button className="mt-3 btn btn-block btn-primary verify">
                        Verify
                      </button>
                    </form>
                  </div>
                  <div className="row mt-3 justify-content-center">
                    <p>
                      Didn't get the code?{" "}
                      <button
                        onClick={() => resendOtp()}
                        className="btn mr-5 ewr-green"
                      >
                        Resend
                      </button>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row d-flex justify-content-center">
                    <Col className="text-center" md="12">
                      <h3 className="text-center">
                        <i className="lar la-check-circle phone"></i>
                      </h3>
                      <h5
                        className="text-center"
                        style={toggleDarkMode ? { color: "white" } : null}
                      >
                        Success
                      </h5>
                    </Col>
                    <Col className="text-center mx-5 my-5" md="12">
                      <button
                        onClick={navigateNext}
                        className="mt-3 btn btn-block btn-primary verify"
                      >
                        Continue
                      </button>
                    </Col>
                  </div>
                </div>
              )}
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default EWR;
