import React, { Component } from "react";
import { Card, Col, Row } from "reactstrap";
import { whatRoleIsThis } from "../../services/auth.service";
import "../css/MyAccount.css";
import AccountDetailsModals from "./AccountDetailsModals";
import AccountTypeModal from "./AccountTypeModal";
import nationalityList from "../../dummyData/nationalityList";
import { AppContext } from "../../AppContext/AppContext";

class AccountOverview extends Component {
  constructor(props) {
    super(props);
    this.handleSuccessMessage = this.handleSuccessMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.checkNationality = this.checkNationality.bind(this);
    this.state = {
      modalview: false,
      modalview2: false,
      modalview3: false,
      nationality: "",
      message: "",
      showAlert: false,
      brokerLinkIndicator: "",
    };
  }

  checkNationality = (country) => {
    var indegene = nationalityList.filter(
      (indigene) => indigene.country == country
    );
    this.setState({ nationality: indegene[0].adj });
  };

  handleSuccessMessage = () => {
    this.setState({
      brokerLinkIndicator: "Your request has been sent.",
    });
  };

  setShowAlert = () => {
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  resetModal = (state) => {
    this.setState({
      [state]: false,
    });
  };

  static contextType = AppContext;

  render() {
    const { toggleDarkMode, fetchUserProfile } = this.context;
    return (
      <>
        <Card className={toggleDarkMode ? "dark" : ""} id="account-overview">
          <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
            <Row>
              <Col>Account Details</Col>
              <Col className="col-md-1">
                <button
                  onClick={() => this.props.toggleModal("AccountDetails")}
                  className={toggleDarkMode ? "dark" : ""}
                >
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <Row>
              <Col md={2} style={{ textAlign: "center" }}>
                <img src={this.props.imagePath} /*className="card-photo"*/ width="79" height="90" alt="" />
                {/* <i className="fas fa-user profile-pic"></i> */}
                <button
                  onClick={() => {
                    this.props.toggleModal("ChangePassword");
                    this.toggleModal("modalview");
                  }}
                  className={toggleDarkMode ? "dark" : ""}
                >
                  <span
                    style={{
                      // @ts-ignore
                      fontWeight: "600",
                    }}
                  >
                    CHANGE PASSWORD
                  </span>
                </button>
              </Col>
              <Col md={10}>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col>
                        <h2
                          style={{
                            textAlign: "left",
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          {this.props.data.fullname}
                        </h2>
                        <h5
                          style={{
                            textAlign: "left",
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          {this.props.data.customer_id}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="bio">{this.props.data.state}</span>
                      </Col>
                      <Col>
                        <span className="bio">{this.props.nationality}</span>
                      </Col>
                      <Col>
                        <span className="bio">
                          {this.props.data.marital_status}
                        </span>
                      </Col>
                      <Col>
                        <span className="bio">{this.props.data.age}</span>
                      </Col>
                      <Col>
                        <span className="bio">{this.props.data.sex}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          style={{
                            padding: "10px 0",
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: "0px",
                          }}
                        >
                          {this.props.data.position_in_company} at{" "}
                          {this.props.data.name_of_employer}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            marginBottom: "8px",
                          }}
                        >
                          Tax ID {this.props.data.tax_id}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {this.props.data.means_of_identification}{" "}
                        {this.props.maskedNum}
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <p style={{ marginBottom: "0px" }}>
                                {this.props.data.card_issue_date}
                              </p>
                            </Row>
                            <Row>
                              <p style={{ fontSize: "14px", fontWeight: 400 }}>
                                <i>Issue Date</i>
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <p style={{ marginBottom: "0px" }}>
                                {this.props.data.card_expiry_date}
                              </p>
                            </Row>

                            <Row>
                              <p style={{ fontSize: "14px", fontWeight: 400 }}>
                                <i>Expiry Date</i>
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    {whatRoleIsThis() === "Customer" ? (
                      <>
                        <Row>
                          <Col>
                            <p
                              style={{
                                margin: "20px 0",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              Customer Account
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <button
                              onClick={() => {
                                this.props.toggleModal("AccountType");
                              }}
                              className={toggleDarkMode ? "dark" : ""}
                            >
                              <span
                                style={{
                                  // @ts-ignore
                                  fontWeight: "600",
                                }}
                              >
                                TO BECOME A BROKER CLICK HERE
                              </span>
                            </button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <p
                            style={{
                              margin: "20px 0",
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            Broker Account
                          </p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>{this.props.data.email}</Col>
                  <Col>
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    {this.props.data.address}
                  </Col>
                  <Col>{this.props.data.phone}</Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
        <AccountDetailsModals
          data={this.props.data}
          accountDetails={this.props.accountDetails}
          modalview={this.state.modalview}
          modalview2={this.state.modalview2}
          modalview3={this.state.modalview3}
          changePassword={this.props.changePassword}
          toggleModal={this.props.toggleModal}
          toggleModal2={this.toggleModal}
          resetModal={this.props.resetModal}
          resetModal2={this.resetModal}
          //   setShowAlert={this.setShowAlert}
          //   handleMessage={this.handleMessage}
          fetchUserProfile={this.props.fetchUserProfile}
          refreshUserProfile={this.props.refreshUserProfile}
          refreshPassport={this.props.refreshPassport}
        />
        <AccountTypeModal
          accountType={this.props.accountType}
          toggleModal={this.props.toggleModal}
          resetModal={this.props.resetModal}
          handleSuccessMessage={this.handleSuccessMessage}
          setShowAlert={this.setShowAlert}
          showAlert={this.state.showAlert}
        />
      </>
    );
  }
}

export default AccountOverview;
