import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Card, Row, Col } from "reactstrap";
import "./kyc.css";

const NextOfKin = ({ data }) => {
  const fullname = data.nok_firstname + data.nok_lastname;
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Card
        id="bank-account"
        style={
          toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
        }
      >
        <span className="cardHeader">
          <Row>
            <Col>
              <span style={{ color: toggleDarkMode ? "white" : "" }}>
                Next of Kin
              </span>
            </Col>
          </Row>
        </span>
        <div className={toggleDarkMode ? "card-content dark" : "card-content"}>
          <Row className="mb-4">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Name
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={
                  fullname === 0 ? "" : fullname
                } /*style={{ width:"57%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Phone No.
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.nok_phone_number} /*style={{ width:"62%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default NextOfKin;
