var spotMarket = [
  {
    commodities: "Rice",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+" + "2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Soya Beans",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Sesame Seeds",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-" + "1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Maize",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Rice",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Soya Beans",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Sesame Seeds",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Maize",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Rice",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "-1.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Soya Beans",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Sesame Seeds",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Maize",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Rice",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Soya Beans",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Sesame Seeds",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
  {
    commodities: "Maize",
    uom: "kg",
    totalQty: "129,000,000",
    totalAvailable: "29,000,000",
    inOrder: "300,000",
    loan: "-",
    lastPrice: "29,000",
    _24h_Change: "+2.34",
    _24h_High: "240",
    _24h_Low: "130",
    productValue: "23,000",
    action: "Trade",
  },
];

export default spotMarket;
