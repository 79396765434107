import React, { useContext, useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classnames from "classnames";
import { AppContext } from "../../AppContext/AppContext";
import "./request.css";

const DeliveryClearance = ({ location }) => {
  const [activeTab, setActiveTab] = useState("1");

  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let buffer = [];

  const { deliveryDetails, toggleDarkMode } = useContext(AppContext);

  const { row } = location.state;

  const rowPadding = {
    paddingBottom: 20,
  };

  const greyColor = {
    color: "#878787",
  };

  const navPadding = {
    padding: "13px 50px",
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };

  const handleChange = (e) => {
    //console.log(e.target.value);
    setText(e.target.value);
  };

  const handleSubmit = (e) => {
    //console.log(text);
    buffer.push(text);
    setMessages(buffer);
  };

  // useEffect(() => {
  //   effect;
  //   return () => {
  //     cleanup;
  //   };
  // }, [messages]);

  return (
    <>
      <Container
        fluid
        className={toggleDarkMode ? "bg-dark mainPage" : "bg-light mainPage"}
      >
        <Card
          className={
            toggleDarkMode ? "delivery-clearance dark" : "delivery-clearance"
          }
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={navPadding}
              >
                <span style={{ color: toggleDarkMode ? "white" : null }}>
                  Details
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={navPadding}
              >
                <span style={{ color: toggleDarkMode ? "white" : null }}>
                  Disputes
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              <Row>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      {deliveryDetails.shipping_address},<br />
                      {deliveryDetails.town},<br />
                      {deliveryDetails.city},<br />
                      {deliveryDetails.state},<br /> Nigeria.
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Status</Col>
                      </Row>
                      <Row>
                        <Col>{row.status}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Delivery ID</Col>
                      </Row>
                      <Row>
                        <Col>{row.delivery_id}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Date of Delivery</Col>
                      </Row>
                      <Row>
                        <Col>{row.delivery_date}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Customer ID</Col>
                      </Row>
                      <Row>
                        <Col>{row.customer_id}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Customer Name</Col>
                      </Row>
                      <Row>
                        <Col>{row.customer_name}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-left">
                <Col>
                  <h4 className="delivery-cost">
                    Delivery Total (NGN) = 25,000
                  </h4>
                </Col>
              </Row>
              <br />
              <Row className="justify-content-center">
                <Col md={3}>
                  <button className="button dispute-button">Dispute</button>
                </Col>
                <Col md={1}></Col>
                <Col md={3}>
                  <button className="button acknowledge-button">
                    Acknowledge
                  </button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      {deliveryDetails.shipping_address},<br />
                      {deliveryDetails.town},<br />
                      {deliveryDetails.city},<br />
                      {deliveryDetails.state},<br /> Nigeria.
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Status</Col>
                      </Row>
                      <Row>
                        <Col>{row.status}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Delivery ID</Col>
                      </Row>
                      <Row>
                        <Col>{row.delivery_id}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Date of Delivery</Col>
                      </Row>
                      <Row>
                        <Col>{row.delivery_date}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Customer ID</Col>
                      </Row>
                      <Row>
                        <Col>{row.customer_id}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={rowPadding}>
                    <Col>
                      <Row>
                        <Col style={greyColor}>Customer Name</Col>
                      </Row>
                      <Row>
                        <Col>{row.customer_name}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="delivery-cost">
                    Delivery Total (NGN) = 25,000
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="justify-content-center">
                  Messages relating to the Dispute can be exchanged here between
                  the market and the customer
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="chatbox">
                    {messages.map((key) => (
                      <div key={key} className="chat-bubble">
                        <p>{messages[key]}</p>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: "20px 0 40px" }}>
                <Col md={7}>
                  <input
                    type="text"
                    className="dispute-textbox"
                    placeholder="Type your message..."
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                </Col>
                <Col md={2}>
                  <button className="button send-button" onClick={handleSubmit}>
                    Send
                  </button>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={3}>
                  <button className="button acknowledge-button">Resolve</button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </>
  );
};

export default DeliveryClearance;
