import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Card, Col, Container, Input, Label, Row } from "reactstrap";
import TransactionHistoryTable from "./TransactionHistoryTable";
import Pagination from "../Market/Pagination";
import transactionHistory from "../../dummyData/transactionHistory";
import "./transaction.css";
import WithdrawalModal from "./WithdrawalModal";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    //this.displayModal = this.displayModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadHistory = this.loadHistory.bind(this);
  }

  state = {
    dropdownOpen: false,
    data: transactionHistory,//replace transactionHistory with data from api
    startDate: new Date("1980-01-01T00:00:00.000Z"),
    endDate: new Date(),
    startDateView: new Date("1980-01-01T00:00:00.000Z"),
    endDateView: new Date(),
    transactionID: "",
    status: "",
    transactionType: "",
    currentPage: 1,
    postsPerPage: 5,
    loading: false,
    withdrawalExport: false,
    errorMessage: "",
    tableHeader: false,
  };

  handleChange(event) {
    event.preventDefault();
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDateRangeChange = (event, picker) => {
    this.setState({ startDate: picker.startDate, endDate: picker.endDate });
  };

  toggle = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  async loadHistory() {
    const token = cookie.get("token");

    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/FundHistoryPerCustomer",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ data: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      // console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.loadHistory();
  }

  static contextType = AppContext;

  render() {
    const { data, currentPage, postsPerPage } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const { toggleDarkMode } = this.context;

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    return (
      <>
        <Container
          fluid
          className={
            toggleDarkMode ? "bg-dark mainPage dark" : "bg-light mainPage"
          }
        >
          <Row>
            <Col>
              <h4
                style={{
                  color: toggleDarkMode ? "white" : null,
                }}
              >
                Transaction History
              </h4>
            </Col>
          </Row>
          <Card className={toggleDarkMode ? "search-bar dark" : "search-bar"}>
            <Row>
              <Col md={3}>
                <Label for="Date">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Date
                  </span>
                </Label>
                <DateRangePicker
                  initialSettings={{
                    startDate: "01/12/2020",
                    endDate: "12/25/2020",
                  }}
                  onApply={this.handleDateRangeChange}
                >
                  <button
                    className="btn-block btn-datePick"
                    style={{ height: "38px" }}
                  >
                    Start Date-End Date
                    <i
                      className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                      style={{ color: "#24B086" }}
                    />
                  </button>
                </DateRangePicker>
              </Col>
              <Col>
                <Label for="transactionType">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Transaction Type
                  </span>
                </Label>
                <br />
                <select
                  name="transactionType"
                  id="transactionType"
                  value={this.state.transactionType}
                  onChange={this.handleChange}
                  className={
                    toggleDarkMode
                      ? "dropdown-custom dark full-length"
                      : "dropdown-custom full-length"
                  }
                >
                  <option value="All">All</option>
                  <option value="Deposit">Deposit</option>
                  <option value="Withdrawal">Withdrawal</option>
                </select>
              </Col>
              <Col>
                <Label for="dtransactionID">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Transaction ID
                  </span>
                </Label>
                <Input
                  type="text"
                  name="transactionID"
                  id="transactionID"
                  onChange={this.handleChange}
                  placeholder="Enter ID"
                  style={
                    toggleDarkMode
                      ? {
                          color: "white",
                          backgroundColor: "#495057",
                        }
                      : null
                  }
                />
              </Col>
              <Col>
                <Label for="status">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Status
                  </span>
                </Label>
                <br />
                <select
                  name="status"
                  id="status"
                  value={this.state.status}
                  onChange={this.handleChange}
                  className={
                    toggleDarkMode
                      ? "dropdown-custom dark full-length"
                      : "dropdown-custom full-length"
                  }
                >
                  <option value="All">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </Col>
              <Col>
                <button
                  onClick={() => this.setState({ tableHeader: false })}
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                >
                  Reset
                </button>
              </Col>
              <Col>
                <button
                  onClick={() => {
                    this.setState({ tableHeader: true });
                  }}
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                >
                  Search
                </button>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col>
              <h6>
                {this.state.tableHeader
                  ? this.state.transactionType === "All" || ""
                    ? ""
                    : this.state.transactionType
                  : null}
              </h6>
            </Col>
            {this.state.transactionType === "Withdrawal" &&
            this.state.tableHeader ? (
              <Col md={2} style={{ textAlign: "right" }}>
                <button
                  className="export-button"
                  onClick={() => this.toggle("withdrawalExport")}
                >
                  <i className="fas fa-download"></i> Export
                </button>
              </Col>
            ) : null}
          </Row>
          <Card style={toggleDarkMode ? { backgroundColor: "black" } : null}>
            <TransactionHistoryTable
              data={currentPosts}
              transactionType={this.state.transactionType}
              tableHeader={this.state.tableHeader}
            />
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
            />
          </Card>
        </Container>
        <WithdrawalModal
          toggle={this.toggle}
          withdrawalExport={this.state.withdrawalExport}
        />
      </>
    );
  }
}

export default TransactionHistory;
