import React, { Component, useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import BankAccountDetails from "./BankAccountDetails";
import AccountOverview from "./AccountOverview";
import NextOfKin from "./NextOfKin";
import "./kyc.css";
import Label from "reactstrap/lib/Label";
import StatusModal from "./StatusModal";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import cookie from "js-cookie";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";

const CustomerDetailsKYC = ({ location }) => {
  const [data, setData] = useState([]);
  const [reason, setReason] = useState("");
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("");
  const wrapperRef = React.createRef();
  const { row } = location.state;
  const search = () => {
    const token = cookie.get("token");
    try {
      axios
        .get(
          `https://server.gcmx.bluetag.tech/api/UserManager/UserProfileByBroker?UserID=${row.user_id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          //console.log("Success");
          setData(res.data);
        })
        .catch((err) => {
          //console.log(err.response?.data);
        });
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  };

  const customerApproval = () => {
    const token = cookie.get("token");
    try {
      axios
        .post(
          `https://server.gcmx.bluetag.tech/api/UserManager/CustomerApproval?UserID=${row.user_id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          //console.log("Success");
        })
        .catch((err) => {
          //console.log(err.response?.data);
        });
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  };

  const customerRejection = () => {
    const token = cookie.get("token");
    try {
      axios
        .post(
          `https://server.gcmx.bluetag.tech/api/UserManager/CustomerRejection?UserID=${row.user_id}&Reason=${reason}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          //console.log("Success");
        })
        .catch((err) => {
          //console.log(err.response?.data);
        });
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  };

  useEffect(() => {
    search();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const { toggleDarkMode } = useContext(AppContext);

  return (
    <>
      <div
        className={toggleDarkMode ? "bg-dark mainPage" : "bg-light mainPage"}
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb pl-0">
            <li className="breadcrumb-item">
              <Link
                to="/admin/kyc"
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                <i
                  className="fas fa-arrow-left"
                  style={{ fontSize: "14px", padding: "2px 3px" }}
                ></i>{" "}
                KYC
              </Link>
            </li>
          </ol>
        </nav>
        <h4
          // @ts-ignore
          style={{
            fontSize: "18px",
            lineHeight: "27px",
            color: toggleDarkMode ? "white" : "",
          }}
        >
          Account Overview
        </h4>
        <Row className="mb-3">
          <Col>
            <AccountOverview
              data={data}
              //fetchUserProfile={this.fetchUserProfile}
            />
          </Col>
        </Row>
        <Row className="mb-6">
          <Col>
            <BankAccountDetails data={data} />
          </Col>
          <Col>
            <NextOfKin data={data} />
          </Col>
        </Row>
        {row.status == "Verified" || row.status == "Rejected" ? null : (
          <Row className="justify-content-around mb-6 buttons">
            <Col className="col-3">
              <button
                className="button dispute-button"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                Reject
              </button>
            </Col>
            <Col className="col-3">
              <button
                className="button acknowledge-button"
                onClick={() => {
                  customerApproval();
                  setModal(!modal);
                  setStatus("verified");
                }}
              >
                Accept
              </button>
            </Col>
          </Row>
        )}
        {isOpen && (
          <div ref={wrapperRef} className="textbox-dropdown">
            <div className="row mb-6 justify-content-center">
              <div className="col-md-10">
                <div>
                  <Label>
                    <span style={{ color: toggleDarkMode ? "white" : "" }}>
                      Please state reason for action
                    </span>
                  </Label>
                  <textarea
                    className="form-control kyc-textbox"
                    placeholder="Reason"
                    name="reason"
                    id="reason"
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    style={{
                      marginBottom: "10px",
                      color: toggleDarkMode ? "white" : "",
                      backgroundColor: toggleDarkMode ? "#495057" : "",
                    }}
                  />
                  <div className="row justify-content-end">
                    <div className="col-md-2">
                      <button
                        className="button acknowledge-button"
                        onClick={() => {
                          customerRejection();
                          setModal(!modal);
                          setStatus("rejected");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <StatusModal
        userID={row.user_id}
        toggle={toggle}
        modal={modal}
        status={status}
      />
    </>
  );
};

export default CustomerDetailsKYC;
