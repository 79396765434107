import React, { useState, useEffect, useRef, useContext } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import "./Spots.css";
import BrokerNavComponent from "./BrokerNavComponent";
import PriceChart from "./Chart.js";
import axios from "axios";
import { BASEURL } from "./baseUrl";
import { getToken } from "../../services/auth.service";
import candlestickData from "../../dummyData/candlestickData";
import { AppContext } from "../../AppContext/AppContext";

const MiddleComponent = ({ product, context }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [commodityPrice, setCommodityPrice] = useState({});
  //console.log("hi", product);
  const { toggleDarkMode } = useContext(AppContext);

  const token = getToken();
  useEffect(() => {
    const fetchCommodityPrices = () => {
      axios
        .get(
          `${BASEURL}/GetPriceChangesPerCommodity?CommodityID=${product.commodity_id}&Grade=${product.grade}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((result) => {
          //console.log("commodity price", result);
          setCommodityPrice(result.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    fetchCommodityPrices();
  }, [product.commodity_id, product.grade, token]);

  return (
    <div>
      <Container>
        <div className="header-body">
          <div className="mb-1">
            <span
              className="textMuted"
              style={toggleDarkMode ? { color: "white" } : null}
            >
              Spot
            </span>{" "}
            <span
              style={{ fontSize: 13, color: toggleDarkMode ? "white" : "" }}
            >
              &#62;
            </span>{" "}
            <span
              style={{ fontSize: 13, color: toggleDarkMode ? "white" : "" }}
            >
              Trade
            </span>
          </div>
          {!context && (
            <div className="mb-2">
              <span
                className="tradeName"
                style={toggleDarkMode ? { color: "white" } : null}
              >
                {product.customer_name} | {product.instruction_id}
              </span>
            </div>
          )}
          <Row>
            <Col>
              <Card
                className="mb-2 mb-xl-0"
                style={{
                  backgroundColor: toggleDarkMode ? "black" : "",
                }}
              >
                <CardBody>
                  <Row>
                    <Col md="2" className="trade">
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        {commodityPrice ? commodityPrice?.commodity : "--"}
                      </span>
                      <span
                        className="textMuted"
                        style={{
                          marginTop: 10,
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        Grade {commodityPrice ? commodityPrice?.grade : "--"}
                      </span>{" "}
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span style={{ color: "green" }}>
                          ₦ {commodityPrice ? commodityPrice?.price : "--"}
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          ${" "}
                          {commodityPrice ? commodityPrice?.second_price : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{
                            marginLeft: -26,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          24hr change
                        </span>{" "}
                        <br />
                        <span className="subtitles" style={{ color: "red" }}>
                          {commodityPrice
                            ? commodityPrice?._24_hr_change
                            : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{
                            marginLeft: -20,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          24hr high
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          {" "}
                          ₦{commodityPrice
                            ? commodityPrice?._24_hr_high
                            : "--"}{" "}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          24hr Low
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          ₦{commodityPrice ? commodityPrice?._24_hr_low : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          Volume
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{
                            marginLeft: -11,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          ₦
                          {commodityPrice
                            ? commodityPrice?.product_value
                            : "--"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ marginBottom: "25px" }}></div>
          <div
            style={
              fullscreen
                ? {
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 10000,
                    backgroundColor: toggleDarkMode ? "black" : "white",
                  }
                : null
            }
            id="fullscren"
          >
            <Row>
              <Col lg="12" xl="12">
                <PriceChart
                  // @ts-ignore
                  data={candlestickData}
                  fullscreen={fullscreen}
                  setFullscreen={setFullscreen}
                  dataChart={dataChart}
                  product={product}
                />
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: "25px" }}></div>
          <Row>
            <Col lg="12" xl="12">
              {/* <CustomerTradeInstruction /> */}
              <BrokerNavComponent product={product} context={context} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default MiddleComponent;
