/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Button } from "react-bootstrap";
// core components
import { useState } from "react";

function FuturesMarket({ futuresMarketData }) {
  const columns = futuresMarketData[0] && Object.keys(futuresMarketData[0]);
  return (
    <>
      <Table responsive>
        <thead className="bg-light">
          <tr>
            <th>Commodities</th>
            <th>Uom</th>
            <th>Total Qty</th>
            <th>Total Available</th>
            <th>In Order</th>
            <th>Loan</th>
            <th>Last Price</th>
            <th>24H Change</th>
            <th>24H High</th>
            <th>24H Low</th>
            <th>Product Value</th>
            <th>Action</th>
            {/* {futuresMarketData[0] &&
              columns.map((heading) => <th>{heading}</th>)} */}
          </tr>
        </thead>
        <tbody>
          {futuresMarketData.map((f, key) => (
            <tr key={key}>
              <td>{f.commodities}</td>
              <td>{f.uom}</td>
              <td>{f.totalQty}</td>
              <td>{f.totalAvailable}</td>
              <td>{f.inOrder}</td>
              <td>{f.loan}</td>
              <td>₦{f.lastPrice}</td>
              <td>{f._24h_Change}</td>
              <td>₦{f._24h_High}</td>
              <td>₦{f._24h_Low}</td>
              <td>₦{f.productValue}</td>
              <td>
                <Button variant="outline-success">{f.action}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default FuturesMarket;
