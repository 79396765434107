import React, { useState, useContext } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import SelectableCardList from "./SelectableCardList";
import { Col, FormGroup, Input, Row } from "reactstrap";
import "../css/MyAccount.css";
import { AppContext } from "../../AppContext/AppContext";


const LinkForBrokerForm = ({ data, userBroker, modalProps, toggleModal }) => {
  const { toggleDarkMode, updateBrokerFormdetails } = useContext(AppContext);
  const [filteredData, setFilteredData] = useState(data)

  const search = (query) => {
    return data.filter((broker) => broker.broker_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  }


  return (
    <Formik
      initialValues={{
        selectedBroker: modalProps.data.linked_broker_id,
        canOrder: modalProps.data.can_order === "" ? false : true,
        canWithdraw: modalProps.data.can_withdraw === "" ? false : true,
        acceptTerms: modalProps.data.can_order === true ? true : false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        const params = JSON.stringify({
          BrokerID: values.selectedBroker,
          CanOrder: values.canOrder,
          CanWithdraw: values.canWithdraw,
        });

        //console.log(params);

        const token = cookie.get("token");

        setTimeout(() => {
          axios
            .post(
              `https://server.gcmx.bluetag.tech/api/UserManager/BrokerLinking?BrokerID=${values.selectedBroker}&CanOrder=${values.canOrder}&CanWithdraw=${values.canWithdraw}`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res) => {
              // console.log('selected broker: ' + res);
              //console.log("Success");
              modalProps.toggleModal("LinkToBroker");
              modalProps.handleMessage(res.data);
              modalProps.setShowAlert();
              setTimeout(() => {
                modalProps.setShowAlert();
              }, 5000);
            })
            .catch((err) => {
              //console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        selectedBroker: Yup.string().required("You must select a broker"),
        canOrder: Yup.boolean().required("Required"),
        canWithdraw: Yup.boolean().required("Required"),
        acceptTerms: Yup.boolean()
          .required("Required")
          .oneOf([true], "You must accept the terms and conditions"),
        //   .min(6, "Password is too short - should be 6 chars minimum"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        const enable = values.acceptTerms && values.selectedBroker

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p style={{
                      backgroundColor: toggleDarkMode ? "white" : '',
                      marginLeft: "40px",
                      fontWeight: 'bold'
                    }}>
                      Select Broker:
                    </p>
                  </Col>
                  <div className="col">
                    <input
                      type="text"
                      placeholder="Search broker name"
                      className="form-control textbox-custom"
                      onChange={(e) => {
                        setFilteredData(search(e.target.value))
                      }}

                      style={
                        toggleDarkMode
                          ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                          : null
                      }
                    />
                  </div>
                </Row>

                <SelectableCardList
                  setSelectedBroker={(id) => (values.selectedBroker = id)}
                  data={filteredData}
                  handleChange={handleChange}
                />


                {errors.selectedBroker && touched.selectedBroker && (
                  <div className="input-feedback float-left">
                    {errors.selectedBroker}
                  </div>
                )}
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Input
                    type="checkbox"
                    name="canOrder"
                    onChange={handleChange}
                    id="canOrder"
                    checked={values.canOrder}
                  />{" "}
                  Allow Broker to place buy/sell order on my behalf.
                  {errors.canOrder && touched.canOrder && (
                    <div className="input-feedback float-left">
                      {errors.canOrder}
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="checkbox"
                    name="canWithdraw"
                    id="canWithdraw"
                    onChange={handleChange}
                    checked={values.canWithdraw}
                  />{" "}
                  Allow Broker to place withdrawal on my behalf to my registered
                  bank account
                  {errors.canWithdraw && touched.canWithdraw && (
                    <div className="input-feedback float-left">
                      {errors.canWithdraw}
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="checkbox"
                    name="acceptTerms"
                    id="acceptTerms"
                    onChange={handleChange}
                    checked={values.acceptTerms}
                  />{" "}
                  I have read and agreed with the GCMX Terms and Conditions for
                  Broker-Customer relationship.
                  {errors.acceptTerms && touched.acceptTerms && (
                    <div className="input-feedback float-left">
                      {errors.acceptTerms}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-3">
                {userBroker.linked_broker_name ? (
                  <button
                    className="btn btn-block btn-success button-default"
                    style={{ background: "#24B086" }}
                    type="button"
                    onClick={() => {
                      toggleModal("ChangeBrokerModal");
                      updateBrokerFormdetails(values)
                    }}
                    disabled={!enable}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="btn btn-block btn-success button-default"
                    style={{ background: "#24B086" }}
                    type="submit"
                  //   onClick={() => setTimeout(function() { //Start the timer
                  //     this.props.toggleModal("LinkToBroker")
                  // }, 3000)}
                  >
                    Submit
                  </button>
                )}

              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LinkForBrokerForm;
