import React, { Component, useContext } from "react";

import r1 from "../img/r1.svg";
import r1paddy from "../img/r1-paddy.svg";
import ym from "../img/ym.svg";
import ss from "../img/ss.svg";
import sb from "../img/sb.svg";
import wheat from "../img/wheat.svg";
import sorghum from "../img/sorghum.svg";
import millet from "../img/millet.svg";
import beans from "../img/beans.svg";
import corn from "../img/corn.svg";

import axios from "axios";
import cookie from "js-cookie";
import "./header.css";
import priceData from "../../dummyData/priceData";
import { commodityIcons } from "../../services/utility.service";

import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Divider } from "antd";
import { AppContext } from "../../AppContext/AppContext";
import Marquee from "react-fast-marquee";

class CarouselCardList extends Component {
  constructor(props) {
    super(props);
    //this.buildPath = this.buildPath.bind(this);
  }
  state = {
    data: priceData,
  };

  // async componentDidMount() {
  //   console.log(this.state.data);
  //   const token = cookie.get("token");
  //   try {
  //     const response = await axios.get(
  //       "https://server.gcmx.bluetag.tech/api/Transaction/PriceChangesForAllCommodityGrades",
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log("Success");
  //       this.setState({ data: response.data });
  //     } else {
  //       console.log("Failure");
  //       this.setState({ errorMessage: "Failed" });
  //       let error = new Error(response.statusText);
  //       // @ts-ignore
  //       error.response = response;
  //       throw error;
  //     }
  //   } catch (error) {
  //     this.setState({ errorMessage: "Failed" });
  //     console.error(
  //       "You have an error in your code or there are Network issues.",
  //       error
  //     );
  //   }
  // }

  /* buildPath = (data) => {
    return commodityIcons.filter((icon) => icon.id === data).length > 0
      ? "..img" +
          commodityIcons.filter((icon) => icon.id === data)[0].id +
          ".svg"
      : null;
  }; */
  
//{ require("../img/beans.svg") }

  static contextType = AppContext;

  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 4,
    //   slidesToScroll: 4,
    // };

    const { data } = this.state;
    const { toggleDarkMode } = this.context;

    return (
      <>
          <Marquee gradient={false}>
            {data.map((d, key) => (
              <Card
                key={key}
                className="card-stats"
                style={toggleDarkMode ? { backgroundColor: "black" } : null}
              >
                <CardBody>
                  <Row>
                    <div className="col-auto" style={{ padding: "0 5px" }}>
                      <Row>
                        <Col>
                          <CardTitle
                            className="mb-0"
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "22px",
                              letterSpacing: "-0.02em",
                              color: toggleDarkMode ? "lightgrey" : "#333333",
                            }}
                          >
                            {d.commodity} <br />
                            Grade {d.grade}
                          </CardTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "18px",
                              lineHeight: "28px",
                              display: "inline-block",
                            }}
                            className="font-weight-bold mb-0 text-success"
                          >
                            ₦ {d.price}
                          </span>{" "}
                          <span
                            style={{
                              fontSize: "14px",
                              lineHeight: "22px",
                              display: "inline-block",
                              color: toggleDarkMode ? "lightgrey" : "#333333",
                            }}
                            className=" mb-0"
                          >
                            {" "}
                            $ {d.second_price}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "10px",
                              lineHeight: "15px",
                              display: "inline-block",
                              color: toggleDarkMode ? "lightgrey" : "#333333",
                            }}
                            className="mb-0"
                          >
                            {" "}
                            (Per Tonne)
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="mb-0 text-muted">
                            <span
                              style={{
                                fontSize: "14px",
                                lineHeight: "22px",
                                color: " #333333",
                              }}
                              className="text-success mr-2"
                            >
                              {d._24_hr_change}
                            </span>{" "}
                            <span
                              style={{
                                fontSize: "14px",
                                lineHeight: "22px",
                                color: toggleDarkMode ? "lightgrey" : "#333333",
                              }}
                              className="text-nowrap"
                            >
                              Volume:{" "}
                              {d.total_quantity == null
                                ? 0
                                : `${d.total_quantity} tonnes`}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-auto">
                      <div className="rounded">
                        <img
                          src={require(`../img/${d.commodity_id.toLowerCase()}.svg`)} /* {this.buildPath(d.commodity_id)} */
                          /* srcSet={this.buildPath(
                            d.commodity_id
                          )} /*this.buildPath(d.commodity_id)*/ 
                          width="80"
                          height="80"
                          style={
                            toggleDarkMode ? { backgroundColor: "white" } : null
                          }
                          alt={`${d.commodity}`}
                        />
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            ))}
          </Marquee>
      </>
    );
  }
}

export default CarouselCardList;
