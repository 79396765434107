import React, { Component, useContext, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../Registration/register.css";
import { Card, CardBody, CardHeader, Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import { Formik } from "formik";
import cookie from "js-cookie";
import { useHistory, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { AppContext } from "../../../AppContext/AppContext";
// import PhoneInput from "react-phone-number-input";

const ForgotPassword = withRouter(({ history }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          axios
            .get(
              `https://server.gcmx.bluetag.tech/api/UserManager/SendForgetPasswordOTP?Email=${values.email}`
            )
            .then((res) => {
              //console.log(res);
              //console.log("Success");
              history.push("/auth/change-password");
            })
            .catch((err) => {
              //console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Col lg="7" md="12">
            <Card style={toggleDarkMode ? { backgroundColor: "black" } : null}>
              <div className="text-center text-muted mb-4">
                <h5
                  style={{
                    marginTop: "40px",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    color: toggleDarkMode ? "white" : "",
                  }}
                >
                  Forgot Password
                </h5>
              </div>
              <CardBody className="px-lg-5 py-lg-5">
                <Col md={14}>
                  <div className="step2Card">
                    <Form onSubmit={handleSubmit} className="form-group">
                      <div className="labels">
                        <FormGroup>
                          <Label for="email">
                            <span
                              style={{ color: toggleDarkMode ? "white" : null }}
                            >
                              Email
                            </span>
                          </Label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="forgot-password-input"
                            onChange={handleChange}
                            style={
                              toggleDarkMode
                                ? { backgroundColor: "#495057", color: "white" }
                                : null
                            }
                          />
                          {errors.email && touched.email && (
                            <div className="input-feedback float-left">
                              {errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </div>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          marginTop: "1%",
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        Please enter your email *
                      </p>
                      <Row>
                        <Col>
                          <button
                            className="forgot-password-btns"
                            type="submit"
                          >
                            <a href="/auth/login" style={{ color: "white" }}>
                              Back
                            </a>
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="forgot-password-btns"
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        );
      }}
    </Formik>
  );
});

export default ForgotPassword;
