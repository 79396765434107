import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Modal, Row, Col } from "reactstrap";
import "./delivery.css";
import { Link } from "react-router-dom";

const DeliveryHistoryClause = ({ data, toggleModal, deliveryClause }) => {
  const { deliveryHistory, toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={deliveryClause}
        toggle={() => toggleModal("deliveryClause")}
      >
        <div
          className="modal-body"
          style={
            toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
          }
        >
          <div className="row">
            <div className="col" style={{ textAlign: "center" }}>
              <h6
                className="modal-title"
                id="modal-title-default"
                style={{ color: toggleDarkMode ? "white" : "" }}
              >
                Delivery Clause
              </h6>
            </div>
            <div className="col-1">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal("deliveryClause")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          <Row>
            <Col>
              Please be informed that delivery items are to be confirmed within
              7 days of receipt of the item else, the delivery will be deemed
              completed.
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={5}>
              <button
                className="back-button"
                onClick={() => toggleModal("deliveryClause")}
              >
                Go Back
              </button>
            </Col>
            <Col md={5}>
              <Link
                to={{
                  pathname: "/admin/delivery-clearance",
                  state: { row: deliveryHistory },
                }}
              >
                <button
                  className="continue-button"
                  onClick={() => {
                    toggleModal("deliveryClause");
                  }}
                >
                  Continue
                </button>
              </Link>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DeliveryHistoryClause;
