import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import "./delivery.css";

export default function DeliveryHistoryTable({
  data,
  toggleModal,
  deliveryClause,
}) {
  const { updateDeliveryHistory, toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Date Requested</th>
            <th>Customer Name</th>
            <th>Customer ID</th>
            <th>Delivery ID</th>
            <th>Commodity</th>
            <th>Grade</th>
            <th>Quantity Requested(Tons)</th>
            <th>Delivery Fee</th>
            <th>Delivery Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {data.map((d) => (
            <tr style={{ fontFamily: "Poppins" }}>
              <td>{d.date_requested}</td>
              <td>{d.customer_name}</td>
              <td>{d.customer_id}</td>
              <td>{d.delivery_id}</td>
              <td>{d.commodities}</td>
              <td>{d.grade}</td>
              <td>{d.quantity_requested}</td>
              <td>{d.delivery_fee}</td>
              <td>{d.delivery_date}</td>
              <td>{d.status}</td>
              <td>
                {d.status === "Pending" ? (
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "#24B086",
                    }}
                    onClick={() => {
                      switch (d.status) {
                        case "Pending":
                          updateDeliveryHistory(d);
                          toggleModal("deliveryClause");
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    {d.action}
                  </button>
                ) : (
                  <Link
                    to={{
                      pathname: "/admin/delivery-clearance",
                      state: { row: d },
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        color: "#24B086",
                      }}
                    >
                      {d.action}
                    </button>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
