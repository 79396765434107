import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Modal } from "reactstrap";

import "../css/MyAccount.css";
import AccountTypeForm from "./AccountTypeForm";
import { AppContext } from "../../AppContext/AppContext";

class AccountTypeModal extends Component {
  constructor(props) {
    super(props);
    this.setFailureMessage = this.setFailureMessage.bind(this);
    this.state = {
      failureMessage: "",
    };
  }

  componentWillUnmount() {
    this.props.resetModal("AccountType");
  }

  setFailureMessage = (value) => {
    this.setState({ failureMessage: value });
  };

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.accountType}
          toggle={() => this.props.toggleModal("AccountType")}
        >
          <div
            className="modal-body"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6
                  className="modal-title"
                  id="modal-title-default"
                  style={{ color: toggleDarkMode ? "white" : "" }}
                >
                  Account Type
                </h6>
              </div>
              <div className="col-1">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("AccountType")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            {this.props.showAlert && (
              <div className="alert alert-danger" role="alert">
                {this.state.failureMessage}
              </div>
            )}
            <AccountTypeForm
              modalProps={this.props}
              handleSuccessMessage={this.props.handleSuccessMessage}
              setShowAlert={this.props.setShowAlert}
              setFailureMessage={this.setFailureMessage}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default AccountTypeModal;
