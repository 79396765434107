import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const { toggleDarkMode } = useContext(AppContext);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav
      style={{
        marginLeft: "10px",
      }}
    >
      <ul className="pagination text-success">
        {pageNumbers.map((number) => (
          <li className="page-item" key={number}>
            <a
              className="page-link"
              style={
                toggleDarkMode
                  ? { backgroundColor: "#495057", color: "lightgreen" }
                  : null
              }
              onClick={() => paginate(number)}
            >
              {number}
            </a>
          </li>
        ))}
        {/* <li className="page-item  disabled">
          <a class="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Pagination;
