/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";
import { loggedIn } from "../../services/auth.service";
// core components

function SpotMarket({ spotMarketData, loading }) {
  const { toggleDarkMode } = useContext(AppContext);
  const columns = spotMarketData[0] && Object.keys(spotMarketData[0]);
  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Commodities</th>
            <th>Grade</th>
            <th>Uom</th>
            <th>Last Price</th>
            <th>24H Change</th>
            <th>24H High</th>
            <th>24H Low</th>
            <th>Product Value</th>
            <th>Action</th>
            {/* {spotMarketData[0] && columns.map((heading) => <th>{heading}</th>)} */}
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {spotMarketData.map((spot, key) => (
            <tr key={key} style={{ fontFamily: "Poppins" }}>
              <td>{spot.commodity}</td>
              <td>{spot.grade}</td>
              <td>{spot.uom}</td>
              <td>₦{spot.last_price}</td>
              <td
                className={
                  spot._24_hr_change > 0 ? "text-success" : "text-danger"
                }
              >
                {spot._24_hr_change}
              </td>
              <td>₦{spot._24_hr_high}</td>
              <td>₦{spot._24_hr_low}</td>
              <td>₦{spot.product_value}</td>
              <td>
                <Link
                  to={{
                    pathname: loggedIn() ? "/admin/orderbook" : "/auth/login",
                    state: { row: spot, context: "personalBroker" },
                  }}
                >
                  <button className="btn btn-outline-success btn-sm">
                    Trade
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default SpotMarket;
{
  /* <UserHeader /> */
}
{
  /* Page content */
}
{
  /* <Container className="mt--20 my--15 bg-light" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Commodities</th>
                      <th>Uom</th>
                      <th>Total Qty</th>
                      <th>Total Available</th>
                      <th>In Order</th>
                      <th>Loan</th>
                      <th>Last Price</th>
                      <th>24H Change</th>
                      <th>24H High</th>
                      <th>24H Low</th>
                      <th>Product Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spotMarketData.map((spot) => (
                      <tr>
                        <th>Commodities</th>
                        <td>Uom</td>
                        <td>Total Qty</td>
                        <td>Total Available</td>
                        <td>In Order</td>
                        <td>Loan</td>
                        <td>Last Price</td>
                        <td>24H Change</td>
                        <td>24H High</td>
                        <td>24H Low</td>
                        <td>Product Value</td>
                        <td>
                          <Button>Action</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */
}
