/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import CommodityCardList from "./CommodityCardList";
import "./header.css";
import axios from "axios";
import cookie from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppContext } from "../../AppContext/AppContext";
import { Link } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kycAlert: true,
      data: false,
      errorMessage: "",
    };
  }

  static contextType = AppContext;
  async isVerifiedCustomer() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/CheckIfCustomerHasBeenVerified",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ kycAlert: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      // console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.isVerifiedCustomer();
  }

  render() {
    //const { toggleDarkMode } = useContext(AppContext);
    const { toggleDarkMode } = this.context;
    return (
      <>
        <div
          className={
            toggleDarkMode
              ? "header bg-dark pb-8 pt-5 pt-md-8"
              : "header bg-light pb-8 pt-5 pt-md-8"
          }
          //className="header bg-light pb-8 pt-5 pt-md-8"
          style={{ fontFamily: "Poppins" }}
        >
          <Container fluid>
            <div className="header-body">
              {/* <Row style={{ height: "180px", width: "100%" }}>
                <Col style={{ color: "#A9A9A9" }}></Col>
              </Row> */}
              {!this.state.kycAlert && (
                <Row className="justify-content-center">
                  <Col
                    className="col-4 text-center"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      Complete the rest of your profile.{" "}
                      <Link to="/admin/myaccount" className="alert-link">
                        {" "}
                        Go <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </Col>
                </Row>
              )}
              {/* <Row
                style={{
                  height: "250px",
                  marginBottom: "40px",
                  paddingTop: "40px",
                }}
              >
                <Col md={5} style={{ paddingLeft: "20px" }}>
                  <h2>
                    Nigeria's Leading Agro Commodity{" "}
                    <span
                      style={{
                        color: "#24b086",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                      }}
                    >
                      Exchange
                    </span>
                  </h2>
                  <p style={{ fontSize: "25px" }}>
                    The first of its kind in NIgeria. We provide fully
                    integrated and efficient platform for traders to thrive
                    beyond their limits.
                  </p>
                </Col>
                <Col></Col>
              </Row> */}
              <Row>
                <Col>
                  <CommodityCardList />
                </Col>
              </Row>

              {/* Card stats */}
            </div>
          </Container>
        </div>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        ></script>
      </>
    );
  }
}

export default Header;
