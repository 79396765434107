import { Formik, Form } from 'formik';
import { AppContext } from "../../AppContext/AppContext";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
    Card,
    Row,
    Container,
    Col,
    NavLink,
    FormGroup
} from "reactstrap";
import './overview.css';
import './withdrawal.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCodeInput from 'react-verification-code-input';

const WithdrawalForm = ({ data, balance }) => {
    const { userProfile, toggleDarkMode } = useContext(AppContext);
    const [showAlert, setShowAlert] = useState(false);

    const history = useHistory();

    const acctDetails = data.account_name + ' - ' + data.account_number + ' - ' + data.bank;
    return (
        <Formik
            initialValues={{
                amount: "",
                bank_details: "",
                transaction_pin: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    history.push("/admin/transaction-summary", { acctDetail: acctDetails, data: data, amount: values.amount })
                }, 1500);
            }}
            validationSchema={Yup.object().shape({
                amount: Yup.string().required().test('Sufficiency of funds', 'Insufficient funds', (value) => parseInt(value, 10) < parseInt(balance, 10)),
                bank_details: Yup.string().required(),
                transaction_pin: Yup.string().required()
            })
            }
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    setFieldValue,
                    setErrors,
                } = props;

                return (
                    <Form className="pt-3" onSubmit={handleSubmit}>
                        <FormGroup className="form-input">
                            <p>Withdrawal Amount</p>
                            <input name="amount" type="text" onChange={handleChange} value={values.amount} />
                            {errors.amount && touched.amount && (
                                <div className="input-feedback float-left">
                                    {errors.amount}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup className="form-input">
                            <p>My Bank Details</p>
                            <input type="text" disabled value={acctDetails} /><br />
                            <button className="borderless-button">Change Account</button>
                        </FormGroup>
                        <FormGroup className="form-input">
                            <p>Transaction Pin</p>
                            {<ReactCodeInput
                                className={
                                    showAlert
                                        ? toggleDarkMode
                                            ? "v-code v-code-error dark"
                                            : "v-code v-code-error"
                                        : toggleDarkMode
                                            ? "v-code dark"
                                            : "v-code"
                                }
                                type="number"
                                fields={4}
                                autoFocus={true}
                                //   onChange={(e) => {
                                //     handleChange(e);
                                //     values.OTPCode = e.currentTarget.value;
                                //   }}
                                //title="OTPCode"
                                onChange={(vals) => (values.transaction_pin = vals)}
                            //onComplete={(val) => //console.log("complete", val)}
                            />}
                            <button className="borderless-button">Create Transaction Pin</button>
                        </FormGroup>
                        <button className="next-button" type="submit">Next</button>
                    </Form>
                );
            }}
        </Formik>
    )
}


export default WithdrawalForm;