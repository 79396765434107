import React, { useState, useContext } from "react";
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { AppContext } from "../../AppContext/AppContext";

const RightComponent = ({ product, loading, productMarket }) => {
  const { toggleDarkMode } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navStyles = {
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };

  //console.log(product);

  return (
    <div>
      <Card
        className="mb-2 mb-xl-0"
        style={{
          height: "955px",
          backgroundColor: toggleDarkMode ? "black" : null,
        }}
      >
        <CardBody>
          <Row>
            <Col>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <>
                  <Nav pills className="rightComponentTab">
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },

                          "spotNavLink"
                        )}
                        onClick={() => {
                          toggle("1");
                        }}
                        style={navStyles}
                      >
                        <span style={{ color: toggleDarkMode ? "white" : "" }}>
                          Spot Market
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "spotNavLink"
                        )}
                        onClick={() => {
                          toggle("2");
                        }}
                        style={navStyles}
                      >
                        <span style={{ color: toggleDarkMode ? "white" : "" }}>
                          Futures Market
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="spotTabContent">
                    <TabPane tabId="1">
                      <div style={{ marginBottom: 10 }}>
                        <input
                          type="search"
                          className={
                            toggleDarkMode
                              ? classnames(
                                  "MyformControl",
                                  "inputSearch",
                                  "dark"
                                )
                              : classnames("MyformControl", "inputSearch")
                          }
                          placeholder="Search"
                          style={{
                            width: "100%",
                            paddingLeft: "12%",
                            marginTop: 10,
                            backgroundColor: toggleDarkMode ? "#495057" : "",
                            color: toggleDarkMode ? "white" : "",
                          }}
                        />
                      </div>

                      <hr />

                      <Table
                        className="table table-borderless table-sm"
                        style={{ marginLeft: -5, width: "auto" }}
                      >
                        <thead
                          style={{
                            background: "none",
                            color: toggleDarkMode ? "white" : null,
                            backgroundColor: toggleDarkMode ? "#495057" : null,
                          }}
                        >
                          <tr>
                            <th scope="col">Commodities</th>
                            <th scope="col">Grade</th>
                            <th scope="col">Last price </th>
                            <th scope="col">Change</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            color: toggleDarkMode ? "white" : null,
                            backgroundColor: toggleDarkMode ? "black" : null,
                          }}
                        >
                          {product.map((prod, index) => (
                            <tr key={index}>
                              <td>{prod.commodity}</td>
                              <td>{prod.grade}</td>
                              <td style={{ color: "red" }}>
                                {prod.last_price}
                              </td>
                              <td style={{ color: "red" }}>
                                {prod._24_hr_change > -1
                                  ? prod._24_hr_change
                                  : "--"}
                                {/* {(prod?._24_hr_Change).toFixed(2)}% */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <h3
                        style={{
                          marginTop: 20,
                          fontSize: "14px",
                          fontWeight: 600,
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        Market Trades
                      </h3>

                      <hr />

                      <Table
                        className="table table-borderless table-sm "
                        style={{ marginLeft: -6 }}
                        width="10%"
                      >
                        <thead
                          style={{
                            background: "none",
                            color: toggleDarkMode ? "white" : null,
                            backgroundColor: toggleDarkMode ? "#495057" : null,
                          }}
                        >
                          <tr>
                            <th scope="col">Price (NGN)</th>
                            <th scope="col">Qty (Tons) </th>
                            <th scope="col">Total (NGN)</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            color: toggleDarkMode ? "white" : null,
                            backgroundColor: toggleDarkMode ? "black" : null,
                          }}
                        >
                          {productMarket.map((prodMarket, index) => (
                            <tr key={index}>
                              <td>{prodMarket?.price}</td>
                              <td>{prodMarket?.quantity}</td>
                              <td>
                                {parseInt(prodMarket?.price) * parseInt(prodMarket?.quantity)*1000}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="2"></TabPane>
                  </TabContent>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default RightComponent;
