import { AppContext } from "../../AppContext/AppContext";
import { data } from "jquery";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import "./kyc.css";

function StatusModal({ toggle, modal, status, userID }) {
  let history = useHistory();
  const { toggleDarkMode } = useContext(AppContext);
  //console.log(userID);
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => toggle("modal")}
      >
        <div
          className="modal-body"
          style={
            toggleDarkMode
              ? {
                  backgroundColor: "#495057",
                  color: "white",
                }
              : null
          }
        >
          <div className="row justify-content-end">
            <div className="col-1">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggle("modal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          <div className="success-message">
            <i className="far fa-check-circle"></i>

            <p
              style={
                toggleDarkMode
                  ? {
                      color: "white",
                    }
                  : null
              }
            >
              You have successfully {status} {userID}
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <button
                className="btn btn-block btn-success button-default"
                style={{ background: "#24B086", marginBottom: "45px" }}
                onClick={() => {
                  toggle("modal");
                  history.push("/admin/kyc");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default StatusModal;
