/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Row, Container, Col, NavLink } from "reactstrap";
import "./overview.css";
import SpotTable from "../spotmarket/SpotTable";
// @ts-ignore
import { AppContext } from "AppContext/AppContext";
import Spinner from "reactstrap/lib/Spinner";
import axios from "axios";

const Overview = (prop) => {
  const {
    customersWalletSummary,
    customersWallet,
    fetchCustomerWallet,
    toggleDarkMode
  } = useContext(AppContext);
  const [consolidated, setconsolidated] = useState([]);
  const [loading, setloading] = useState(true);
  const [dollar, setDollar] = useState()
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  useEffect(() => {
    fetchCustomerWallet();
    if (customersWalletSummary != null) {
      //setloading(false);
      //console.log(customersWalletSummary);
      const arr = customersWalletSummary?.map((e) => {
        return {
          name: e.customer,
          customer_id: e.customer_id,
          spot: "Spot Market",
          future: "Future Market",
          amountNaira: e.spot_market,
          amountDollar: e.futures_market,
        };
      });
      
    let stringBalance = (parseInt(customersWallet.estimated_balance) * 10000).toString()
    axios.get(`https://server.gcmx.bluetag.tech/api/Common/CurrencyConverter?currenctFrom=NGN&currencyTo=USD&amount=${stringBalance}`)
      .then(res => {
        setDollar(res.data)
      })
      .catch(error => console.log(error))
    setconsolidated(arr);
    }
  }, [customersWalletSummary, fetchCustomerWallet, customersWallet]);

  const market = [
    {
      title: "Spot",
      naira: customersWallet?.spot_market
        ? formatter.format(customersWallet?.spot_market)
        : "₦0",
      dollar: customersWallet?.spot_market_second_value
        ? formatter.format(customersWallet?.spot_market_second_value)
        : "₦0",
    },
    {
      title: "Future",
      naira: customersWallet?.futures_market
        ? formatter.format(customersWallet?.futures_market)
        : "₦0",
      dollar: customersWallet?.futures_market_second_value
        ? formatter.format(customersWallet?.futures_market_second_value)
        : "₦0",
    },
  ];

  
  return (
    <>
      <Container
        fluid
        className={toggleDarkMode ? "bg-dark pb-5" : "bg-light pb-5"}
      >
        <Row>
          <div className="col-12 mb-5 mb-xl-0">
            <h3 className={toggleDarkMode ? "page-title dark" : "page-title"}>
              Overview
            </h3>
            <Card
              className={
                toggleDarkMode ? "overview-card dark" : "overview-card"
              }
            >
              <small className="overview-card-title">Estimated Balance</small>
              <Row>
                <Col md="9">
                  <p>
                    <span
                      className="h6"
                      style={toggleDarkMode ? { color: "white" } : null}
                    >
                      {" "}
                      {customersWallet?.estimated_balance
                        ? `₦ ${parseInt(customersWallet?.estimated_balance) * 10000}`
                        : "₦0"}{" "} {/* estimated balance is in kobo */}
                      |{" "}
                    </span>{" "}
                    <small>{" "}
                      {dollar
                        ? `$${parseInt(dollar)}`
                        : "$0"}{" "}</small>
                  </p>
                </Col>
                <Col md="3" className="flex-e">
                  <Link
                    className="deposit-btn mx-3 px-2"
                    to="/admin/deposit"
                    tag={Link}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  >
                    Deposit
                  </Link>
                  <Link
                    className="deposit-btn px-2"
                    to="/admin/withdrawal"
                    tag={Link}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  >
                    Withdrawal
                  </Link>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="col-12 mt-4 mb-5">
            {market.map((prop, idx) => (
              <Card
                key={idx}
                className={
                  toggleDarkMode ? "overview-card dark" : "overview-card"
                }
              >
                <span className="overview-card-title">{prop.title} Market</span>
                <Row>
                  <Col md="3">
                    <p>
                      <span
                        className="overview-money"
                        style={toggleDarkMode ? { color: "white" } : null}
                      >
                        {prop.naira} |{" "}
                      </span>{" "}
                      <small>${prop.dollar}</small>
                    </p>
                  </Col>
                  <Col md="6">
                    <span className="overview-card-text">
                      This is your {prop.title.toLowerCase()} trading account.
                      Simply transfer funds to start trading
                    </span>
                  </Col>
                  <Col md="3" className="flex-e">
                    <Link
                      to={{
                        pathname: "/admin/deposit/spot",
                        params: { type: "spot" },
                      }}
                      className="deposit-btn mx-3 px-2"
                      style={
                        toggleDarkMode
                          ? {
                              backgroundColor: "#495057",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Deposit
                    </Link>
                    <Link
                      to="/admin/overview"
                      className="deposit-btn  px-2"
                      style={
                        toggleDarkMode
                          ? {
                              backgroundColor: "#495057",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Withdrawal
                    </Link>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Overview;
