import React, { useContext, useState } from "react";
import { Table } from "reactstrap";
import axios from "axios";
import { getToken } from "../../services/auth.service";
import Pagination from "../Market/Pagination";
import { BASEURL } from "./baseUrl";
import { AppContext } from "../../AppContext/AppContext";

const OpenOrder = ({ openOrder }) => {
  //console.log(openOrder);
  const token = getToken();
  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost1 = currentPage * postsPerPage;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage;
  const currentPosts1 = openOrder.slice(indexOfFirstPost1, indexOfLastPost1);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const cancelOrder = (instruction_id) => {
    // //console.log(instruction_id);
    axios
      .get(
        `https://server.gcmx.bluetag.tech/api/Transaction/CancelOrder?InstructionID=${instruction_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        //console.log(res);
        // fetchInstruction();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const [modal, setModal] = useState(false);

  const { toggleDarkMode } = useContext(AppContext);

  const toggle = () => setModal(!modal);
  return (
    <div>
      <Table bordered className="mb-2">
        <thead
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "#495057" : null,
          }}
        >
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Instruction ID</th>
            <th scope="col">Commodities</th>
            <th scope="col">Side</th>
            <th scope="col">Type</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price (NGN)</th>
            <th scope="col">Amount (NGN)</th>
            <th scope="col">Executed</th>
            <th scope="col">Status</th>
            <th scope="col">Placed by</th>
          </tr>
        </thead>
        <tbody
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {currentPosts1.map((openOrd, index) => (
            <tr key={index}>
              <td>{openOrd.date}</td>
              <td>{openOrd.instruction_id}</td>
              <td>{openOrd.commodity} (kg)</td>
              <td style={{ color: "tomato" }}>{openOrd.trade_side} </td>
              <td>{openOrd.market_type}</td>
              <td>{openOrd.quantity}</td>
              <td>{openOrd.price}</td>
              <td>{openOrd.amount}</td>
              <td>Nil</td>
              <td>{openOrd.status}</td>
              <td>{openOrd.customer_name}</td>
              <td
                style={{ color: "tomato", cursor: "pointer" }}
                onClick={() => cancelOrder(openOrd.instruction_id)}
              >
                Cancel
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={openOrder.length}
        paginate={paginate}
      />
    </div>
  );
};

export default OpenOrder;
