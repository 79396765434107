import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Modal } from "reactstrap";

import "../css/MyAccount.css";
import "./modalsizing.css";
import UpdateUserForm from "./UpdateUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import OTPForm2 from "./OTPForm2";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class AccountDetailsModals extends Component {
  constructor(props) {
    super(props);
    this.loadCountryList = this.loadCountryList.bind(this);
    this.state = {
      countryList: [],
    };
  }

  componentWillUnmount() {
    this.props.resetModal("ChangePassword");
    this.props.resetModal("AccountDetails");
    this.props.resetModal2("modalview");
    this.props.resetModal2("modalview2");
    this.props.resetModal2("modalview3");
  }

  async loadCountryList() {
    try {
      const token = cookie.get("token");

      //console.log(this.state);

      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/CountryList",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      //const response = loginInfo;

      if (response.status === 200) {
        //console.log("Success");
        //const permissions = response.data.permissions;
        this.setState({ countryList: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      // console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.loadCountryList();
  }

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;

    let item;

    if (this.props.modalview2) {
      //console.log("It's working");
      //console.log(this.props.modalview);
      item = (
        <div className="modal-view-2">
          <div
            className="modal-body text-center"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row justify-content-right">
              <div className="col">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("ChangePassword")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <i
              style={{
                fontSize: "30px",
                textAlign: "center",
                color: "#24b086",
              }}
              className="fas fa-mobile"
            ></i>
            <h3 style={toggleDarkMode ? { color: "white" } : null}>
              Verification Code
            </h3>
            <p style={toggleDarkMode ? { fontWeight: "bold" } : null}>
              Verification code has been sent to your phone number
            </p>
            <h5 style={toggleDarkMode ? { color: "white" } : null}>
              Please enter the code below
            </h5>
            <OTPForm2 modalProps={this.props} />
          </div>
        </div>
      );
    } else if (this.props.modalview3) {
      item = (
        <div className="modal-view-3">
          <div
            className="modal-body"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row justify-content-right">
              <div className="col">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("ChangePassword")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <div className="success-message">
              <i className="far fa-check-circle"></i>

              <p style={{ color: toggleDarkMode ? "white" : "" }}>Success</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086", marginBottom: "45px" }}
                  onClick={() => {
                    this.props.toggleModal("ChangePassword");
                    this.props.toggleModal2("modalview3");
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.accountDetails}
          toggle={() => this.props.toggleModal("AccountDetails")}
          style={toggleDarkMode ? { backgroundColor: "black" } : null}
          size="lg"
        >
          <div
            className="modal-body"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row">
              <div
                className="col"
                style={{
                  textAlign: "center",
                }}
              >
                <h6
                  className="modal-title"
                  id="modal-title-default"
                  style={{ color: toggleDarkMode ? "white" : "" }}
                >
                  Account Overview
                </h6>
              </div>
              <div className="col-1">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("AccountDetails")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <UpdateUserForm
              modalProps={this.props}
              refreshUserProfile={this.props.refreshUserProfile}
              refreshPassport={this.props.refreshPassport}
              countryList={this.state.countryList}
            />
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.changePassword}
          toggle={() => this.props.toggleModal("ChangePassword")}
          size="md"
        >
          {this.props.modalview ? (
            <div className="modal-view-1">
              <div
                className="modal-body"
                style={
                  toggleDarkMode
                    ? { backgroundColor: "black", color: "white" }
                    : null
                }
              >
                <div className="row">
                  <div
                    className="col"
                    style={{
                      textAlign: "center",
                      color: toggleDarkMode ? "white" : "",
                    }}
                  >
                    <h6
                      className="modal-title"
                      id="modal-title-default"
                      style={{ color: toggleDarkMode ? "white" : "" }}
                    >
                      Change Password
                    </h6>
                  </div>
                  <div className="col-1">
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.props.toggleModal("ChangePassword")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                </div>
                <ChangePasswordForm modalProps={this.props} />
              </div>
            </div>
          ) : (
            item
          )}
        </Modal>
      </>
    );
  }
}

export default AccountDetailsModals;
