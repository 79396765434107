import React, { useContext, useState, useEffect } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { imagePath } from "../../services/utility.service";

const UpdateUserForm = ({ modalProps, refreshUserProfile, refreshPassport, countryList }) => {

  const idType = modalProps.data.means_of_identification != "National ID" && modalProps.data.means_of_identification != "Voters Card" && modalProps.data.means_of_identification != "Driver's Licence" && modalProps.data.means_of_identification != "National Passport" ? true : false;

  const { toggleDarkMode } = useContext(AppContext);
  const [showOther, setShowOther] = useState(false);
  const [passport, setPassport] = useState("");
  const [fileSelected, setFileSelected] = useState();
  const token = cookie.get("token");

  //console.log(idType);
  //console.log(modalProps.data.means_of_identification);

  // useEffect(() => {
  //   setShowOther(idType);
  // }, [idType])


  const hexToBase64 = (str) => {
    return btoa(str);
  }

  const handleImage = (event) => {
    setPassport(event.target.files[0].name);
    setFileSelected(event.target.files[0]);
  }
  const UpdateUser = (formValues) => {
    axios
      .post(
        "https://server.gcmx.bluetag.tech/api/UserManager/ProfileUpdate",
        formValues,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        //console.log("Success");
        refreshUserProfile("data", formValues);
        modalProps.toggleModal("AccountDetails");
        modalProps.handleMessage(res.data);
        modalProps.setShowAlert();
        setTimeout(() => {
          modalProps.setShowAlert();
        }, 5000);
      })
      .catch((err) => {
        //console.log(err.response?.data);
      });
  }

  const UpdatePassport = (formValues) => {
    var bodyFormData = new FormData();

    bodyFormData.append('Passport', formValues);
    axios
      .post(
        "https://server.gcmx.bluetag.tech/api/UserManager/PassportUpload",
        bodyFormData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        console.log(imagePath + '/files/' + passport);
        console.log("Passport Upload Success");
        refreshPassport("imagePath", imagePath + '/files/' + passport);
      })
      .catch((err) => {
        //console.log(err.response?.data);
      });
  }

  return (
    <Formik
      initialValues={{
        user_id: modalProps.data.user_id, //1
        phone: modalProps.data.phone,
        email: modalProps.data.email,
        password: modalProps.data.password, //2
        lastname: modalProps.data.lastname,
        firstname: modalProps.data.firstname,
        othername: modalProps.data.othername,
        role: modalProps.data.role,
        customer_id: modalProps.data.customer_id,
        broker_id: modalProps.data.broker_id, //3
        country: modalProps.data.country,
        address: modalProps.data.address,
        account_name: modalProps.data.account_name,
        account_number: modalProps.data.account_number,
        bank: modalProps.data.bank,
        account_type: modalProps.data.account_type,
        bvn: modalProps.data.bvn,
        age: modalProps.data.age,
        marital_status: modalProps.data.marital_status,
        tax_id: modalProps.data.tax_id,
        name_of_employer: modalProps.data.name_of_employer,
        position_in_company: modalProps.data.position_in_company,
        means_of_identification: modalProps.data.means_of_identification,
        card_issue_date: modalProps.data.card_issue_date,
        card_number: modalProps.data.card_number,
        card_expiry_date: modalProps.data.card_expiry_date,
        nok_phone_number: modalProps.data.nok_phone_number,
        nok_address: modalProps.data.nok_address,
        nok_firstname: modalProps.data.nok_firstname,
        passport: modalProps.data.passport,
        nok_lastname: modalProps.data.nok_lastname,
        sex: modalProps.data.sex,
        state: modalProps.data.state,
        /* nationality: modalProps.data.nationality, */
      }}
      onSubmit={(values, { setSubmitting }) => {
        //console.log(values);
        setTimeout(() => {
          // const user = {
          //   lastname: values.lastname,
          //   firstname: values.firstname,
          //   othername: values.othername,
          //   email: values.email,
          //   phone: values.phone,
          // };
          console.log(fileSelected);
          UpdateUser(values);
          UpdatePassport(fileSelected);
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        lastname: Yup.string().required("Required"),
        firstname: Yup.string().required("Required"),
        othername: Yup.string().required("Required"),
        email: Yup.string().email("E-mail is not valid!").required("Required"),
        phone: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        if (values.means_of_identification != "National ID" && values.means_of_identification != "Voters Card" && values.means_of_identification != "Driver's Licence" && values.means_of_identification != "National Passport") {
          setShowOther(true);
        } else {
          setShowOther(false);
        }

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="lastname">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Last Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Last Name"
                    name="lastname"
                    onChange={handleChange}
                    value={values.lastname}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.lastname && touched.lastname && (
                    <div className="input-feedback float-left">
                      {errors.lastname}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="firstname">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      First Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="First Name"
                    name="firstname"
                    onChange={handleChange}
                    value={values.firstname}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.firstname && touched.firstname && (
                    <div className="input-feedback float-left">
                      {errors.firstname}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="othername">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Other Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Other Name"
                    name="othername"
                    onChange={handleChange}
                    value={values.othername}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.othername && touched.othername && (
                    <div className="input-feedback float-left">
                      {errors.othername}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="state">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      State
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="state"
                    onChange={handleChange}
                    value={values.state}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.state && touched.state && (
                    <div className="input-feedback float-left">
                      {errors.state}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="marital_status">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Marital Status
                    </span>
                  </Label>
                  <select
                    name="marital_status"
                    id="marital_status"
                    value={values.marital_status}
                    onChange={handleChange}
                    className="dropdown-custom2 full-length"
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  >
                    <option

                      value="Single"
                    >
                      Single
                    </option>
                    <option

                      value="Married"
                    >
                      Married
                    </option>
                    <option

                      value="Widow"
                    >
                      Widow
                    </option>
                    <option

                      value="Widower"
                    >
                      Widower
                    </option>
                  </select>
                  {errors.marital_status && touched.marital_status && (
                    <div className="input-feedback float-left">
                      {errors.marital_status}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="age">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Age
                    </span>
                  </Label>
                  <Input
                    type="number"
                    className="form-control textbox-custom2"
                    name="age"
                    onChange={handleChange}
                    value={values.age}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.age && touched.age && (
                    <div className="input-feedback float-left">
                      {errors.age}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="sex">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Sex
                    </span>
                  </Label>

                  <select
                    name="sex"
                    id="sex"
                    value={values.sex}
                    onChange={handleChange}
                    className="dropdown-custom2 full-length"
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  >
                    <option
                      value="Male"
                    >
                      Male
                    </option>
                    <option
                      value="Female"
                    >
                      Female
                    </option>
                  </select>

                  {errors.sex && touched.sex && (
                    <div className="input-feedback float-left">
                      {errors.sex}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="means_of_id">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Means of ID
                    </span>
                  </Label>
                  <select
                    className="dropdown-custom2"
                    name="means_of_identification"
                    onChange={handleChange}
                    placeholder="Select an ID type"
                    defaultValue={values.means_of_identification}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }>
                    <option value="National ID">National ID</option>
                    <option value="Voters Card">Voters Card</option>
                    <option value="Driver's Licence">Driver's Licence</option>
                    <option value="National Passport">National Passport</option>
                    <option selected={showOther}>Other</option>
                  </select>
                  {showOther ?
                    <Input
                      type="text"
                      className="form-control textbox-custom2"
                      name="means_of_identification"
                      onChange={handleChange}
                      defaultValue={idType ? modalProps.data.means_of_identification : ""}
                      style={
                        toggleDarkMode
                          ? {
                            backgroundColor: "#495057",
                            color: "white",
                            marginTop: "5px"
                          }
                          : {
                            marginTop: "5px"
                          }
                      }
                    /> : null}
                  {errors.means_of_identification &&
                    touched.means_of_identification && (
                      <div className="input-feedback float-left">
                        {errors.means_of_identification}
                      </div>
                    )}
                </FormGroup>
              </Col>
              {/* <Col>
                <FormGroup>
                  <Label htmlFor="phone">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Phone Number
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.phone && touched.phone && (
                    <div className="input-feedback float-left">
                      {errors.phone}
                    </div>
                  )}
                </FormGroup>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="card_number">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Card Number
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Card Number"
                    name="card_number"
                    onChange={handleChange}
                    value={values.card_number}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.card_number && touched.card_number && (
                    <div className="input-feedback float-left">
                      {errors.card_number}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="card_issue_date">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Card Issue Date
                    </span>
                  </Label>
                  <Input
                    type="date"
                    name="card_issue_date"
                    id="card_issue_date"
                    onChange={handleChange}
                    value={values.card_issue_date}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.card_issue_date && touched.card_issue_date && (
                    <div className="input-feedback float-left">
                      {errors.card_issue_date}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="card_expiry_date">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Card Expiry Date
                    </span>
                  </Label>
                  <Input
                    type="date"
                    name="card_expiry_date"
                    id="card_expiry_date"
                    onChange={handleChange}
                    value={values.card_expiry_date}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.card_expiry_date && touched.card_expiry_date && (
                    <div className="input-feedback float-left">
                      {errors.card_expiry_date}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="name_of_employer">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Name of Employer
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="name_of_employer"
                    onChange={handleChange}
                    value={values.name_of_employer}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.name_of_employer && touched.name_of_employer && (
                    <div className="input-feedback float-left">
                      {errors.name_of_employer}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="position_in_company">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Position in Company
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="position_in_company"
                    onChange={handleChange}
                    value={values.position_in_company}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.position_in_company &&
                    touched.position_in_company && (
                      <div className="input-feedback float-left">
                        {errors.position_in_company}
                      </div>
                    )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="tax_id">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Tax ID
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="tax_id"
                    onChange={handleChange}
                    value={values.tax_id}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.tax_id && touched.tax_id && (
                    <div className="input-feedback float-left">
                      {errors.tax_id}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-4">
                <FormGroup>
                  <Label for="country">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Country
                    </span>
                  </Label>
                  <select
                    value={values.country}
                    name="country"
                    id="country"
                    className="dropdown-custom2"
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  >
                    {countryList.map((d) => (
                      <option
                        value={d.name}
                      >
                        {d.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && touched.country && (
                    <div className="input-feedback float-left">
                      {errors.country}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {/* <Col className="col-md-4">
                <FormGroup>
                  <Label htmlFor="email">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Email
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback float-left">
                      {errors.email}
                    </div>
                  )}
                </FormGroup>
              </Col> */}
              <Col>
                <FormGroup>
                  <Label for="phone">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Phone
                    </span>
                  </Label>
                  <PhoneInput
                    country={"ng"}
                    // @ts-ignore
                    name="phone"
                    value={values.phone}
                    onBlur={(e) => {
                      handleBlur(e);
                      values.phone = e.currentTarget.value;
                    }}
                    className={errors.phone && touched.phone && "error"}
                    inputStyle={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                    buttonStyle={
                      toggleDarkMode ? { backgroundColor: "#495057" } : null
                    }
                    dropdownStyle={
                      toggleDarkMode ? { backgroundColor: "#495057" } : null
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <FormGroup>
                  <Label htmlFor="address">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Address
                    </span>
                  </Label>
                  <textarea
                    className="form-control"
                    placeholder="Enter your Full Address"
                    name="address"
                    id="address"
                    value={values.address}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.address && touched.address && (
                    <div className="input-feedback float-left">
                      {errors.address}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="passport">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Profile Pic
                    </span>
                  </Label>
                  <br />
                  <input
                    name="passport"
                    type="file"
                    id="passport"
                    onChange={handleImage}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.passport && touched.passport && (
                    <div className="input-feedback float-left">
                      {errors.passport}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    color: toggleDarkMode ? "white" : null,
                  }}
                >
                  Next of Kin Details
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="nok_lastname">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Last Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Last Name"
                    name="nok_lastname"
                    onChange={handleChange}
                    value={values.nok_lastname}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.nok_lastname && touched.nok_lastname && (
                    <div className="input-feedback float-left">
                      {errors.nok_lastname}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="nok_firstname">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      First Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="First Name"
                    name="nok_firstname"
                    onChange={handleChange}
                    value={values.nok_firstname}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.nok_firstname && touched.nok_firstname && (
                    <div className="input-feedback float-left">
                      {errors.nok_firstname}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="nok_phone_number">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Phone Number
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Phone Number"
                    name="nok_phone_number"
                    onChange={handleChange}
                    value={values.nok_phone_number}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.nok_phone_number && touched.nok_phone_number && (
                    <div className="input-feedback float-left">
                      {errors.nok_phone_number}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <FormGroup>
                  <Label htmlFor="nok_address">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Address
                    </span>
                  </Label>
                  <textarea
                    className="form-control"
                    placeholder="Enter their Full Address"
                    name="nok_address"
                    id="nok_address"
                    value={values.nok_address}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                        : null
                    }
                  />
                  {errors.nok_address && touched.nok_address && (
                    <div className="input-feedback float-left">
                      {errors.nok_address}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-4">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateUserForm;
