import { AppContext } from "../../AppContext/AppContext";
import React, { useContext, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardText,
  CardImg,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

import "../css/MyAccount.css";

const SelectableCardList = ({ data, setSelectedBroker, handleChange }) => {
  const [cardsPerRow, setCardsPerRow] = useState(3);
  const [rowNumbers, setRowNumbers] = useState([]);
  const { toggleDarkMode } = useContext(AppContext);

  const calculate = (data, cardsPerRow) => {
    let rowNumbersCount = [];
    //console.log(data.length);
    //console.log(cardsPerRow);
    for (let i = 1; i <= Math.ceil(data.length / cardsPerRow); i++) {
      rowNumbersCount.push(i);
    }
    //console.log(rowNumbersCount);
    setRowNumbers(rowNumbersCount);
  };

  useEffect(() => {
    // const { rowNumbers } = this.state;
    calculate(data, cardsPerRow);
    console.log(data);
    //console.log(rowNumbers);
  }, []);

  return (
    <>
      <div className="scroll">
        <Row>
          <Col>
            {rowNumbers.map((number) => (
              <Row key={number}>
                {data
                  .slice(cardsPerRow * number - cardsPerRow, cardsPerRow * number)
                  .map((d) => (
                    <Col key={d.id} lg={4}>
                      <Card
                        className="cardItem"
                        style={{
                          padding: "1px",
                          backgroundColor: toggleDarkMode ? "#495057" : "",
                        }}
                      >
                        <CardImg
                          top
                          // className="card-photo"
                          src={d.photo || "https://i.ibb.co/Mn7dbq3/avatar.jpg"}
                          width="100%"
                          height="150"
                        />
                        <CardBody
                          style={{
                            backgroundColor: toggleDarkMode ? "black" : ""

                          }}
                        >
                          <CardText className="cardTextName">
                            <span
                              style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px", lineHeight: "8px" }}
                            >
                              {d.broker_name}
                            </span>
                          </CardText>
                          <CardText
                            className="cardText"
                            style={{ fontSize: "12px" }}
                          >
                            <span
                              style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                            >
                              ID: {d.broker_id}
                            </span>
                          </CardText>
                          <CardText
                            className="cardText"
                            style={{ color: "#EAA221", fontSize: "10px" }}
                          >
                            <span
                              style={{ color: toggleDarkMode ? "white" : "", fontSize: "15px" }}
                            >
                              4.0
                            </span>{" "}
                            <i className="fas fa-star"></i>
                          </CardText>
                          <FormGroup check>
                            <Label
                              htmlFor="selectedBroker"
                              // onClick={() => {
                              //   setSelectedBroker(d.brokerID);
                              // }}
                              check
                            >
                              <Input
                                type="radio"
                                name="selectedBroker"
                                onChange={handleChange}
                                // onClick={() => {
                                //   setSelectedBroker(d.brokerID);
                                // }}
                                value={d.broker_id}
                                style={{ position: "static" }}
                              />{" "}
                              <i
                                className="fas fa-link"
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              ></i>
                            </Label>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SelectableCardList;
