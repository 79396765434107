import { AppContext } from "../../AppContext/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Card, Row, Container, Col } from "reactstrap";
import "./overview.css";

const BrokerWallet = () => {
  const { customersWallet, toggleDarkMode } = useContext(AppContext);
  const [market, setmarket] = useState([
    {
      title: "Spot",
      naira: customersWallet.spot_market,
      dollar: "0.00",
    },
    {
      title: "Future",
      naira: customersWallet.futures_market,
      dollar: "0.00",
    },
  ]);
  useEffect(() => {
    //console.log(customersWallet);
    const obj = customersWallet;
  }, [customersWallet]);

  return (
    <>
      <Container
        fluid
        className={toggleDarkMode ? "bg-dark pb-5" : "bg-light pb-5"}
      >
        <Row>
          <div className="col-12 mb-5 mb-xl-0">
            <h3 className={toggleDarkMode ? "page-title dark" : "page-title"}>
              Overview
            </h3>
            <Card
              className={
                toggleDarkMode ? "overview-card dark" : "overview-card"
              }
            >
              <small className="overview-card-title">Estimated Balance</small>
              <Row>
                <Col md="9">
                  <p>
                    <span
                      className="h6"
                      style={toggleDarkMode ? { color: "white" } : null}
                    >
                      ₦ {customersWallet.estimated_balance} |{" "}
                    </span>{" "}
                    <small>$0.00</small>
                  </p>
                </Col>
                <Col md="3" className="flex-e">
                  <Link
                    className="deposit-btn mx-3 px-2"
                    to="/admin/deposit"
                    tag={Link}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  >
                    Deposit
                  </Link>
                  <Link
                    className="deposit-btn px-2"
                    to="/admin/withdrawal"
                    tag={Link}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  >
                    Withdrawal
                  </Link>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="col-12 mt-4 mb-5">
            {market.map((prop, idx) => (
              <Card
                key={idx}
                className={
                  toggleDarkMode ? "overview-card dark" : "overview-card"
                }
              >
                <span className="overview-card-title">{prop.title} Market</span>
                <Row>
                  <Col md="3">
                    <p>
                      <span
                        className="overview-money"
                        style={toggleDarkMode ? { color: "white" } : null}
                      >
                        ₦ {prop.naira} |{" "}
                      </span>{" "}
                      <small>${prop.dollar}</small>
                    </p>
                  </Col>
                  <Col md="6">
                    <span className="overview-card-text">
                      This is your {prop.title.toLowerCase()} trading account.
                      Simply transfer funds to start trading
                    </span>
                  </Col>
                  <Col md="3" className="flex-e">
                    <Link
                      to={{
                        pathname: "/admin/deposit/spot",
                        params: { type: "spot" },
                      }}
                      className="deposit-btn mx-3 px-2"
                      style={
                        toggleDarkMode
                          ? {
                              backgroundColor: "#495057",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Deposit
                    </Link>
                    <Link
                      to="/admin/withdrawal"
                      className="deposit-btn  px-2"
                      style={
                        toggleDarkMode
                          ? {
                              backgroundColor: "#495057",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Withdrawal
                    </Link>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BrokerWallet;
