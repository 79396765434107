import cookie from "js-cookie";
import axios from "axios";

export const login = (token, email, mobile, permissions) => {
  cookie.set("token", token, { expires: 1 });
  cookie.set("email", email, { expires: 1 });
  cookie.set("mobile", mobile, { expires: 1 });
  window.localStorage.setItem("permissions", permissions);
};

export const fetchPermissions = (token) => {
  var config = {
    method: "get",
    url: "https://server.gcmx.bluetag.tech/api/UserManager/RolePermission",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  axios(config)
    .then(function (response) {
      window.localStorage.setItem("permissions", JSON.stringify(response.data));
    })
    .catch(function (error) {
      //console.log(error);
    });
};

export const logout = () => {
  cookie.remove("token");
  cookie.remove("email");
  cookie.remove("mobile");
  window.localStorage.removeItem("permissions");
  // to support logging out from all windows
  window.localStorage.setItem("logout", Date.now());
};

export const loggedIn = () => {
  const token = cookie.get("token");
  if (!token) {
    return false;
  }
  return true;
};

export const getToken = () => {
  return cookie.get("token");
};

export const getUserRole = () => {
  return cookie.get("role");
};

export const userHasPermission = (path) => {
  const userPermissions = window.localStorage.getItem("permissions");
  if (userPermissions) {
    const permissions = JSON.parse(userPermissions);
    if (permissions.filter((perm) => perm.path === path).length > 0)
      return true;
    else return false;
  }
  return false;
};

export const whatRoleIsThis = () => {
  const userPermissions = window.localStorage.getItem("permissions");
  if (userPermissions) {
    const permissions = JSON.parse(userPermissions);
    if (permissions.filter((perm) => perm.role === "Broker").length > 0)
      return "Broker";
    else return "Customer";
  }
  return "No Permissions";
};

export const getUserPermissionsByCategory = (category) => {
  const userPermissions = window.localStorage.getItem("permissions");
  if (userPermissions) {
    const permissions = JSON.parse(userPermissions);
    return permissions.filter(
      (perm) => perm.Category.toLowerCase() === category.toLowerCase()
    );
  }
  return [];
};

export const isAdmin = () => {
  const role = cookie.get("role");
  if (role && role.toLowerCase() === "admin") return true;
  return false;
};

export const isAggregateUser = () => {
  const aggregateUser = cookie.get("aggregate");
  if (aggregateUser && aggregateUser.toLowerCase() === "true") return true;
  return false;
};
