import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import "./kyc.css";

function KycTable({ data }) {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Date</th>
            <th>Customer Name</th>
            <th>Customer ID</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {data.map((d) => (
            <>
              <tr style={{ fontFamily: "Poppins" }} key={d.instruction_id}>
                <td>{d.date}</td>
                <td>{d.customer_name}</td>
                <td>{d.customer_id}</td>
                <td>{d.status}</td>
                <td>
                  <Link
                    to={{
                      pathname: "/admin/customer-kyc-details",
                      state: { row: d },
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        color: "#24B086",
                      }}
                    >
                      View Details
                    </button>
                  </Link>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default KycTable;
