import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import "./Spots.css";
import CustomerTradeInstruction from "./CustomerTradeInstruction";
import PriceChart from "./Chart.js";
import axios from "axios";
import { getToken } from "../../services/auth.service";
import candlestickData from "../../dummyData/candlestickData";
import { AppContext } from "../../AppContext/AppContext";

const CustomerMiddleComponent = ({ product, fetchInstruction }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const token = getToken();
  //console.log(product);
  const { commodity_id, grade } = product;
  const fetch_chart_per_commidty_grade = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://server.gcmx.bluetag.tech/api/Transaction/MarketChartPerCommodityGrade`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { commodity_id: commodity_id, grade: grade },
        }
      );
      //console.log(res);
      setDataChart(res.data.chart);
    } catch (error) {
      //console.log(error);
    }
  }, [commodity_id, grade, token]);

  useEffect(() => {
    fetch_chart_per_commidty_grade();
  }, [fetch_chart_per_commidty_grade]);

  const { toggleDarkMode } = useContext(AppContext);

  return (
    <div>
      <Container>
        <div className="header-body">
          <div className="mb-1">
            <span
              className="textMuted"
              style={toggleDarkMode ? { color: "white" } : null}
            >
              Spot
            </span>{" "}
            <span
              style={{ fontSize: 13, color: toggleDarkMode ? "white" : "" }}
            >
              &#62;
            </span>{" "}
            <span
              style={{ fontSize: 13, color: toggleDarkMode ? "white" : "" }}
            >
              Trade
            </span>
          </div>
          {/* <div className="mb-2">
            <span className="tradeName">
              Mr. Onyedikachi Ugwu | GEZAWA/CUS/00257
            </span>
          </div> */}
          <Row>
            <Col lg="12" xl="12">
              <Card
                className="mb-2 mb-xl-0"
                style={{
                  backgroundColor: toggleDarkMode ? "black" : "",
                }}
              >
                <CardBody>
                  <Row>
                    <Col md="2" className="trade">
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        {product ? product.commodity : "--"}
                      </span>
                      <span
                        className="textMuted"
                        style={{
                          marginTop: 10,
                          color: toggleDarkMode ? "white" : "",
                        }}
                      >
                        Grade {product ? product.grade : "--"}
                      </span>{" "}
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span style={{ color: "green" }}>
                          ₦ {product ? product?.last_price : "--"}
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          ${" "}
                          {product
                            ? ((parseInt(product.last_price)*1000) / 412).toFixed(2) ||
                            ((parseInt(product[0].last_price)*1000) / 412).toFixed(2)
                            : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{
                            marginLeft: -26,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          24hr change
                        </span>{" "}
                        <br />
                        <span className="subtitles" style={{ color: "red" }}>
                          {product?._24_hr_Change > -1
                            ? product?._24_hr_Change
                            : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={{
                            marginLeft: -20,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          24hr high
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          {" "}
                          ₦{product ? product?._24_hr_high : "--"}{" "}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={toggleDarkMode ? { color: "white" } : null}
                        >
                          24hr Low
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{ color: toggleDarkMode ? "white" : "" }}
                        >
                          ₦{product ? product?._24_hr_low : "--"}
                        </span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="trade">
                        <span
                          className="textMuted"
                          style={toggleDarkMode ? { color: "white" } : null}
                        >
                          Volume
                        </span>{" "}
                        <br />
                        <span
                          className="subtitles"
                          style={{
                            marginLeft: -11,
                            color: toggleDarkMode ? "white" : "",
                          }}
                        >
                          ₦{product ? product?.product_value : "--"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ marginBottom: "25px" }}></div>
          <div
            style={
              fullscreen
                ? {
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 10000,
                    backgroundColor: toggleDarkMode ? "black" : "white",
                  }
                : null
            }
            id="fullscren"
          >
            <Row>
              <Col lg="12" xl="12">
                <PriceChart
                  // @ts-ignore

                  data={candlestickData}
                  fullscreen={fullscreen}
                  setFullscreen={setFullscreen}
                  dataChart={dataChart}
                  product={product}
                />
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: "25px" }}></div>
          <Row>
            <Col lg="12" xl="12">
              <CustomerTradeInstruction
                product={product}
                fetchInstruction={fetchInstruction}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CustomerMiddleComponent;
