import React, { Component, useContext } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../Registration/register.css";
import { Card, CardBody, CardHeader, FormGroup, Label } from "reactstrap";
import axios from "axios";
import cookie from "js-cookie";
import { withRouter } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { ref } from "yup";
import { AppContext } from "../../../AppContext/AppContext";
// import PhoneInput from "react-phone-number-input";
// const required = (value) =>
//   value ? undefined : <h6 className="text-danger">*</h6>;

// const passwordsMustMatch = (value, allValues) =>
//   value !== allValues.password ? (
//     <h6 className="text-danger">Passwords do not match</h6>
//   ) : undefined;

const ChangePassword = withRouter(({ history }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <Formik
      initialValues={{
        OTP: "",
        newpassword: "",
        confirmnewpassword: "",
      }}
      // @ts-ignore
      onSubmit={(values, { setSubmitting }) => {
        const token = cookie.get("token");
        setTimeout(() => {
          axios
            .post(
              `https://server.gcmx.bluetag.tech/api/UserManager/ResetPassword?OTPCode=${values.OTP}&NewPassword=${values.newpassword}`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res) => {
              //console.log(res);
              //console.log("Success");
              history.push("/auth/login");
            })
            .catch((err) => {
              //console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        OTP: Yup.string().required("Required"),
        newpassword: Yup.string().required("Required"),
        confirmnewpassword: Yup.string()
          .oneOf([ref("newpassword")], "Passwords do not match")
          .required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Col lg="7" md="12">
            <Card style={toggleDarkMode ? { backgroundColor: "black" } : null}>
              <div className="text-center text-muted mb-4">
                <h5
                  style={{
                    marginTop: "40px",
                    fontStyle: "normal",
                    fontFamily: "Poppins",
                    color: toggleDarkMode ? "white" : "",
                  }}
                >
                  Change Password
                </h5>
              </div>
              <CardBody className="px-lg-5 py-lg-5">
                <Col md={14}>
                  <div className="step2Card">
                    <form onSubmit={handleSubmit} className="form-group">
                      <div>
                        <FormGroup>
                          <Label for="OTP">
                            <span
                              style={{ color: toggleDarkMode ? "white" : null }}
                            >
                              OTP
                            </span>
                          </Label>
                          <input
                            type="text"
                            name="OTP"
                            id="OTP"
                            className="forgot-password-input"
                            onChange={handleChange}
                            style={
                              toggleDarkMode
                                ? { backgroundColor: "#495057", color: "white" }
                                : null
                            }
                          />
                          {errors.OTP && touched.OTP && (
                            <div className="input-feedback float-left">
                              {errors.OTP}
                            </div>
                          )}
                        </FormGroup>
                      </div>
                      <div style={{ fontFamily: "Poppins" }}>
                        <Label
                          htmlFor="newpassword"
                          className="confirm-password"
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            New Password
                          </span>
                        </Label>
                        <input
                          type="password"
                          name="newpassword"
                          id="newpassword"
                          className="forgot-password-input"
                          onChange={handleChange}
                          style={
                            toggleDarkMode
                              ? { backgroundColor: "#495057", color: "white" }
                              : null
                          }
                        />
                        {errors.newpassword && touched.newpassword && (
                          <div className="input-feedback float-left">
                            {errors.newpassword}
                          </div>
                        )}
                      </div>
                      <div style={{ fontFamily: "Poppins" }}>
                        <Label
                          className="confirm-password"
                          style={{ marginTop: "10px" }}
                        >
                          <span
                            style={{ color: toggleDarkMode ? "white" : null }}
                          >
                            Confirm New Password
                          </span>
                        </Label>
                        <input
                          type="password"
                          name="confirmnewpassword"
                          id="confirmnewpassword"
                          className="forgot-password-input"
                          onChange={handleChange}
                          style={
                            toggleDarkMode
                              ? { backgroundColor: "#495057", color: "white" }
                              : null
                          }
                          required
                        />
                        {errors.confirmnewpassword &&
                          touched.confirmnewpassword && (
                            <div className="input-feedback float-left">
                              {errors.confirmnewpassword}
                            </div>
                          )}
                      </div>

                      <button
                        className="nextBtn"
                        type="submit"
                        style={{ width: "95%" }}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        );
      }}
    </Formik>
  );
});

export default ChangePassword;
