import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Card, Row, Col } from "reactstrap";
import "./kyc.css";

const BankAccountDetails = ({ data }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Card
        id="bank-account"
        style={
          toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
        }
      >
        <span className="cardHeader">
          <Row>
            <Col>
              <span style={toggleDarkMode ? { color: "white" } : null}>
                Bank Account Details
              </span>
            </Col>
          </Row>
        </span>
        <div className={toggleDarkMode ? "card-content dark" : "card-content"}>
          <Row className="mb-3">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Account Type
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.account_type} /*style={{ width:"57%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Account No.
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.account_number} /*style={{ width:"62%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                BVN
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.bvn} /*style={{ width:"82%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Account Name
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.account_name} /*style={{ width:"70%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col">
              <span
                style={{
                  fontSize: "14px",
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Bank Name
              </span>
              <input
                type="text"
                disabled
                className="textbox-custom form-control"
                value={data.bank} /*style={{ width:"76%" }}*/
                style={
                  toggleDarkMode
                    ? {
                        backgroundColor: "#495057",
                        color: "white",
                      }
                    : null
                }
              />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default BankAccountDetails;
