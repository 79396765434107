/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  Row,
  Container,
  Col,
  NavLink,
} from "reactstrap";
import "./overview.css";
import SpotTable from "../spotmarket/SpotTable";
import classnames from "classnames";
import { AppContext } from "../../AppContext/AppContext";

const CustomerDetails = (props) => {
  const customer = props.location?.customer || {};
  //console.log(customer);
  const { getCustomersWalletDetails } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("1");
  const [items, setItems] = useState([]);
  useEffect(() => {
    getCustomersWalletDetails(customer.customer_id).then((res) => {
      //console.log(res);
      setItems(res.data);
    });
  }, []);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const state = {
    consolidated: [
      {
        name: "Oyedikachi Ugwu",
        receipt: "GEZAWA/CUS/0257",
        spot: "Spot Market",
        future: "Spot Market",
        amountNaira: "100,250,000,000",
        amountDollar: "2,481,070,441.50",
      },
      {
        name: "Offiah Chidi",
        receipt: "GEZAWA/CUS/0251",
        spot: "Spot Market",
        future: "Spot Market",
        amountNaira: "250,250,000,000",
        amountDollar: "2,481,070,441.50",
      },
      {
        name: "Nweri Chukuma",
        receipt: "GEZAWA/CUS/0257",
        spot: "Spot Market",
        future: "Spot Market",
        amountNaira: "150,250,000,000",
        amountDollar: "2,481,070,441.50",
      },
    ],
  };
  const viewDetails = (data) => {
    //console.log(data);
    props.history.push({
      pathname: "/admin/checkout",
      amount: data,
    });
  };

  const { consolidated } = state;
  return (
    <>
      <div className="header bg-light pb-8" style={{ fontFamily: "Poppins" }}>
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="12" xl="12">
                <div className="spotMainTitle">
                  <p className="page-title">Spot Market</p>
                  <div className="exportPlusIcon">
                    <i
                      className={classnames("fa fa-download", "exportIcon")}
                      aria-hidden="true"
                    ></i>
                    <span className="exportText">Export spot market</span>
                  </div>
                </div>
                <Card className="mb-2 mb-xl-0">
                  <CardBody>
                    <Row className="mb-2">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Spot Market
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Futures Market
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Row>
                    <Row>
                      <Col md="6">
                        <span className="spotSubtitle">Commodity Value</span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <span className="spotValue">
                            <img
                              className="spotNaira"
                              src="https://www.flaticon.com/svg/static/icons/svg/32/32974.svg"
                              alt="naira"
                              width="18px"
                            />
                            ${customer.amountNaira}
                          </span>
                          <span className="spotLine">|</span>
                          <span className="spotValue2">
                            ${customer.amountDollar}
                          </span>
                        </div>
                      </Col>
                      <Col md="6">
                        <span className="spotSubtitle">Customer Balance</span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <span className="spotValue">
                            <img
                              className="spotNaira"
                              src="https://www.flaticon.com/svg/static/icons/svg/32/32974.svg"
                              alt="naira"
                              width="18px"
                            />
                            ${customer.amountNaira}
                          </span>
                          <span className="spotLine">|</span>
                          <span className="spotValue2">
                            ${customer.amountDollar}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div style={{ marginBottom: "25px" }}></div>
            <Row>
              <Col lg="12" xl="12">
                <Card className="mb-2 mb-xl-0">
                  <CardBody>
                    {false ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <SpotTable products={items} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* <Container fluid className="bg-light pb-5">
          <Row>
              <h3 className="page-title ml-3">{customer.name}</h3>
              <p className="page-title export ml-auto mr-4"><i class="fa fa-download mr-1" />Export</p>
            <div className="col-12 mb-5 mb-xl-0">
              <Card className=" overview-card">
              <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Spot Market
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Futures Market
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
        <Row className="pt-3 ml-3">
                      <Col md="6">
                        <span className="spotSubtitle">Commodity Value</span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <span >
                            <img
                              className="spotNaira"
                              src="https://www.flaticon.com/svg/static/icons/svg/32/32974.svg"
                              alt="naira"
                              width="15px"
                            />
                            {customer.amountNaira}
                          </span>
                          <span className="spotLine">|</span>
                          <span>${customer.amountDollar}</span>
                        </div>
                      </Col>
                      <Col md="6">
                        <span className="spotSubtitle">Customer Balance</span>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <small className="">
                            <img
                              className=""
                              src="https://www.flaticon.com/svg/static/icons/svg/32/32974.svg"
                              alt="naira"
                              width="15px"
                            /> 
                            {customer.amountNaira}
                          </small>
                          <span className="spotLine">
                            |
                          </span>
                          <span>
                            ${customer.amountDollar}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col className="pt-3" sm="6">
                <p>Futures Market</p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    
                   
              </Card>
            </div>
          
            <div className="col-12 mt-4 mb-5">
                <div className="overview-card overflow ">
                 
                   <SpotTable /> 
 
                </div>
            </div>
          </Row>
         
        </Container> */}
    </>
  );
};

export default CustomerDetails;
