import { AppContext } from "../../AppContext/AppContext";
import React, { useContext } from "react";
import { Table } from "reactstrap";
import "./transaction.css";

export default function TransactionHistoryTable({
  data,
  transactionType,
  tableHeader,
}) {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Date</th>
            <th>Customer Name</th>
            <th>Customer ID</th>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Service Fee</th>
            <th>
              {tableHeader
                ? transactionType === "All" || ""
                  ? "Payment Channel"
                  : transactionType === "Deposit"
                  ? "Mode of Deposit"
                  : "Mode of Payment"
                : "Payment Channel"}
            </th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {data.map((d) => (
            <tr style={{ fontFamily: "Poppins" }}>
              <td>{d.date}</td>
              <td>{d.customer_name}</td>
              <td>{d.customer_id}</td>
              <td>{d.transaction_id}</td>
              <td>{d.amount}</td>
              <td>{d.service_fee}</td>
              <td>{d.mode_of_payment}</td>
              <td>{d.status}</td>
              <td>
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "#24B086",
                  }}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
