import React from "react";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";

export default function TradeHistoryTable({ data }) {
  return (
    <>
      <Table responsive>
        <thead
          className="bg-light text-transform-lowercase"
          style={{ fontFamily: "Poppins" }}
        >
          <tr>
            <th>Date</th>
            <th>Instruction ID</th>
            <th>Commodities (kg)</th>
            <th>UoM</th>
            <th>Grade</th>
            <th>Market</th>
            <th>Trade side</th>
            <th>Quantity</th>
            <th>Price (NGN)</th>
            <th>Executed</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr style={{ fontFamily: "Poppins" }}>
              <td>{d.date}</td>
              <td>{d.instructionID}</td>
              <td>{d.commodities}</td>
              <td>{d.uom}</td>
              <td>{d.grade}</td>
              <td>{d.market}</td>
              <td
                className={
                  d.tradeSide == "Sell" ? "text-danger" : "text-success"
                }
              >
                {d.tradeSide}
              </td>
              <td>{d.qty}</td>
              <td>₦{d.price}</td>
              <td>₦{d.executed}</td>
              <td>₦{d.amount}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="trade-dropdown"
                    style={{ color: "#59588D" }}
                  >
                    {d.status}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropDownMenu">
                    <Dropdown.Item href="#/action-1">30m</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">45m</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
