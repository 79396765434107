var tradeHistory = [
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Pending",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "A",
    market: "Futures market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "A",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "A",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "A",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "A",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "B",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "B",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "B",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Soya Beans",
    uom: "kg",
    grade: "B",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "B",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Sesame Seeds",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Spot market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "09-08-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Sell",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "10-09-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "11-11-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Maize",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
  {
    date: "12-06-2020",
    instructionID: "GEZAWA/CUS/00257/INS/SELL/001",
    commodities: "Rice",
    uom: "kg",
    grade: "C",
    market: "Futures market",
    tradeSide: "Buy",
    qty: "+2.34",
    price: "240",
    executed: "130",
    amount: "23,000",
    status: "Completed",
  },
];

export default tradeHistory;
