import React from "react";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="d-flex justify-content-center align-item-center h-100">
                <h1>Contact is coming Soon</h1>
            </div>
         );
    }
}
 
export default Contact;