import routes from "../routes.js";

export const getPath = (routeName) => {
  var route = routes.filter((rn) => rn.routeName === routeName);
  var path = route[0].layout + route[0].path;
  return path;
};

export const imagePath = "https://gezawa.poc.bluetag.tech";

export const commodityIcons = [
  { path: "r1.ff70af29", id: "R1" },
  { path: "sb.9f6ab1d1", id: "SB" },
  { path: "ss.dd433e5d", id: "SS" },
  { path: "ym.4a0d2e3f", id: "YM" },
  { path: "ym.4a0d2e3f", id: "YM-WYM" },
  { path: "ym.4a0d2e3f", id: "YM-SYM" },
  { path: "r1-paddy.4b4184c9", id: "R1-PADDY" },
  { path: "millet.5284481d", id: "Millet" },
  { path: "beans.28028f35", id: "Beans" },
  { path: "wheat.3cd1fd29", id: "Wheat" },
  { path: "corn.7191d039", id: "Corn" },
  { path: "sorghum.6e49374f", id: "Sorghum" },
];
