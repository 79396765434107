import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
// reactstrap components

import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  Row,
  Container,
  Col,
  Label,
} from "reactstrap";
import "./overview.css";
import Visa from "../../assets/img/icons/common/visa.svg";
import Master from "../../assets/img/icons/common/mastercard.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { whatRoleIsThis } from "../../services/auth.service";
const Bank3D = window.Bank3D;
function rand(length, current) {
  current = current ? current : "";
  return length
    ? rand(
        --length,
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".charAt(
          Math.floor(Math.random() * 60)
        ) + current
      )
    : current;
}
const Checkout = (props) => {
  const amount = props.location.state.amount;
  //console.log(amount);
  //console.log(rand(10));
  return (
    <>
      <Container className="bg-light" fluid>
        <nav aria-Label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link
                to={
                  whatRoleIsThis() === "Customer"
                    ? "/admin/overview"
                    : "/admin/broker-wallet"
                }
              >
                Overview
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/deposit">Deposit</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Checkout
            </li>
          </ol>
        </nav>
        <Row className="justify-content-center">
          <div className="col-7 text-center mb-5 mb-xl-0">
            <p className="checkout-title text-center">Checkout</p>
            <Card className=" deposit-card pb-5 text-center">
              <p className="pt-3 text-amount text-center">
                Amount to be deposited:{" "}
                <strong className="amount-green">NGN {amount}</strong>
              </p>
              <p className="card-text-amount">Enter your card details to pay</p>
              <Formik
                initialValues={{
                  holderName: "",
                  cardNumber: "",
                  exp: "",
                  cvv: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  //console.log(values);
                  setTimeout(() => {
                    //console.log(values);

                    setSubmitting(false);
                  }, 500);
                }}
                validationSchema={Yup.object().shape({
                  holderName: Yup.string().required(
                    "Card holder name is required"
                  ),
                  cardNumber: Yup.string().required("Card Number provided."),
                  exp: Yup.string().required("Enter card expiration date."),
                  cvv: Yup.string().required("Enter card cvv"),
                  //   .min(6, "Password is too short - should be 6 chars minimum"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    setFieldValue,
                    setErrors,
                  } = props;

                  return (
                    <Form
                      onSubmit={handleSubmit}
                      className="mt-3 px-3 text-justify"
                    >
                      <div id="bank3DEmbeddedContainer"> </div>
                      <FormGroup>
                        <Label for="cardholdername">Cardholder Name</Label>
                        <Input
                          type="text"
                          value={values.holderName}
                          name="holderName"
                          id="holderName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.holderName && touched.holderName && "error"
                          }
                        />
                        {errors.holderName && touched.holderName && (
                          <div className="input-feedback float-left">
                            {errors.holderName}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="cardnumber">Card Number</Label>
                        <InputMask
                          type="text"
                          value={values.cardNumber}
                          name="cardNumber"
                          id="cardNumber"
                          mask="9999  9999  9999  9999"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="53** **** **** **** "
                          className={
                            errors.cardNumber && touched.cardNumber
                              ? "error form-control"
                              : "form-control"
                          }
                        />
                        {errors.cardNumber && touched.cardNumber && (
                          <div className="input-feedback float-left">
                            {errors.cardNumber}
                          </div>
                        )}
                      </FormGroup>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="expiryDate">Expiry Date</Label>
                            <Input
                              type="month"
                              value={values.exp}
                              name="exp"
                              id="exp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder=""
                              className={errors.exp && touched.exp && "error"}
                            />
                            {errors.exp && touched.exp && (
                              <div className="input-feedback float-left">
                                {errors.exp}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="cvv">CVV</Label>
                            <Input
                              type="text"
                              vale={values.cvv}
                              name="cvv"
                              id="cvv"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="CVV"
                              className={errors.cvv && touched.cvv && "error"}
                            />
                            {errors.cvv && touched.cvv && (
                              <div className="input-feedback float-left">
                                {errors.cvv}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form className="mt-2">
                        <Col md={6}>
                          <Link
                            to="/admin/deposit"
                            className="pt-2 btn btn-block  btn-back"
                          >
                            Go Back
                          </Link>
                        </Col>
                        <Col md={6}>
                          <Button className="btn btn-block  btn-checkout">
                            Deposit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
            <p className="mt-3 mb-5">
              <i className="fas fa-lock"></i> &nbsp; Secure payment
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Checkout;
