import React, { useContext } from "react";
import { Modal } from "reactstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import WithdrawalExportForm from "./WithdrawalExportForm";
import "./transaction.css";
import { AppContext } from "../../AppContext/AppContext";

const WithdrawalModal = ({ toggle, withdrawalExport }) => {
  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={withdrawalExport}
        toggle={() => toggle("withdrawalExport")}
      >
        <div
          className="modal-body"
          style={
            toggleDarkMode ? { backgroundColor: "black", color: "white" } : null
          }
        >
          <div className="row">
            <div className="col" style={{ textAlign: "center" }}>
              <h6
                className="modal-title"
                id="modal-title-default"
                style={{ color: toggleDarkMode ? "white" : "" }}
              >
                Export Withdrawal History
              </h6>
            </div>
            <div className="col-1">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggle("withdrawalExport")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </div>
          <WithdrawalExportForm />
        </div>
      </Modal>
    </>
  );
};

export default WithdrawalModal;
