import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import BankAccountDetailsModals from "./BankAccountDetailsModals";
import { AppContext } from "../../AppContext/AppContext";

class BankAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.state = {
      modalview4: false,
      modalview5: false,
      modalview6: false,
      message: "",
      showAlert: false,
    };
  }

  handleMessage = (response) => {
    //console.log("Message set");
    this.setState({
      message: response,
    });
  };

  setShowAlert = () => {
    //console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  resetModal = (state) => {
    this.setState({
      [state]: false,
    });
  };

  static contextType = AppContext;

  render() {
    const { toggleDarkMode, bankDetailsForm } = this.context;
    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message}
          </div>
        )}
        <Card className={toggleDarkMode ? "dark" : ""} id="bank-account">
          <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
            <Row>
              <Col>Bank Account Details</Col>
              <Col className="col-1">
                <button
                  onClick={() => {
                    this.props.toggleModal("BankAccountDetails");
                    this.toggleModal("modalview4");
                  }}
                  className={toggleDarkMode ? "dark" : ""}
                >
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <Row className="mb-4">
              <Col className="col">
                <span style={{ fontSize: "14px" }}>Account Type</span>
                <input
                  type="text"
                  disabled
                  className="textbox-custom form-control"
                  value={
                    this.props.bank.account_type || this.props.data.account_type
                  } /*style={{ width:"57%" }}*/
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
              <Col className="col">
                <span style={{ fontSize: "14px" }}>Account No.</span>
                <input
                  type="text"
                  disabled
                  className="textbox-custom form-control"
                  value={
                    this.props.bank.account_number ||
                    this.props.data.account_number
                  } /*style={{ width:"62%" }}*/
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
              <Col className="col">
                <span style={{ fontSize: "14px" }}>BVN</span>
                <input
                  type="text"
                  disabled
                  className="textbox-custom form-control"
                  value={
                    this.props.bank.bvn || this.props.data.bvn
                  } /*style={{ width:"82%" }}*/
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <span style={{ fontSize: "14px" }}>Account Name</span>
                <input
                  type="text"
                  disabled
                  className="textbox-custom form-control"
                  value={
                    this.props.bank.account_name || this.props.data.account_name
                  } /*style={{ width:"70%" }}*/
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
              <Col className="col-6">
                <span style={{ fontSize: "14px" }}>Bank Name</span>
                <input
                  type="text"
                  disabled
                  className="textbox-custom form-control"
                  value={
                    this.props.bank.bank || this.props.data.bank
                  } /*style={{ width:"76%" }}*/
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
        </Card>
        <BankAccountDetailsModals
          bankAccountDetails={this.props.bankAccountDetails}
          modalview4={this.state.modalview4}
          modalview5={this.state.modalview5}
          modalview6={this.state.modalview6}
          toggleModal={this.props.toggleModal}
          toggleModal2={this.toggleModal}
          resetModal={this.props.resetModal}
          resetModal2={this.resetModal}
          data={this.props.data}
          bank={this.props.bank}
          setShowAlert={this.setShowAlert}
          handleMessage={this.handleMessage}
          refreshUserProfile={this.props.refreshUserProfile}
          refreshBankDetails={this.props.refreshBankDetails}
        />
      </>
    );
  }
}

export default BankAccountDetails;
