/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Button, Dropdown } from "react-bootstrap";
// core components
import "./Index.css";
import Header from "../../components/Headers/Header.js";
import "../../../node_modules/rc-tabs/assets/index.css";
import "../css/tabs.css";
import spotMarket from "../../dummyData/spotMarket.js";
import SpotMarketTable from "./MarketQuery";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      chartExample2Data: "data2",
      spotMarketData: [],
    };
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };

  populate() {
    this.setState({
      spotMarketData: spotMarket,
    });
  }

  async getChartData() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/MarketChartForAllCommodityGrade",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ chartExample1Data: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  async fetchSpotMarketInfo() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/Market",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        //console.log(response.data);
        this.setState({ spotMarketData: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
    //this.getChartData();
  }

  static contextType = AppContext;

  componentDidMount() {
    this.fetchSpotMarketInfo();
  }

  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container
          className={toggleDarkMode ? "mt--7 bg-dark" : "mt--7 bg-light"}
          style={{
            width: "100%",
            backgroundColor: toggleDarkMode ? "#495057" : "",
          }}
        >
          <Col className="mb-5" xl="12" style={{ marginTop: "40px" }}>
            <Card className="mb-10">
              <CardHeader
                style={
                  toggleDarkMode
                    ? { color: "white", backgroundColor: "#495057" }
                    : null
                }
              >
                <b>MARKETS</b>
              </CardHeader>
              <CardBody
                style={
                  toggleDarkMode
                    ? { color: "white", backgroundColor: "black" }
                    : null
                }
              >
                <SpotMarketTable spotMarketData={this.state.spotMarketData} />
              </CardBody>
            </Card>
          </Col>

          {/* <AdminFooter /> */}
        </Container>
      </>
    );
  }
}

export default Index;
