import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import axios from "axios";
import { getToken } from "../../services/auth.service";
import { AppContext } from "../../AppContext/AppContext";

const LeftComponent = ({ product }) => {
  const [buyOrder, setBuyOrder] = useState([]);
  const [sellOrder, setSellOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = getToken();
  //console.log(product);
  const { commodity_id, grade } = product;
  //console.log(commodity_id, grade);

  useEffect(() => {
    fetchBuyOrderBook();
  }, []);
  useEffect(() => {
    fetchSellOrderBook();
  }, []);

  const fetchBuyOrderBook = () => {
    setLoading(true);
    axios
      .get(`https://server.gcmx.bluetag.tech/api/Transaction/BuyOrderBook`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { CommodityId: commodity_id, Grade: grade },
      })
      .then((response) => {
        //console.log(response.data);
        setBuyOrder(response.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error.response);
        setLoading(false);
      });
  };
  const fetchSellOrderBook = () => {
    axios
      .get(`https://server.gcmx.bluetag.tech/api/Transaction/SellOrderBook`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { CommodityId: commodity_id, Grade: grade },
      })
      .then((response) => {
        //console.log(response);
        setSellOrder(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const { toggleDarkMode } = useContext(AppContext);
  return (
    <div>
      <Card
        className="mb-2 mb-xl-0"
        style={{
          height: "955px",
          backgroundColor: toggleDarkMode ? "black" : "",
        }}
      >
        <CardBody>
          <Row>
            <Col>
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: toggleDarkMode ? "white" : "",
                }}
              >
                Order Book
              </h3>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginTop: 20,
                      color: toggleDarkMode ? "white" : "",
                    }}
                  >
                    Selling
                  </h3>

                  <hr />

                  <Table
                    className="table table-borderless table-sm"
                    style={{ marginLeft: -5, width: "auto" }}
                  >
                    <thead
                      style={{
                        background: "none",
                        color: toggleDarkMode ? "white" : null,
                        backgroundColor: toggleDarkMode ? "#495057" : null,
                      }}
                    >
                      <tr>
                        <th scope="col">Price (NGN)</th>
                        <th scope="col">Qty (Tons) </th>
                        <th scope="col">Total (NGN)</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        color: toggleDarkMode ? "white" : null,
                        backgroundColor: toggleDarkMode ? "black" : null,
                      }}
                    >
                      {sellOrder
                        .sort(function (a, b) {
                          return b - a;
                        })
                        .map((sell, index) => (
                          <tr key={index}>
                            <td style={{ color: "#FD665E" }}>{sell.price}</td>
                            <td>{sell.quantity}</td>
                            <td>{sell.total}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>

                  <h3
                    style={{
                      marginTop: 20,
                      fontSize: "14px",
                      fontWeight: 600,
                      color: toggleDarkMode ? "white" : "",
                    }}
                  >
                    Buying
                  </h3>

                  <hr />

                  <Table
                    className="table table-borderless table-sm "
                    style={{ marginLeft: -5 }}
                    width="10%"
                  >
                    <thead
                      style={{
                        background: "none",
                        color: toggleDarkMode ? "white" : null,
                        backgroundColor: toggleDarkMode ? "#495057" : null,
                      }}
                    >
                      <tr>
                        <th scope="col">Price (NGN)</th>
                        <th scope="col">Qty (Tons) </th>
                        <th scope="col">Total (NGN)</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        color: toggleDarkMode ? "white" : null,
                        backgroundColor: toggleDarkMode ? "black" : null,
                      }}
                    >
                      {buyOrder
                        .sort(function (a, b) {
                          return a - b;
                        })
                        .map((buy, index) => (
                          <tr key={index}>
                            <td style={{ color: "green" }}>{buy.price}</td>
                            <td>{buy.quantity}</td>
                            <td>{buy.total}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LeftComponent;
