import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Modal } from "reactstrap";

import "../css/MyAccount.css";

import BankAccountDetailsForm from "./BankAccountDetailsForm";
import OTPForm from "./OTPForm";
import { AppContext } from "../../AppContext/AppContext";

class BankAccountDetailsModals extends Component {
  componentWillUnmount() {
    this.props.resetModal("BankAccountDetails");
    this.props.resetModal2("modalview4");
    this.props.resetModal2("modalview5");
    this.props.resetModal2("modalview6");
  }

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;
    let item;

    if (this.props.modalview5) {
      item = (
        <div className="modal-view-2">
          <div
            className="modal-body text-center"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row justify-content-right">
              <div className="col">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("BankAccountDetails")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <i
              style={{
                fontSize: "30px",
                textAlign: "center",
                color: "#24b086",
              }}
              className="fas fa-mobile"
            ></i>
            <h3 style={{ color: toggleDarkMode ? "white" : "" }}>
              Verification Code
            </h3>
            <p style={{ color: toggleDarkMode ? "white" : "" }}>
              Verification code has been sent to your phone number
            </p>
            <h5 style={{ color: toggleDarkMode ? "white" : "" }}>
              Please enter the code below
            </h5>
            <OTPForm modalProps={this.props} />
          </div>
        </div>
      );
    } else if (this.props.modalview6) {
      item = (
        <div className="modal-view-3">
          <div
            className="modal-body"
            style={
              toggleDarkMode
                ? { backgroundColor: "black", color: "white" }
                : null
            }
          >
            <div className="row justify-content-right">
              <div className="col">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("BankAccountDetails")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <div className="success-message">
              <i className="far fa-check-circle"></i>

              <h1>Success</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086", marginBottom: "45px" }}
                  onClick={() => {
                    this.props.toggleModal("BankAccountDetails");
                    this.props.toggleModal2("modalview6");
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.bankAccountDetails}
          toggle={() => this.props.toggleModal("BankAccountDetails")}
          size="lg"
        >
          {this.props.modalview4 ? (
            <div className="modal-view-1">
              <div
                className="modal-body"
                style={
                  toggleDarkMode
                    ? { backgroundColor: "black", color: "white" }
                    : null
                }
              >
                <div className="row">
                  <div className="col" style={{ textAlign: "center" }}>
                    <h6
                      className="modal-title"
                      id="modal-title-default"
                      style={{ color: toggleDarkMode ? "white" : "" }}
                    >
                      Bank Account Details
                    </h6>
                  </div>
                  <div className="col-1">
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() =>
                        this.props.toggleModal("BankAccountDetails")
                      }
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                </div>
                <BankAccountDetailsForm modalProps={this.props} />
              </div>
            </div>
          ) : (
            item
          )}
        </Modal>
      </>
    );
  }
}

export default BankAccountDetailsModals;
