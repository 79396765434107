import React, { useContext } from 'react';
import { Form, Formik } from "formik";
import axios from "axios";
import cookie from "js-cookie";
import * as Yup from "yup";
import { Row } from 'reactstrap';
import { AppContext } from '../../AppContext/AppContext';

function NewsletterForm(props) {
    const { toggleDarkMode } = useContext(AppContext);

    const subscribe = (email) => {
        const token = cookie.get("token");
        axios
            .post(
                `https://server.gcmx.bluetag.tech/api/UserManager/NewsletterSubscription?Email=${email}`,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
            .then((res) => {
                console.log(res);
                console.log("Success");
                props.setSubscription();
                props.setShowAlert();
                setTimeout(() => {
                    props.setShowAlert();
                }, 5000);
            })
            .catch((err) => {
                console.log(err.response?.data);
            });
    }
    return (
        <Formik
            initialValues={{
                email: ''
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    subscribe(values.email);
                    setSubmitting(false);
                }, 1500);
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email("E-mail is not valid!").required("Required!"),
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    setFieldValue,
                    setErrors,
                } = props;

                return (
                    <Form className='email-form'>
                        <Row>
                            <div className="col">
                                <input
                                    type="text"
                                    placeholder="Enter email address"
                                    className="form-control textbox-custom"
                                    value={values.email}
                                    onChange={handleChange}
                                    name="email"
                                    style={
                                        toggleDarkMode
                                            ? {
                                                backgroundColor: "#495057",
                                                color: "white",
                                            }
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-3">
                                <button type="submit" className="btn button-default">
                                    <span style={{ fontSize: "14px" }}>SUBSCRIBE</span>
                                </button>
                            </div>
                        </Row>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default NewsletterForm;