import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import "./Spots.css";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";
import MiddleComponent from "./MiddleComponent";
import CustomerMiddleComponent from "./CustomerMiddleComponent";
import CustomerTableBelow from "./CustomerTableBelow";
// import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { useLocation } from "react-router-dom";
import { getToken, whatRoleIsThis } from "../../services/auth.service";
import axios from "axios";
import { BASEURL } from "./baseUrl";
import TableBelow from "./TableBelow";
import { AppContext } from "../../AppContext/AppContext";

const OrderBook = ({ location }) => {
  const [instruction, setInstruction] = useState([]);
  const [openOrder, setOpenOrder] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [spotMarket, setSpotMarket] = useState([]);
  const [marketTrade, setMarketTrade] = useState([]);
  const [loading, setLoading] = useState(false);
  const { toggleDarkMode } = useContext(AppContext);
  const token = getToken();
  const role = whatRoleIsThis();
  const { row } = location.state;
  const { context } = location.state;
  //console.log("location", location);

  //console.log("hi2", row);

  useEffect(() => {
    fetchInstruction();
  }, []);
  useEffect(() => {
    fetchOpenOrder();
  }, []);
  useEffect(() => {
    fetchOrderHistory();
  }, []);
  useEffect(() => {
    fetchSportMarketData();
  }, []);
  useEffect(() => {
    fetchMarketTradesData();
  }, []);

  const fetchInstruction = () => {
    setLoading(true);
    axios
      .get(`${BASEURL}/InstructionHistory`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res.data);
        setInstruction(res.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
  };

  const fetchOpenOrder = () => {
    axios
      .get(`${BASEURL}/OpenOrderList`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res.data);
        setOpenOrder(res.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const fetchOrderHistory = () => {
    axios
      .get(`${BASEURL}/OrderHistory`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res.data);
        setOrderHistory(res.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const fetchSportMarketData = () => {
    setLoading(true);
    axios
      .get(`${BASEURL}/SpotMarket`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        //console.log(result.data);
        setSpotMarket(result.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
  };
  const fetchMarketTradesData = () => {
    axios
      .get(
        `${BASEURL}/MarketTrade?CommodityID=${row.commodity_id}&Grade=${row.grade}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        //console.log(result);
        setMarketTrade(result.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const PendingOpenOrder = openOrder.filter((openOrd) => {
    return openOrd.status.toLowerCase() === "pending";
  });

  return (
    <>
      {/* <AdminNavbar /> */}
      <div
        className={
          toggleDarkMode ? "header bg-dark pb-8" : "header bg-light pb-8"
        }
        style={{ fontFamily: "Poppins" }}
      >
        <div className="header-body">
          {/* Card stats */}
          <Row className="mb-3" style={{ marginLeft: 0, marginRight: 0 }}>
            <Col sm="3">
              <LeftComponent product={row} />
            </Col>
            <Col sm="6">
              {role === "Broker" ? (
                <MiddleComponent product={row} context={context} />
              ) : (
                <CustomerMiddleComponent
                  product={row}
                  fetchInstruction={fetchInstruction}
                />
              )}
            </Col>
            <Col sm="3">
              <RightComponent
                product={spotMarket}
                loading={loading}
                productMarket={marketTrade}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col sm="12">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : role !== "Broker" ? (
                <CustomerTableBelow
                  instruction={instruction}
                  openOrder={PendingOpenOrder}
                  orderHistory={orderHistory}
                />
              ) : (
                <TableBelow
                  openOrder={PendingOpenOrder}
                  orderHistory={orderHistory}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OrderBook;
