import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import LinkToBrokerModal from "./LinkToBrokerModal";

import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class LinkToBroker extends Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
  }

  state = {
    data: [],
    message: "",
    showAlert: false,
  };

  handleMessage = (response) => {
    //console.log("Message set");
    this.setState({
      message: response,
    });
  };

  setShowAlert = () => {
    //console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  async componentDidMount() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/ApprovedBrokerList",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        // console.log('selected broker: ' + response.data);
        this.setState({ data: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message === "Broker hasn't been approved"
              ? "Pending Approval"
              : this.state.message}
          </div>
        )}
        <Card className={toggleDarkMode ? "dark" : ""} id="link-to-broker">
          <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
            <Row>
              <Col>Link To Broker</Col>
              <Col className="col-2">
                <button
                  onClick={() => this.props.toggleModal("LinkToBroker")}
                  className={toggleDarkMode ? "dark" : ""}
                >
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <p style={{ fontSize: "14px" }}>
              Broker's Name: {this.props.data.linked_broker_name}
            </p>
            <p style={{ fontSize: "14px" }}>
              Broker's ID: {this.props.data.linked_broker_id}
            </p>
            <p style={{ fontSize: "14px" }}>
              Accreditation category: Spot & Futures
            </p>
            <p style={{ fontSize: "14px" }}>
              <input
                type="checkbox"
                disabled
                value={this.props.data.can_order}
                checked={this.props.data.can_order}
              />{" "}
              Allow Broker to place buy/sell order on my behalf.
            </p>
            <p style={{ fontSize: "14px" }}>
              <input
                type="checkbox"
                disabled
                value={this.props.data.can_withdraw}
                checked={this.props.data.can_withdraw}
              />{" "}
              Allow Broker to place withdrawal on my behalf to my registered
              bank account
            </p>
            <p style={{ fontSize: "14px" }}>
              <input
                type="checkbox"
                disabled
                value={
                  this.props.data.can_withdraw || this.props.data.can_order
                }
                checked={
                  this.props.data.can_withdraw || this.props.data.can_order
                }
              />{" "}
              I have read and agreed with the GCMX Terms and Conditions for
              Broker-Customer relationship.
            </p>
            <br />
            <div>
              <button
                onClick={() => {
                  this.props.toggleModal("LinkToBroker");
                }}
                style={{ backgroundColor: "transparent" }}
              >
                <span
                  style={{
                    // @ts-ignore
                    fontWeight: "600",
                  }}
                >
                  {this.props.data.linked_broker_name === ""
                    ? "SELECT BROKER"
                    : "CHANGE BROKER"}
                </span>
              </button>
            </div>
          </div>
        </Card>
        <LinkToBrokerModal
          userProfile={this.props.data}
          LinkToBroker={this.props.linkToBroker}
          ChangeBroker={this.props.changeBrokerModal}
          toggleModal={this.props.toggleModal}
          resetModal={this.props.resetModal}
          data={this.state.data}
          setShowAlert={this.setShowAlert}
          handleMessage={this.handleMessage}
          fetchUserProfile={this.props.fetchUserProfile}
        />
      </>
    );
  }
}

export default LinkToBroker;
