import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "reactstrap";
import Timer from "react-compound-timer/build";
import ReactCodeInput from "react-verification-code-input";
import { AppContext } from "../../AppContext/AppContext";

const DeliveryOTPForm = ({ success, setSuccess, OTPForm, setOTPForm }) => {
  const { fetchOTP, verifyOTP, toggleDarkMode } = useContext(AppContext);
  const [otpError, setOtpError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [Alert, setAlert] = useState("");

  return (
    <Formik
      initialValues={{
        OTPCode: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          verifyOTP(values.OTPCode)
            .then((res) => {
              console.log(res.data);
              //console.log("Success");
              setOTPForm(!OTPForm);
              setSuccess(!success);
            })
            .catch((err) => {
              //console.log(err.response?.data);
              setOtpError(err);
              setAlert("Incorrect Code");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        OTPCode: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <Timer
              initialTime={120000}
              direction="backward"
              // @ts-ignore
              style={{
                marginBottom: "15px",
                color: toggleDarkMode ? "white" : "",
              }}
            >
              {() => (
                <React.Fragment>
                  <Timer.Minutes /> : <Timer.Seconds /> till expiration
                </React.Fragment>
              )}
            </Timer>
            <br />
            <FormGroup>
              <ReactCodeInput
                className={
                  showAlert
                    ? toggleDarkMode
                      ? "v-code v-code-error dark"
                      : "v-code v-code-error"
                    : toggleDarkMode
                    ? "v-code dark"
                    : "v-code"
                }
                //   onChange={(e) => {
                //     handleChange(e);
                //     values.OTPCode = e.currentTarget.value;
                //   }}
                //title="OTPCode"
                onChange={(vals) => (values.OTPCode = vals)}
                //onComplete={(val) => //console.log("complete", val)}
              />
              {errors.OTPCode && touched.OTPCode && (
                <div className="input-feedback float-left">
                  {errors.OTPCode}
                </div>
              )}
            </FormGroup>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Next
                </button>
                <br />
                <p>
                  Did you get the code?{" "}
                  <a style={{ cursor: "pointer" }} onClick={() => fetchOTP()}>
                    Resend
                  </a>
                </p>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeliveryOTPForm;
