/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { AppContext } from "../../AppContext/AppContext";
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  static contextType = AppContext;
  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        <footer
          className={toggleDarkMode ? "auth-footer" : "mt-5 auth-footer"}
          style={
            toggleDarkMode
              ? { backgroundColor: "#495057", color: "lightgrey" }
              : null
          }
        >
          <Container>
            <div
              className={
                toggleDarkMode
                  ? "copyright text-center"
                  : "copyright text-center text-muted"
              }
              style={{
                fontFamily: "Poppins",
              }}
            >
              © 2020{" "}
              <a href="/" target="_blank">
                Gezawa.com
              </a>{" "}
              All Rights Reserved
            </div>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
