import React, { Component } from "react";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Card, Row, Col } from "reactstrap";
import cookie from "js-cookie";
import "../css/MyAccount.css";
import { AppContext } from "../../AppContext/AppContext";
import NewsletterForm from "./NewsletterForm";

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    //this.onChangeSubscription = this.onChangeSubscription.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.state = {
      subscribed: false,
      email: '',
      showAlert: false,
      message: "Subscription Successful"
    }
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  // onChangeSubscription = (e) => {
  //   const token = cookie.get("token");
  //   e.preventDefault()
  //     axios
  //       .post(
  //         `https://server.gcmx.bluetag.tech/api/UserManager/NewsletterSubscription?Email=${this.state.email}`,
  //         {},
  //         {
  //           headers: {
  //             Authorization: "Bearer " + token,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         //console.log(res);
  //         //console.log("Success");
  //         this.setState({
  //           subscribed: !this.state.subscribed,
  //           email: ''
  //         })
  //         this.setShowAlert();
  //         setTimeout(() => {
  //           this.setShowAlert();
  //         }, 5000);
  //       })
  //       .catch((err) => {
  //         //console.log(err.response?.data);
  //       });
  // }

  setSubscription = () => {
    console.log("Subscription successful");
    this.setState({
      subscribed: !this.state.subscribed,
      email: ''
    })
  }
  setShowAlert = () => {
    console.log("Alert set");
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  static contextType = AppContext;
  render() {
    const { toggleDarkMode } = this.context;

    const subscribeMessage = this.state.subscribed ? (
      <p style={{ fontSize: "14px" }}>
        Newsletter subscription is active
      </p>
    ) : (
      <p style={{ fontSize: "14px" }}>
        You are not currently subscribed to any newsletter
      </p>
    )

    return (
      <>
        {this.state.showAlert && (
          <div className="alert alert-success" role="alert">
            {this.state.message}
          </div>
        )}
        <Card className={toggleDarkMode ? "dark" : ""} id="newsletter">
          <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
            <Row>
              <Col>Newsletter Preferences</Col>
              <Col className="col-2">
                <i
                  className={
                    toggleDarkMode ? "far fa-edit dark" : "far fa-edit"
                  }
                ></i>
              </Col>
            </Row>
          </span>
          <div
            className={toggleDarkMode ? "card-content dark" : "card-content"}
          >
            <Row>
              <Col className="col">
                <p style={{ fontSize: "14px" }}>
                  {/* You are not currently subscribed to any newsletter */}
                  {subscribeMessage}
                </p>
              </Col>
            </Row>
            <NewsletterForm setShowAlert={this.setShowAlert} setSubscription={this.setSubscription} />
          </div>
        </Card>
      </>
    );
  }
}

export default Newsletter;
