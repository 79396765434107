import React, { Component, useContext } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Modal,
  Row,
  Col,
} from "reactstrap";

import "../css/MyAccount.css";
import AccountTypeModal from "./AccountTypeModal";
import { whatRoleIsThis } from "../../services/auth.service";
//import { AppContext } from "../../AppContext/AppContext";

class AccountType extends Component {
  constructor(props) {
    super(props);
    this.handleSuccessMessage = this.handleSuccessMessage.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
  }

  state = {
    brokerLinkIndicator: "",
    showAlert: false,
  };

  handleSuccessMessage = () => {
    this.setState({
      brokerLinkIndicator: "Your request has been sent.",
    });
  };

  setShowAlert = () => {
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  // getSnapshotBeforeUpdate(prevState) {
  //   // Are we adding new items to the list?
  //   // Capture the scroll position so we can adjust scroll later.
  //   if (
  //     prevState.brokerLinkIndicator ===
  //     "Your request has been sent. Check back on subsequent logins."
  //   ) {
  //     return "Your request has been sent. Check back on subsequent logins.";
  //   }
  //   return null;
  // }

  // componentDidUpdate(snapshot) {
  //   if (snapshot) {
  //     this.state.brokerLinkIndicator = snapshot;
  //   }
  // }

  render() {
    //const { brokerLinkIndicator } = useContext(AppContext);
    return (
      <>
        {this.state.showAlert &&
          this.state.brokerLinkIndicator === "Your request has been sent." && (
            <div className="alert alert-success" role="alert">
              {this.state.brokerLinkIndicator}
            </div>
          )}
        <Card id="account-type">
          <span className="cardHeader">
            <Row>
              <Col>Account Type</Col>
              <Col className="col-2">
                <button onClick={() => this.props.toggleModal("AccountType")}>
                  <i className="far fa-edit"></i>
                </button>
              </Col>
            </Row>
          </span>
          <div className="card-content">
            {whatRoleIsThis() === "Customer" ? (
              <>
                <p style={{ fontSize: "14px" }}>
                  {" "}
                  Customer Account. {this.state.brokerLinkIndicator}
                </p>
                <br />
                <Row>
                  <Col className="col">
                    <button
                      onClick={() => {
                        this.props.toggleModal("AccountType");
                      }}
                    >
                      <span
                        style={{
                          // @ts-ignore
                          fontWeight: "600",
                        }}
                      >
                        REQUEST FOR ACCOUNT UPGRADE
                      </span>
                    </button>
                  </Col>
                </Row>
              </>
            ) : (
              <p style={{ fontSize: "14px" }}>Broker Account</p>
            )}
          </div>
        </Card>
        <AccountTypeModal
          accountType={this.props.accountType}
          toggleModal={this.props.toggleModal}
          resetModal={this.props.resetModal}
          handleSuccessMessage={this.handleSuccessMessage}
          setShowAlert={this.setShowAlert}
          showAlert={this.state.showAlert}
        />
      </>
    );
  }
}

export default AccountType;
