import React, { useState, useContext } from "react";
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import "./Spots.css";
import classnames from "classnames";
import OpenOrder from "./OpenOrder";
import OrderHistory from "./OrderHistory";
import { AppContext } from "../../AppContext/AppContext";

const TableBelow = ({ openOrder, orderHistory }) => {
  const [activeTab, setActiveTab] = useState("1");
  const { toggleDarkMode } = useContext(AppContext);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navStyles = {
    color: toggleDarkMode ? "#fff" : "black",
    backgroundColor: toggleDarkMode ? "black" : "#fff",
  };

  return (
    <div>
      <Card
        className="mb-2 mb-xl-0"
        style={{
          backgroundColor: toggleDarkMode ? "black" : null,
        }}
      >
        <CardBody>
          <Nav pills>
            <NavItem className="nav-item">
              <NavLink
                className={classnames(
                  { active: activeTab === "1" },
                  "spotNavLink"
                )}
                onClick={() => {
                  toggle("1");
                }}
                style={navStyles}
              >
                <span style={{ color: toggleDarkMode ? "white" : "" }}>
                  Open Order ({openOrder?.length})
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === "2" },
                  "spotNavLink"
                )}
                onClick={() => {
                  toggle("2");
                }}
                style={navStyles}
              >
                <span style={{ color: toggleDarkMode ? "white" : "" }}>
                  Order History
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === "3" },
                  "spotNavLink"
                )}
                onClick={() => {
                  toggle("3");
                }}
                style={navStyles}
              >
                <span style={{ color: toggleDarkMode ? "white" : "" }}>
                  Track History
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === "4" },
                  "spotNavLink"
                )}
                onClick={() => {
                  toggle("4");
                }}
                style={navStyles}
              >
                <span style={{ color: toggleDarkMode ? "white" : "" }}>
                  Funds
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <OpenOrder openOrder={openOrder} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <OrderHistory orderHistory={orderHistory} />
            </TabPane>
            <TabPane tabId="3"></TabPane>
            <TabPane tabId="4"></TabPane>
            <TabPane tabId="5"></TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

export default TableBelow;
