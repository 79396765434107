import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Input, Label, Container, Row, Col } from "reactstrap";

import "./order.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { whatRoleIsThis } from "../../services/auth.service";
import OrderHistoryTable from "./OrderHistoryTable";
import Pagination from "../Market/Pagination";

import axios from "axios";
import cookie from "js-cookie";
import tradeHistory from "../../dummyData/tradeHistory";
import { AppContext } from "../../AppContext/AppContext";

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    //this.displayModal = this.displayModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    dropdownOpen: false,
    data: [],
    startDate: new Date("1980-01-01T00:00:00.000Z"),
    endDate: new Date(),
    startDateView: new Date("1980-01-01T00:00:00.000Z"),
    endDateView: new Date(),
    commoditySelect: "",
    marketSelect: "",
    tradeSideSelect: "",
    statusSelect: "",
    customerID: "",
    customerName: "",
    currentPage: 1,
    postsPerPage: 5,
    loading: false,
  };

  handleDateRangeChange = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };
  handleCommodityChange = (e) => {
    this.setState({ commoditySelect: e.target.value });
    // //console.log("i changed on click");
  };
  handleMarketChange = (e) => {
    this.setState({ marketSelect: e.target.value });
  };

  handleTradeSideChange = (e) => {
    this.setState({ tradeSideSelect: e.target.value });
  };
  handleStatusChange = (e) => {
    this.setState({ statusSelect: e.target.value });
  };

  search = () => {
    const token = cookie.get("token");
    this.setState({ loading: true, data: [] });
    if (whatRoleIsThis() === "Broker") {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      //console.log(config);
      //console.log(this.state.startDate);
      axios
        .get(
          `https://server.gcmx.bluetag.tech/api/Transaction/OrderHistoryByParametersForBroker?CommodityID=${this.state.commoditySelect
          }&MarketType=${this.state.marketSelect}&TradeSide=${this.state.tradeSideSelect
          }&OrderStatus=${this.state.statusSelect}&CustomerName=${this.state.customerName
          }&CustomerID=${this.state.customerID
          }&StartDate=${this.state.startDate.toJSON()}&EndDate=${this.state.endDate.toJSON()}`,
          config
        )
        .then((res) => {
          //console.log(res);
          //var result = JSON.parse(res.data);
          this.setState({
            startDateView: this.state.startDate,
            endDateView: this.state.endDate,
            data: res.data,
          });
        })
        .catch(/*(err) => //console.log(err)*/);
    }

    if (whatRoleIsThis() === "Customer") {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      //console.log(config);
      //console.log(typeof this.state.startDate);
      axios
        .get(
          `https://server.gcmx.bluetag.tech/api/Transaction/OrderHistoryByParametersForCustomer?CommodityID=${this.state.commoditySelect
          }&MarketType=${this.state.marketSelect}&TradeSide=${this.state.tradeSideSelect
          }&OrderStatus=${this.state.statusSelect
          }&StartDate=${this.state.startDate.toJSON()}&EndDate=${this.state.endDate.toJSON()}`,
          config
        )
        .then((res) => {
          //console.log(res);
          //var result = JSON.parse(res.data);
          this.setState({
            startDateView: this.state.startDate,
            endDateView: this.state.endDate,
            data: res.data,
          });
        })
        .catch(/*(err) => //console.log(err)*/);
    }
  };

  toggle = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  fetchOrderHistory = () => {
    const token = cookie.get("token");

    axios
      .get("https://server.gcmx.bluetag.tech/api/Transaction/OrderHistory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        this.setState({ data: res.data });
        //console.log(JSON.stringify(res.data));
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  componentDidMount() {
    this.fetchOrderHistory();
  }

  handleChange(event) {
    event.preventDefault();
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  static contextType = AppContext;

  render() {
    const { data, currentPage, postsPerPage } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const { toggleDarkMode } = this.context;

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

    return (
      <>
        <Container
          fluid
          className={
            toggleDarkMode ? "bg-dark mainPage dark" : "bg-light mainPage"
          }
        >
          <Row>
            <Col lg={4}>
              <h4
                style={{
                  color: toggleDarkMode ? "white" : null,
                }}
              >
                Order History
              </h4>
            </Col>
            {whatRoleIsThis() === "Broker" ? (
              <Col lg={2}>
                <Input type="checkbox" name="customers" id="customers" />
                <Label for="customers">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    All customers
                  </span>
                </Label>
              </Col>
            ) : (
              <Col lg={2}></Col>
            )}
            <Col lg={2}>
              <Input type="checkbox" name="hideCanceled" id="hideCanceled" />
              <Label for="forCanceled">
                <span
                  style={{
                    color: toggleDarkMode ? "white" : null,
                  }}
                >
                  Hide all canceled
                </span>
              </Label>
            </Col>
            <Col lg={2.5}>
              <a href="#">
                <i className="fas fa-download"></i>{" "}
                <Label>
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Export complete order history
                  </span>
                </Label>
              </a>
            </Col>
          </Row>
          <Card className={toggleDarkMode ? "search-bar dark" : "search-bar"}>
            <Row>
              {/* <Col lg={5}>
                    <Label for="customerName">Customer Name</Label>
                    <Input
                      type="text"
                      name="customerName"
                      id="customerName"
                      onChange={this.handleChange}
                      placeholder="Enter Customer Name"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label for="customerID">Customer ID</Label>
                    <Input
                      type="text"
                      name="customerID"
                      id="customerID"
                      onChange={this.handleChange}
                      placeholder="Enter Customer ID"
                    />
                  </Col> */}
              <Col md={4}>
                <Label for="orderStatus">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Order Status
                  </span>
                </Label>
                <br />
                <select
                  name="orderStatus"
                  id="orderStatus"
                  value={this.state.statusSelect}
                  onChange={this.handleStatusChange}
                  className={
                    toggleDarkMode
                      ? "dropdown-custom dark full-length"
                      : "dropdown-custom full-length"
                  }
                >
                  <option value="All">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </Col>
              <Col md={4}>
                <Label for="startDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Start Date
                  </span>
                </Label>
                {/* <DateRangePicker
                      initialSettings={{
                        startDate: "01/12/2021",
                        endDate: "12/25/2021",
                      }}
                      onApply={this.handleDateRangeChange}
                    >
                      <button
                        className="btn-block btn-datePick"
                        style={{ height: "38px" }}
                      >
                        Start Date-End Date
                        <i
                          className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                          style={{ color: "#24B086" }}
                        />
                      </button>
                    </DateRangePicker> */}
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                        color: "white",
                        backgroundColor: "#495057",
                      }
                      : null
                  }
                />
              </Col>
              <Col md={4}>
                <Label for="endDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    End Date
                  </span>
                </Label>
                {/* <DateRangePicker
                      initialSettings={{
                        startDate: "01/12/2021",
                        endDate: "12/25/2021",
                      }}
                      onApply={this.handleDateRangeChange}
                    >
                      <button
                        className="btn-block btn-datePick"
                        style={{ height: "38px" }}
                      >
                        Start Date-End Date
                        <i
                          className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                          style={{ color: "#24B086" }}
                        />
                      </button>
                    </DateRangePicker> */}
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                        color: "white",
                        backgroundColor: "#495057",
                      }
                      : null
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Label for="commodityName">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Commodity
                  </span>
                </Label>
                <br />
                <select
                  name="commodityName"
                  id="commodityName"
                  value={this.state.commoditySelect}
                  onChange={this.handleCommodityChange}
                  className={
                    toggleDarkMode ? "dropdown-custom dark" : "dropdown-custom"
                  }
                >
                  <option value="All">All</option>
                  <option value="R1">Rice</option>
                  <option value="R1-PADDY">Rice-PADDY</option>
                  <option value="Millet">Millet</option>
                  <option value="Beans">Beans</option>
                  <option value="Wheat">Wheat</option>
                  <option value="Corn">Corn</option>
                  <option value="Sorghum">Sorghum</option>
                  <option value="SB">Soya Beans</option>
                  <option value="SS">Sesame Seeds</option>
                  <option value="Maize">Maize</option>
                </select>
              </Col>
              <Col>
                <Label for="market">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Market
                  </span>
                </Label>
                <br />
                <select
                  name="market"
                  id="market"
                  value={this.state.marketSelect}
                  onChange={this.handleMarketChange}
                  className={
                    toggleDarkMode ? "dropdown-custom dark" : "dropdown-custom"
                  }
                >
                  <option value="All">All</option>
                  <option value="Spot">Spot Market</option>
                  <option value="Futures">Futures market</option>
                </select>
              </Col>
              <Col>
                <Label for="tradeSide">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Trade side
                  </span>
                </Label>
                <br />
                <select
                  name="tradeSide"
                  id="tradeSide"
                  value={this.state.tradeSideSelect}
                  onChange={this.handleTradeSideChange}
                  className={
                    toggleDarkMode ? "dropdown-custom dark" : "dropdown-custom"
                  }
                >
                  <option value="All">All</option>
                  <option value="Buy">Buy</option>
                  <option value="Sell">Sell</option>
                </select>
              </Col>
              <Col>
                <button
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                  onClick={this.fetchOrderHistory}
                >
                  Reset
                </button>
              </Col>
              <Col>
                <button
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                  onClick={this.search}
                >
                  Search
                </button>
              </Col>
            </Row>
          </Card>
          <OrderHistoryTable data={currentPosts} />
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
          />
        </Container>
      </>
    );
  }
}

export default OrderHistory;
