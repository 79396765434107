import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Card, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import RequestDeliveryModals from "./RequestDeliveryModals";
import { AppContext } from "../../AppContext/AppContext";
import "./request.css";

const RequestDelivery = ({ location }) => {
  const [deliveryConfirmation, setDeliveryConfirmation] = useState(false);
  const [OTPForm, setOTPForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestDeliveryModal, setRequestDeliveryModal] = useState(false);

  const { deliveryDetails, updateDeliveryDetailsForm, fetchOTP } =
    useContext(AppContext);

  const { row } = location.state;
  //console.log(row);

  return (
    <>
      <Container fluid className="bg-light mainPage">
        <Row>
          <Col>
            <h3>Request Delivery</h3>
          </Col>
        </Row>
        <Card className="form-bar">
          <Formik
            initialValues={{
              commodity_name: row.commodity,
              grade: row.grade,
              available_balance: row.total_available,
              withdrawal_quantity: "",
              state: "",
              city: "",
              town: "",
              shipping_address: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              const token = cookie.get("token");

              setTimeout(() => {
                //   axios
                //     .post(
                //       "https://server.gcmx.bluetag.tech/api/UserManager/BrokerUpgradeRequest",
                //       user,
                //       {
                //         headers: {
                //           Authorization: "Bearer " + token,
                //         },
                //       }
                //     )
                //     .then((res) => {
                //       console.log(res);
                //       console.log("Success");
                //       updateBrokerLinkIndicator(
                //         "Your request has been sent. Check back on subsequent logins."
                //       );
                //       modalProps.toggleModal("AccountType");
                //     })
                //     .catch((err) => {
                //       console.log(err.response?.data);
                //     });
                updateDeliveryDetailsForm(values);
                setRequestDeliveryModal(!requestDeliveryModal);
                setDeliveryConfirmation(!deliveryConfirmation);
                fetchOTP();
                setSubmitting(false);
              }, 1500);
            }}
            validationSchema={Yup.object().shape({
              commodity_name: Yup.string().required("Required"),
              grade: Yup.string().required("Required"),
              available_balance: Yup.number().required("Required"),
              withdrawal_quantity: Yup.number()
                .lessThan(
                  Yup.ref("available_balance"),
                  "Quantity higher than the available quantity"
                )
                .required("Required"),
              state: Yup.string().required("Required"),
              city: Yup.string().required("Required"),
              town: Yup.string().required("Required"),
              shipping_address: Yup.string().required("Required"),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                status,
                setFieldValue,
                setErrors,
              } = props;

              return (
                <Form className="pt-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="commodity_name">Commodity Name</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="commodity_name"
                          id="commodity_name"
                          value={values.commodity_name}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.commodity_name && touched.commodity_name && (
                          <div className="input-feedback float-left">
                            {errors.commodity_name}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="grade">Grade</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="grade"
                          id="grade"
                          value={values.grade}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.grade && touched.grade && (
                          <div className="input-feedback float-left">
                            {errors.grade}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="available_balance">
                          Available Balance
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="available_balance"
                          id="available_balance"
                          value={values.available_balance}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.available_balance &&
                          touched.available_balance && (
                            <div className="input-feedback float-left">
                              {errors.available_balance}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="withdrawal_quantity">
                          Withdrawal Quantity (Tons)
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="withdrawal_quantity"
                          id="withdrawal_quantity"
                          value={values.withdrawal_quantity}
                          onChange={handleChange}
                        />
                        {errors.withdrawal_quantity &&
                          touched.withdrawal_quantity && (
                            <div className="input-feedback float-left">
                              {errors.withdrawal_quantity}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="state">State</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="state"
                          id="state"
                          value={values.state}
                          onChange={handleChange}
                        />
                        {errors.state && touched.state && (
                          <div className="input-feedback float-left">
                            {errors.state}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="city">City</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="city"
                          id="city"
                          value={values.city}
                          onChange={handleChange}
                        />
                        {errors.city && touched.city && (
                          <div className="input-feedback float-left">
                            {errors.city}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="town">Town</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="town"
                          id="town"
                          value={values.town}
                          onChange={handleChange}
                        />
                        {errors.town && touched.town && (
                          <div className="input-feedback float-left">
                            {errors.town}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="shipping_address">
                          Shipping Address
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="shipping_address"
                          id="shipping_address"
                          value={values.shipping_address}
                          onChange={handleChange}
                        />
                        {errors.shipping_address &&
                          touched.shipping_address && (
                            <div className="input-feedback float-left">
                              {errors.shipping_address}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <div className="row justify-content-center">
                    <div className="col-6">
                      <button
                        className="btn btn-block btn-success button-default"
                        style={{ background: "#24B086" }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Container>
      <RequestDeliveryModals
        requestDeliveryModal={requestDeliveryModal}
        setRequestDeliveryModal={setRequestDeliveryModal}
        deliveryConfirmation={deliveryConfirmation}
        setDeliveryConfirmation={setDeliveryConfirmation}
        OTPForm={OTPForm}
        setOTPForm={setOTPForm}
        success={success}
        setSuccess={setSuccess}
      />
    </>
  );
};

export default RequestDelivery;
