import React, { useContext, useState } from "react";
//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cookie from "js-cookie";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";

const BankAccountDetailsForm = ({ modalProps }) => {
  const { fetchEmailOTP, updateBankDetailsForm, toggleDarkMode } =
    useContext(AppContext);
  return (
    <Formik
      initialValues={{
        account_name:
          modalProps.bank.account_name || modalProps.data.account_name,
        account_number:
          modalProps.bank.account_number || modalProps.data.account_number,
        bank: modalProps.bank.bank || modalProps.data.bank,
        account_type:
          modalProps.bank.account_type || modalProps.data.account_type,
        bvn: modalProps.bank.bvn || modalProps.data.bvn,
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = cookie.get("token");
        modalProps.toggleModal2("modalview5");
        modalProps.toggleModal2("modalview4");
        updateBankDetailsForm(values);
        setTimeout(() => {
          const user = {
            account_name: values.account_name,
            account_number: values.account_number,
            bank: values.bank,
            account_type: values.account_type,
            bvn: values.bvn,
          };
          fetchEmailOTP()
            .then((res) => {
              console.log(res.data);
              //console.log("Success");
            })
            .catch((err) => {
              console.log(err.response?.data);
            });
          setSubmitting(false);
        }, 1500);
      }}
      validationSchema={Yup.object().shape({
        account_name: Yup.string().required("Required"),
        account_number: Yup.string().required("Required"),
        bank: Yup.string().required("Required"),
        account_type: Yup.string().required("Required"),
        bvn: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          setFieldValue,
          setErrors,
        } = props;

        return (
          <Form className="pt-3" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="accountType">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Account Type
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Account Type"
                    name="account_type"
                    value={values.account_type}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.account_type && touched.account_type && (
                    <div className="input-feedback float-left">
                      {errors.account_type}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="accountNumber">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Account Number
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Account Number"
                    name="account_number"
                    value={values.account_number}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.account_number && touched.account_number && (
                    <div className="input-feedback float-left">
                      {errors.account_number}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="bvn">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      BVN
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="BVN"
                    name="bvn"
                    value={values.bvn}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.bvn && touched.bvn && (
                    <div className="input-feedback float-left">
                      {errors.bvn}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="accountName">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Account Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Account Name"
                    name="account_name"
                    value={values.account_name}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.account_name && touched.account_name && (
                    <div className="input-feedback float-left">
                      {errors.account_name}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <FormGroup>
                  <Label htmlFor="bank">
                    <span
                      style={{
                        color: toggleDarkMode ? "white" : null,
                      }}
                    >
                      Bank Name
                    </span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control textbox-custom2"
                    placeholder="Bank Name"
                    name="bank"
                    value={values.bank}
                    onChange={handleChange}
                    style={
                      toggleDarkMode
                        ? {
                            backgroundColor: "#495057",
                            color: "white",
                          }
                        : null
                    }
                  />
                  {errors.bank && touched.bank && (
                    <div className="input-feedback float-left">
                      {errors.bank}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="row justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-block btn-success button-default"
                  style={{ background: "#24B086" }}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankAccountDetailsForm;
