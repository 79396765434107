// @ts-nocheck
import React, { createContext, useEffect, useState } from "react";

import cookie from "js-cookie";
import config from "../config.json";
import axios from "axios";
export const AppContext = createContext();
export const AppProvider = (props) => {
  const API = "https://server.gcmx.bluetag.tech/g/api/";
  const [customerWalletDetails, setCustomerWalletDetails] = useState([]);
  const [customersWalletSummary, setCustomersWalletSummary] = useState(null);
  const [customersWallet, setCustomersWallet] = useState({});
  // /api/Transaction/CustomerWalletDetails
  const [ewrDeposit, setEWRDeposit] = useState([]);
  const [EWRRetrieval, setEWRRetrieval] = useState([]);
  const [cardDeposit, setCardDeposit] = useState([]);
  const [feedback, setfeedback] = useState();
  const [userProfile, setUserProfile] = useState();
  //const [token, settoken] = useState("");
  const [toggleDarkMode, setToggleDarkMode] = useState(false);

  const userToken = cookie.get("token");

  const userEmail = cookie.get("email");
  useEffect(() => {
    fetchCustomerWallet();
    getCustomersWalletSummary();
    fetchUserProfile();
    // fetchEWRRetrieval('lorem')
  }, []);


  // useEffect(() => {
  //   fetchUserProfile();
  // }, [user]);
  const fetchEWRRetrieval = (number) => {
    //console.log(token);
    return axios.get(
      `${config.BASE_URL}Transaction/ContractRetrieval?ContractNumber=${number}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
  };
  const fetchOTP = () => {
    //console.log(token);
    return axios.get(`${config.BASE_URL}Common/SendOTPCodeViaSMS`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  };
  const verifyOTP = (otp) => {
    //console.log(token);
    return axios.get(`${config.BASE_URL}Common/VerifyOTPCode?OTP=${otp}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  };
  const fetchEmailOTP = () => {
    //console.log(token);
    return axios.get(`${config.BASE_URL}Common/SendOTPCodeViaEmail`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  };
  const verifyEmailOTP = (otp) => {
    //console.log(token);
    return axios.get(`${config.BASE_URL}Common/VerifyOTPCode?OTP=${otp}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  };
  const postEWRDeposit = (deposit) => {
    axios
      .post(`${config.BASE_URL}Transaction/ContractDeposit?`, deposit, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        // //console.log(res.data)
        setEWRDeposit(res.data);
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const postCardDeposit = (deposit) => {
    axios
      .post(`${config.BASE_URL}Transaction/CardDeposit?`, deposit, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        // //console.log(res.data)
        setCardDeposit(res.data);
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const getCustomersWalletSummary = async () => {
    await axios
      .get(`${config.BASE_URL}Transaction/CustomersWalletSummary`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        //console.log(res.data);
        setCustomersWalletSummary(res.data);
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const getCustomersWalletDetails = (customerID) => {
    return axios.get(
      `${config.BASE_URL}Transaction/CustomerWalletDetails?CustomerID=${customerID}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
  };
  const fetchUserProfile = () => {
    axios
      .get(`${config.BASE_URL}UserManager/UserProfile`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        // console.log('User profile: ' + res.data);
        setUserProfile(res.data);
        getCustomersWalletDetails(res.data.customerid);
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const fetchCustomerWallet = () => {
    let userToken = cookie.get("token")
    axios
      .get(`${config.BASE_URL}Transaction/CustomerWallet`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        //console.log(token);
        setCustomersWallet(res.data);
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };

  const changePassword = (params, props) => {
    axios
      .post(
        `https://server.gcmx.bluetag.tech/api/UserManager/ChangePassword?OldPassword=${params.OldPassword}&NewPassword=${params.NewPassword}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        //console.log("Success");
        props.toggleModal2("modalview3");
        props.toggleModal2("modalview2");
      })
      .catch((err) => {
        //console.log(err.response?.data);
      });
  };

  const updateBankAccountDetails = (user, props) => {
    //console.log(user);
    axios
      .post(
        "https://server.gcmx.bluetag.tech/api/UserManager/UpdateBankAccount",
        user,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        //console.log("Success");
        props.toggleModal2("modalview6");
        props.toggleModal2("modalview5");
        //console.log(props.modalview5);
      })
      .catch((err) => {
        //console.log(err.response?.data);
      });
  };

  const [bankDetailsForm, setBankDetailsForm] = useState({
    account_name: "",
    account_number: "",
    bank: "",
    account_type: "",
    bvn: "",
  });

  const updateBankDetailsForm = (obj) => {
    setBankDetailsForm({
      ...bankDetailsForm,
      ...obj,
    });
    //console.log(bankDetailsForm);
  };

  const [deliveryDetails, setDeliveryDetails] = useState({
    commodity_name: "",
    grade: "",
    available_balance: "",
    withdrawal_quantity: "",
    state: "",
    city: "",
    town: "",
    shipping_address: "",
  });

  const updateDeliveryDetailsForm = (obj) => {
    setDeliveryDetails({
      ...deliveryDetails,
      ...obj,
    });
  };

  const [changePasswordForm, setChangePasswordForm] = useState({
    OldPassword: "",
    NewPassword: "",
  });

  const updateChangePasswordForm = (obj) => {
    setChangePasswordForm({
      ...changePasswordForm,
      ...obj,
    });
    //console.log(changePasswordForm);
  };

  const [brokerLinkIndicator, setBrokerLinkIndicator] = useState("");

  const updateBrokerLinkIndicator = (val) => {
    setBrokerLinkIndicator(brokerLinkIndicator, val);
  };

  const [deliveryHistory, setDeliveryHistory] = useState({
    date_requested: "",
    customer_name: "",
    customer_id: "",
    delivery_id: "",
    commodities: "",
    grade: "",
    quantity_requested: "",
    delivery_fee: "",
    delivery_date: "",
    status: "",
  });

  const updateDeliveryHistory = (value) => {
    setDeliveryHistory({
      ...deliveryHistory,
      ...value,
    });
  };

  const [brokerFormDetails, setBrokerFormDetails] = useState({
    selectedBroker: '',
    canOrder: false,
    canWithdraw: false
  })

  const updateBrokerFormdetails = (value) => {
    setBrokerFormDetails({
      ...brokerFormDetails,
      ...value
    })
  }


  return (
    <AppContext.Provider
      value={{
        fetchEWRRetrieval,
        postEWRDeposit,
        fetchCustomerWallet,
        fetchUserProfile,
        postCardDeposit,
        getCustomersWalletDetails,
        customersWalletSummary,
        getCustomersWalletSummary,
        fetchOTP,
        verifyOTP,
        fetchEmailOTP,
        verifyEmailOTP,
        customersWallet,
        bankDetailsForm,
        changePasswordForm,
        updateBankDetailsForm,
        updateChangePasswordForm,
        changePassword,
        updateBankAccountDetails,
        updateBrokerLinkIndicator,
        brokerLinkIndicator,
        userProfile,
        deliveryDetails,
        updateDeliveryDetailsForm,
        deliveryHistory,
        updateDeliveryHistory,
        toggleDarkMode,
        setToggleDarkMode,
        brokerFormDetails,
        updateBrokerFormdetails
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
