import { Form, Formik } from "formik";
import React, { useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import * as Yup from "yup";
import "./transaction.css";
import { AppContext } from "../../AppContext/AppContext";

const WithdrawalExportForm = () => {
  const handleDateRangeChange = (event, value, picker) => {
    value = picker.startDate + " - " + picker.endDate;
    return value;
  };

  const { toggleDarkMode } = useContext(AppContext);
  return (
    <>
      <Formik
        initialValues={{
          dateRange: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          //const token = cookie.get("token");

          setTimeout(() => {
            //   axios
            //     .post(
            //       "https://server.gcmx.bluetag.tech/api/UserManager/UpdateContactAddress",
            //       {
            //         headers: {
            //           Authorization: "Bearer " + token,
            //         },
            //       }
            //     )
            //     .then((res) => {
            //       console.log(res);
            //       console.log("Success")
            //       modalProps.toggleModal("Address");
            //     })
            //     .catch((err) => {
            //       console.log(err.response?.data);
            //     });
            setSubmitting(false);
          }, 1500);
        }}
        validationSchema={Yup.object().shape({
          dateRange: Yup.string().required("Required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            status,
            setFieldValue,
            setErrors,
          } = props;

          return (
            <Form className="pt-3" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dateRange"
                                onChange={handleChange}
                                style={{ position: "static" }}
                              />{" "}
                              <span
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              >
                                Last 24 Hours
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dateRange"
                                onChange={handleChange}
                                style={{ position: "static" }}
                              />{" "}
                              <span
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              >
                                Yesterday
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dateRange"
                                onChange={handleChange}
                                style={{ position: "static" }}
                              />{" "}
                              <span
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              >
                                Past 2 weeks
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dateRange"
                                onChange={handleChange}
                                style={{ position: "static" }}
                              />{" "}
                              <span
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              >
                                Past month
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dateRange"
                                onChange={handleChange}
                                style={{ position: "static" }}
                              />{" "}
                              <span
                                style={{ color: toggleDarkMode ? "white" : "" }}
                              >
                                Past 3 months
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={5}>
                      <Label for="Date">
                        <span style={{ color: toggleDarkMode ? "white" : "" }}>
                          Date
                        </span>
                      </Label>
                      <DateRangePicker
                        initialSettings={{
                          startDate: "01/12/2020",
                          endDate: "12/25/2020",
                        }}
                        onApply={handleDateRangeChange}
                      >
                        <button
                          className="btn-block btn-datePick"
                          style={{ height: "38px" }}
                        >
                          Start Date-End Date
                          <i
                            className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                            style={{ color: "#24B086" }}
                          />
                        </button>
                      </DateRangePicker>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <div className="row justify-content-center">
                <div className="col-6">
                  <button
                    className="btn btn-block btn-success button-default"
                    style={{ background: "#24B086" }}
                    type="submit"
                  >
                    Export
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default WithdrawalExportForm;
