import React, { Component } from "react";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Modal } from "reactstrap";

import "../css/MyAccount.css";

import axios from "axios";
import cookie from "js-cookie";
import AddressForm from "./AddressForm";

class AddressModal extends Component {
  constructor(props) {
    super(props);
    this.loadCountryList = this.loadCountryList.bind(this);
    this.state = {
      countryList: [],
    };
  }

  async loadCountryList() {
    try {
      const token = cookie.get("token");

      //console.log(this.state);

      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/CountryList",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      //const response = loginInfo;

      if (response.status === 200) {
        //console.log("Success");
        //const permissions = response.data.permissions;
        this.setState({ countryList: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      this.setState({ errorMessage: "Failed" });
      // //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.loadCountryList();
  }

  componentWillUnmount() {
    this.props.resetModal("Address");
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.address}
          toggle={() => this.props.toggleModal("Address")}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6 className="modal-title" id="modal-title-default">
                  Address
                </h6>
              </div>
              <div className="col-1">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.toggleModal("Address")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>
            <AddressForm
              modalProps={this.props}
              countryList={this.state.countryList}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default AddressModal;
