/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React from "react";
import ReactDOM from "react-dom";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";

//import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "assets/scss/argon-dashboard-react.scss";
//import "bootstrap/dist/css/bootstrap.min.css";
//import "../node_modules/font-awesome/css/font-awesome.min.css";
// @ts-ignore
import AdminLayout from "layouts/Admin.js";
// @ts-ignore
import AuthLayout from "layouts/Auth.js";
import "./override.css";
import { AppProvider } from "./AppContext/AppContext";

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />

        <Redirect from="/" to="/auth/market" />
      </Switch>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);
