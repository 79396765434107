import React, { Component } from "react";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
//import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import AccountDetails from "./AccountDetails";
import Address from "./Address";
import AccountType from "./AccountType";
import BankAccountDetails from "./BankAccountDetails";
import CurrencyOption from "./CurrencyOption";
import Newsletter from "./Newsletter";
import AccountOverview from "./AccountOverview";

import axios from "axios";
import cookie from "js-cookie";

import { whatRoleIsThis } from "../../services/auth.service";
import LinkToBroker from "./LinkToBroker";
import nationalityList from "../../dummyData/nationalityList";
import { AppContext } from "../../AppContext/AppContext";
import CurrencyConverter from "./CurrencyConverter";
import { imagePath } from "../../services/utility.service";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    //this.displayModal = this.displayModal.bind(this);
    this.checkNationality = this.checkNationality.bind(this);
  }

  state = {
    AccountDetails: false,
    AccountOverview: false,
    ChangePassword: false,
    Address: false,
    AccountType: false,
    BankAccountDetails: false,
    LinkToBroker: false,
    ChangeBrokerModal: false,
    
    nationality: "",
    data: {},
    bank: {},
    errorMessage: "",
    passport: "",
    maskedNum: "",
    imagePath: ""
  };

  checkNationality = (country) => {
    var indegene = nationalityList.filter(
      (indigene) => indigene.country == country
    );
    this.setState({ nationality: indegene[0].adj });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  resetModal = (state) => {
    this.setState({
      [state]: false,
    });
  };

  refreshUserProfile = (state, user) => {
    this.setState({
      [state]: user,
    });
  };

  refreshPassport = (state, imagePath) => {
    this.setState({
      [state]: imagePath,
    });
  }

  refreshBankDetails = (state, user) => {
    this.setState({
      [state]: user,
    });
  };

  maskNumbers = (n) => {
    const unmask = n;
    const letters = unmask.split("");
    const newMask = letters.map((n, key) => { if (key < letters.length - 6) { return n = "*" } else { return n } }).join("");
    this.setState({ maskedNum: newMask })
  }

  async fetchUserProfile() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/UserManager/UserProfile",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.maskNumbers(response.data.card_number);
        this.setState({ imagePath: imagePath + response.data.passport })
        this.setState({ data: response.data });
        console.log(response.data)
        this.checkNationality(response.data.country);
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  }

  componentDidMount() {
    this.fetchUserProfile();
  }

  static contextType = AppContext;

  render() {
    const { toggleDarkMode } = this.context;
    return (
      <>
        {/* <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../components/img/logo.svg"),
            imgAlt: "...",
          }}
        /> */}
        <div
          className={
            toggleDarkMode ? "MainPage dark bg-dark" : "MainPage bg-light"
          }
        >
          <h4
            // @ts-ignore
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "27px",
              color: toggleDarkMode ? "white" : "null",
            }}
          >
            Account Overview
          </h4>
          <Row className="mb-3">
            <Col>
              <AccountOverview
                data={this.state.data}
                accountDetails={this.state.AccountDetails}
                changePassword={this.state.ChangePassword}
                toggleModal={this.toggleModal}
                resetModal={this.resetModal}
                fetchUserProfile={this.fetchUserProfile}
                nationality={this.state.nationality}
                accountType={this.state.AccountType}
                refreshUserProfile={this.refreshUserProfile}
                refreshPassport={this.refreshPassport}
                maskedNum={this.state.maskedNum}
                imagePath={this.state.imagePath}
              />
            </Col>
          </Row>
          {/* <Row className="mb-3">
            <Col md={6}>
              <AccountDetails
                data={this.state.data}
                accountDetails={this.state.AccountDetails}
                changePassword={this.state.ChangePassword}
                toggleModal={this.toggleModal}
                resetModal={this.resetModal}
                fetchUserProfile={this.fetchUserProfile}
              />
            </Col>
            <Col md={6}>
              <Address
                data={this.state.data}
                address={this.state.Address}
                toggleModal={this.toggleModal}
                resetModal={this.resetModal}
                fetchUserProfile={this.fetchUserProfile}
              />
            </Col>
          </Row> */}
          <Row className="mb-3">
            <Col md={6}>
              {/* <Row className="mb-4">
                <Col>
                  <AccountType
                    accountType={this.state.AccountType}
                    toggleModal={this.toggleModal}
                    resetModal={this.resetModal}
                    fetchUserProfile={this.fetchUserProfile}
                  />
                </Col>
              </Row> */}
              {whatRoleIsThis() === "Customer" ? (
                <Row className="mb-4">
                  <Col>
                    <Newsletter />
                  </Col>
                </Row>
              ) : null}
              <Row className="mb-4">
                <Col>
                  <CurrencyOption data={this.state.data} />
                </Col>
              </Row>
              <Row>
                <Col className="mb-4">
                  <CurrencyConverter data={this.state.data} />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              {whatRoleIsThis() === "Customer" ? (
                <LinkToBroker
                  data={this.state.data}
                  linkToBroker={this.state.LinkToBroker}
                  changeBrokerModal={this.state.ChangeBrokerModal}
                  toggleModal={this.toggleModal}
                  resetModal={this.resetModal}
                  fetchUserProfile={this.fetchUserProfile}
                  refreshUserProfile={this.refreshUserProfile}
                />
              ) : (
                <Newsletter />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <BankAccountDetails
                data={this.state.data}
                bank={this.state.bank}
                bankAccountDetails={this.state.BankAccountDetails}
                toggleModal={this.toggleModal}
                resetModal={this.resetModal}
                fetchUserProfile={this.fetchUserProfile}
                refreshUserProfile={this.refreshUserProfile}
                refreshBankDetails={this.refreshBankDetails}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default MyAccount;
