var transactionHistory = [
  /* {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "EWR",
    status: "Completed",
    action: "View Details",
  },
  {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "Debit Card",
    status: "Completed",
    action: "View Details",
  },
  {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "Debit Card",
    status: "Completed",
    action: "View Details",
  },
  {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "Debit Card",
    status: "Completed",
    action: "View Details",
  },
  {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "EWR",
    status: "Pending",
    action: "View Details",
  },
  {
    date: "09-08-2020",
    customer_name: "Yussuf Musa",
    customer_id: "GEZAWA/CUS/00257",
    transaction_id: "GEZAWA/DEL/001",
    amount: "10,000",
    service_fee: "100",
    mode_of_deposit: "EWR",
    status: "Pending",
    action: "View Details",
  }, */
];

export default transactionHistory;
