import React, { Component } from "react";
import { Card, Col, Container, Input, Label, Row } from "reactstrap";
import Pagination from "../Market/Pagination";
import KycTable from "./KycTable";
import "./kyc.css";
import customerDetails from "../../dummyData/customerDetails";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class Kyc extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      data: [],
      startDate: new Date("1980-01-01T00:00:00.000Z"),
      endDate: new Date(),
      commoditySelect: "",
      marketSelect: "",
      tradeSideSelect: "",
      statusSelect: "",
      customerID: "",
      customerName: "",
      currentPage: 1,
      postsPerPage: 5,
      loading: false,
      errorMessage: "",
    };
  }

  async handleChange(event) {
    event.preventDefault();
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  async search() {
    const token = cookie.get("token");
    try {
      const response = await axios.get(
        `https://server.gcmx.bluetag.tech/api/UserManager/GetRegisteredCustomerByBroker?StartDate=${this.state.startDate}&EndDate=${this.state.endDate}&Status=${this.state.statusSelect}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ data: response.data });
      } else {
        //console.log("Failure");
        this.setState({ errorMessage: "Failed" });
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.search();
  }

  handleStatusChange = (e) => {
    this.setState({ statusSelect: e.target.value });
  };

  static contextType = AppContext;

  render() {
    const { data, currentPage, postsPerPage } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const { toggleDarkMode } = this.context;

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    return (
      <>
        <Container
          fluid
          className={toggleDarkMode ? "bg-dark mainPage" : "bg-light mainPage"}
        >
          <Row className="justify-content-start">
            <Col lg={4}>
              <h4
                style={{
                  color: toggleDarkMode ? "white" : null,
                }}
              >
                KYC
              </h4>
            </Col>
          </Row>
          <Card className={toggleDarkMode ? "search-bar dark" : "search-bar"}>
            <Row>
              <Col md={4}>
                <Label for="orderStatus">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Status
                  </span>
                </Label>
                <br />
                <select
                  name="orderStatus"
                  id="orderStatus"
                  value={this.state.statusSelect}
                  onChange={this.handleStatusChange}
                  className={
                    toggleDarkMode
                      ? "dropdown-custom dark full-length"
                      : "dropdown-custom full-length"
                  }
                >
                  <option value="All">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </Col>
              <Col md={4}>
                <Label for="startDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Start Date
                  </span>
                </Label>

                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
              <Col md={4}>
                <Label for="endDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    End Date
                  </span>
                </Label>

                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
            </Row>
            <br />

            <Row className="justify-content-end">
              <Col md={2} style={{ fontFamily: "Poppins" }}>
                <button
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                  onClick={this.fetchInstructionHistory}
                >
                  Reset
                </button>
              </Col>
              <Col
                md={2}
                style={{
                  fontFamily: "Poppins",
                }}
              >
                <button
                  className={
                    toggleDarkMode ? "button-search dark" : "button-search"
                  }
                  onClick={this.search}
                >
                  Search
                </button>
              </Col>
            </Row>
          </Card>
          <Card style={toggleDarkMode ? { backgroundColor: "black" } : null}>
            <KycTable data={currentPosts} />
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
            />
            <br />
          </Card>
        </Container>
      </>
    );
  }
}

export default Kyc;
