var priceData = [
  {
    commodity: "Rice-PADDY",
    commodity_id: "R1-PADDY",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "10,000.00",
    second_price: "24.10",
  },
  {
    commodity: "Rice-PADDY",
    commodity_id: "R1-PADDY",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice-PADDY",
    commodity_id: "R1-PADDY",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice-PADDY",
    commodity_id: "R1-PADDY",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice",
    commodity_id: "R1",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice",
    commodity_id: "R1",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice",
    commodity_id: "R1",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Rice",
    commodity_id: "R1",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-WYM",
    commodity_id: "YM-WYM",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-WYM",
    commodity_id: "YM-WYM",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-WYM",
    commodity_id: "YM-WYM",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-WYM",
    commodity_id: "YM-WYM",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-SYM",
    commodity_id: "YM-SYM",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-SYM",
    commodity_id: "YM-SYM",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-SYM",
    commodity_id: "YM-SYM",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize-SYM",
    commodity_id: "YM-SYM",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize",
    commodity_id: "YM",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize",
    commodity_id: "YM",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize",
    commodity_id: "YM",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "yellow maize",
    commodity_id: "YM",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Millet",
    commodity_id: "Millet",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Millet",
    commodity_id: "Millet",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Millet",
    commodity_id: "Millet",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Millet",
    commodity_id: "Millet",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Beans",
    commodity_id: "Beans",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Beans",
    commodity_id: "Beans",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Beans",
    commodity_id: "Beans",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Beans",
    commodity_id: "Beans",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Wheat",
    commodity_id: "Wheat",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Wheat",
    commodity_id: "Wheat",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Wheat",
    commodity_id: "Wheat",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Wheat",
    commodity_id: "Wheat",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Corn",
    commodity_id: "Corn",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Corn",
    commodity_id: "Corn",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Corn",
    commodity_id: "Corn",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Corn",
    commodity_id: "Corn",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sorghum",
    commodity_id: "Sorghum",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sorghum",
    commodity_id: "Sorghum",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sorghum",
    commodity_id: "Sorghum",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sorghum",
    commodity_id: "Sorghum",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sesame Seeds",
    commodity_id: "SS",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sesame Seeds",
    commodity_id: "SS",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sesame Seeds",
    commodity_id: "SS",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Sesame Seeds",
    commodity_id: "SS",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Soya Beans",
    commodity_id: "SB",
    grade: "1",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Soya Beans",
    commodity_id: "SB",
    grade: "2",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Soya Beans",
    commodity_id: "SB",
    grade: "3",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
  {
    commodity: "Soya Beans",
    commodity_id: "SB",
    grade: "4",
    _24_hr_change: "0.00",
    _24_hr_high: "0.00",
    _24_hr_low: "0.00",
    price: "0.00",
    second_price: "0.00",
  },
];

export default priceData;
