import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import "./Spots.css";
import sortedData from "../../dummyData/candlestickData";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
  elderRay,
  ema,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  ElderRaySeries,
  LineSeries,
  MovingAverageTooltip,
  OHLCTooltip,
  SingleValueTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  withDeviceRatio,
  withSize,
} from "react-financial-charts";
import { AppContext } from "../../AppContext/AppContext";

const PriceChart = ({ fullscreen, setFullscreen, product }) => {
  //const [fullscreen, setFullscreen] = useState(false);
  const myChart = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const ScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d) => new Date(d.date)
    );

  const viewportWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const viewportHeight =
    window.innerHeight > 0 ? window.innerHeight : window.screen.height;

  //console.log(document.getElementById("myChart"));

  const height = fullscreen ? 0.95 * viewportHeight : 535;
  const width = fullscreen ? 0.95 * viewportWidth : containerWidth - 50;
  const margin = { left: 0, right: 50, top: 20, bottom: 0 };

  const elder = elderRay();

  const { data, xScale, xAccessor, displayXAccessor } =
    ScaleProvider(sortedData);
  const pricesDisplayFormat = format(".2f");
  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max + 5];

  const gridHeight = height - margin.top - margin.bottom;

  const elderRayHeight = 100;
  const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
  const barChartHeight = gridHeight / 4;
  const barChartOrigin = (_, h) => [0, h - barChartHeight - elderRayHeight];
  const chartHeight = gridHeight - elderRayHeight;
  const yExtents = (data) => {
    return [data.high, data.low];
  };
  const dateTimeFormat = "%Y-%m-%d %H:%M:%S";
  const timeDisplayFormat = timeFormat(dateTimeFormat);
  const xDisplayFormat = timeFormat("%d %b");

  const ema12 = ema()
    .id(1)
    .options({ windowSize: 12 })
    .merge((d, c) => {
      d.ema12 = c;
    })
    .accessor((d) => d.ema12);

  const ema26 = ema()
    .id(2)
    .options({ windowSize: 26 })
    .merge((d, c) => {
      d.ema26 = c;
    })
    .accessor((d) => d.ema26);

  const barChartExtents = (data) => {
    return data.volume;
  };

  const candleChartExtents = (data) => {
    return [data.high, data.low];
  };

  const yEdgeIndicator = (data) => {
    return data.close;
  };

  const volumeColor = (data) => {
    return data.close > data.open
      ? "rgba(38, 166, 154, 0.3)"
      : "rgba(239, 83, 80, 0.3)";
  };

  const volumeSeries = (data) => {
    return data.volume;
  };

  const openCloseColor = (data) => {
    return data.close > data.open ? "#26a69a" : "#ef5350";
  };

  const getChartSize = () => {
    const newWidth = myChart.current.offsetWidth;
    setContainerWidth(newWidth);
  };

  useEffect(() => {
    getChartSize();
  }, [containerWidth]);

  const { toggleDarkMode } = useContext(AppContext);

  return (
    <div
      style={
        fullscreen
          ? {
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10000,
              backgroundColor: "white",
              paddingLeft: 15,
            }
          : null
      }
    >
      <Card
        style={{
          backgroundColor: toggleDarkMode ? "black" : "",
          color: toggleDarkMode ? "white" : "",
        }}
      >
        <div className="card-body" ref={myChart}>
          <div className="row justify-content-between">
            {/* {fullscreen ? (
              <span style={{ fontWeight: 700, fontSize: 18 }}>
                {product ? product.commodity : "--"}
              </span>
            ) : null} */}
            <div className="col-7">
              {fullscreen ? (
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 18,
                    display: "inline",
                    color: toggleDarkMode ? "white" : "",
                  }}
                >
                  {product ? product.commodity : "--"}
                </span>
              ) : null}
              <FormGroup>
                <Label for="country">
                  <span style={{ color: toggleDarkMode ? "#e8e8e8" : "" }}>
                    Interval
                  </span>{" "}
                </Label>{" "}
                <select
                  className={
                    toggleDarkMode ? "select-options dark" : "select-options"
                  }
                  name="time-interval"
                  id="time-interval"
                >
                  <option value="">15m</option>
                  <option value="">1H</option>
                  <option value="">4H</option>
                  <option value="">1D</option>
                  <option value="">1W</option>
                </select>
              </FormGroup>
            </div>
            <div className="col-1" style={{ alignItems: "right" }}>
              {fullscreen ? (
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: toggleDarkMode ? "#495057" : "",
                  }}
                  onClick={() => setFullscreen(false)}
                >
                  <i className="fas fa-compress-alt fullscreen-button"></i>
                </button>
              ) : (
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: toggleDarkMode ? "#495057" : "",
                  }}
                  onClick={() => setFullscreen(true)}
                >
                  <i className="fas fa-expand-alt fullscreen-button"></i>
                </button>
              )}
            </div>
          </div>
          <div style={fullscreen ? { alignItems: "center" } : null}>
            <ChartCanvas
              height={height}
              ratio={3}
              width={width}
              margin={margin}
              data={data}
              displayXAccessor={displayXAccessor}
              seriesName="Data"
              xScale={xScale}
              xAccessor={xAccessor}
              xExtents={xExtents}
              zoomAnchor={lastVisibleItemBasedZoomAnchor}
            >
              <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
                <XAxis
                  showGridLines
                  showTickLabel={true}
                  strokeStyle={toggleDarkMode ? "white" : "black"}
                  tickStrokeStyle={toggleDarkMode ? "white" : "black"}
                  tickLabelFill={toggleDarkMode ? "white" : "black"}
                />
                <YAxis
                  showGridLines
                  tickFormat={pricesDisplayFormat}
                  strokeStyle={toggleDarkMode ? "white" : "black"}
                  tickStrokeStyle={toggleDarkMode ? "white" : "black"}
                  tickLabelFill={toggleDarkMode ? "white" : "black"}
                />
                <CandlestickSeries />
                <LineSeries
                  yAccessor={ema26.accessor()}
                  strokeStyle={ema26.stroke()}
                />
                <CurrentCoordinate
                  yAccessor={ema26.accessor()}
                  fillStyle={ema26.stroke()}
                />
                <LineSeries
                  yAccessor={ema12.accessor()}
                  strokeStyle={ema12.stroke()}
                />
                <CurrentCoordinate
                  yAccessor={ema12.accessor()}
                  fillStyle={ema12.stroke()}
                />
                <MouseCoordinateY
                  rectWidth={margin.right}
                  displayFormat={pricesDisplayFormat}
                />
                <MouseCoordinateX displayFormat={timeDisplayFormat} />
                <EdgeIndicator
                  itemType="last"
                  rectWidth={margin.right}
                  fill={openCloseColor}
                  lineStroke={openCloseColor}
                  displayFormat={pricesDisplayFormat}
                  yAccessor={yEdgeIndicator}
                />
                <OHLCTooltip
                  origin={[8, 16]}
                  labelFill="white"
                  textFill="white"
                />
              </Chart>
              <CrossHairCursor />
            </ChartCanvas>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PriceChart;
