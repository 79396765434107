import React, { Component } from "react";
import { Card, Input, Label, Container, Row, Col } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";

import InstructionHistoryTable from "./InstructionHistoryTable";
import "./instruction.css";

import Pagination from "../Market/Pagination";
import { whatRoleIsThis } from "../../services/auth.service";

import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class InstructionHistory extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      data: [],
      marketData: [],
      startDate: new Date("1980-01-01T00:00:00.000Z"),
      endDate: new Date(),
      startDateView: new Date("1980-01-01T00:00:00.000Z"),
      endDateView: new Date(),
      commoditySelect: "",
      marketSelect: "",
      tradeSideSelect: "",
      statusSelect: "",
      customerID: "",
      customerName: "",
      currentPage: 1,
      postsPerPage: 5,
      loading: false,
    };
  }

  handleDateRangeChange = (event, picker) => {
    this.setState({ startDate: picker.startDate, endDate: picker.endDate });
  };
  handleCommodityChange = (e) => {
    this.setState({ commoditySelect: e.target.value });
    // //console.log("i changed on click");
  };
  handleMarketChange = (e) => {
    this.setState({ marketSelect: e.target.value });
  };

  handleTradeSideChange = (e) => {
    this.setState({ tradeSideSelect: e.target.value });
  };
  handleStatusChange = (e) => {
    this.setState({ statusSelect: e.target.value });
  };

  search = () => {
    const token = cookie.get("token");
    this.setState({ loading: true, data: [] });

    if (whatRoleIsThis() === "Broker") {
      const params = JSON.stringify({
        Date: this.state.startDate,
        //EndDate: this.state.endDate,
        CustomerName: this.state.customerName,
        CustomerID: this.state.customerID,
      });
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params,
      };
      axios
        .get(
          `https://server.gcmx.bluetag.tech/api/Transaction/InstructionHistoryByParameterForBroker?CustomerName=${
            this.state.customerName
          }&CustomerID=${
            this.state.customerID
          }&StartDate=${this.state.startDate.toJSON()}&EndDate=${this.state.endDate.toJSON()}&Status=${
            this.state.statusSelect
          }`,
          config
        )
        .then((res) => {
          //var result = JSON.parse(res.data);
          //console.log(res);
          this.setState({
            startDateView: this.state.startDate,
            endDateView: this.state.endDate,
            data: res.data,
          });
        })
        .catch(/*console.log*/);
    }

    if (whatRoleIsThis() === "Customer") {
      const params = {
        Date: this.state.startDate,
        //EndDate: this.state.endDate,
        CommodityID: this.state.commoditySelect,
        MarketType: this.state.marketSelect,
        TradeSide: this.state.tradeSideSelect,
        //OrderStatus: this.state.statusSelect,
      };
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      axios
        .get(
          `https://server.gcmx.bluetag.tech/api/Transaction/InstructionHistoryByParameterForCustomer?CommodityID=${
            this.state.commoditySelect
          }&MarketType=${this.state.marketSelect}&TradeSide=${
            this.state.tradeSideSelect
          }&StartDate=${this.state.startDate.toJSON()}&EndDate=${this.state.endDate.toJSON()}&Status=${
            this.state.statusSelect
          }`,
          config
        )
        .then((res) => {
          //console.log(res);
          this.setState({
            startDateView: this.state.startDate,
            endDateView: this.state.endDate,
            data: res.data,
          });
        })
        .catch(/*console.log*/);
    }
  };

  async fetchInstructionHistory() {
    const token = cookie.get("token");

    try {
      const response = await axios.get(
        "https://server.gcmx.bluetag.tech/api/Transaction/InstructionHistory",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ data: response.data });
        //console.log(JSON.stringify(response.data));
      } else {
        //console.log("Failure");
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  async fetchMarketInfo() {
    const token = cookie.get("token");

    try {
      const response = await axios.get(
        `https://server.gcmx.bluetag.tech/api/Transaction/Market`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        //console.log("Success");
        this.setState({ marketData: response.data });
        //console.log(JSON.stringify(response.data));
      } else {
        //console.log("Failure");
        let error = new Error(response.statusText);
        // @ts-ignore
        error.response = response;
        throw error;
      }
    } catch (error) {
      //console.error(
      //   "You have an error in your code or there are Network issues.",
      //   error
      // );
    }
  }

  componentDidMount() {
    this.fetchInstructionHistory();
    this.fetchMarketInfo();
  }

  async handleChange(event) {
    event.preventDefault();
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  static contextType = AppContext;

  render() {
    const { data, currentPage, postsPerPage } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const { toggleDarkMode } = this.context;

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    return (
      <>
        <Container
          fluid
          className={toggleDarkMode ? "bg-dark mainPage" : "bg-light mainPage"}
        >
          <Row>
            <Col lg={4}>
              <h4
                style={{
                  color: toggleDarkMode ? "white" : null,
                }}
              >
                Instruction History
              </h4>
            </Col>
            {whatRoleIsThis() === "Broker" ? (
              <Col lg={2}>
                <Input type="checkbox" name="customers" id="customers" />
                <Label for="customers">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    All customers
                  </span>
                </Label>
              </Col>
            ) : (
              <Col lg={2}></Col>
            )}
            <Col lg={2}>
              <Input type="checkbox" name="hideCanceled" id="hideCanceled" />
              <Label for="forCanceled">
                <span
                  style={{
                    color: toggleDarkMode ? "white" : null,
                  }}
                >
                  Hide all canceled
                </span>
              </Label>
            </Col>
            <Col lg={2.5}>
              <a href="#">
                <i className="fas fa-download"></i>{" "}
                <Label>
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Export complete order history
                  </span>
                </Label>
              </a>
            </Col>
          </Row>
          <Card className={toggleDarkMode ? "search-bar dark" : "search-bar"}>
            {/* <Col lg={12}> */}
            <Row>
              {/* <Col lg={5}>
                  <Label for="customerName">Customer Name</Label>
                  <Input
                    type="text"
                    name="customerName"
                    id="customerName"
                    onChange={this.handleChange}
                    placeholder="Enter Customer Name"
                  />
                </Col>
                <Col lg={4}>
                  <Label for="customerID">Customer ID</Label>
                  <Input
                    type="text"
                    name="customerID"
                    id="customerID"
                    onChange={this.handleChange}
                    placeholder="Enter Customer ID"
                  />
                </Col> */}
              <Col md={4}>
                <Label for="orderStatus">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Status
                  </span>
                </Label>
                <br />
                <select
                  name="orderStatus"
                  id="orderStatus"
                  value={this.state.statusSelect}
                  onChange={this.handleStatusChange}
                  className={
                    toggleDarkMode
                      ? "dropdown-custom dark full-length"
                      : "dropdown-custom full-length"
                  }
                >
                  <option value="All">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </Col>
              <Col md={4}>
                <Label for="startDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    Start Date
                  </span>
                </Label>
                {/* <DateRangePicker
                      initialSettings={{
                        startDate: "01/12/2021",
                        endDate: "12/25/2021",
                      }}
                      onApply={this.handleDateRangeChange}
                    >
                      <button
                        className="btn-block btn-datePick"
                        style={{ height: "38px" }}
                      >
                        Start Date-End Date
                        <i
                          className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                          style={{ color: "#24B086" }}
                        />
                      </button>
                    </DateRangePicker> */}
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
              <Col md={4}>
                <Label for="endDate">
                  <span
                    style={{
                      color: toggleDarkMode ? "white" : null,
                    }}
                  >
                    End Date
                  </span>
                </Label>
                {/* <DateRangePicker
                      initialSettings={{
                        startDate: "01/12/2021",
                        endDate: "12/25/2021",
                      }}
                      onApply={this.handleDateRangeChange}
                    >
                      <button
                        className="btn-block btn-datePick"
                        style={{ height: "38px" }}
                      >
                        Start Date-End Date
                        <i
                          className="fa fa-calendar ml-2 d-none d-lg-inline-flex"
                          style={{ color: "#24B086" }}
                        />
                      </button>
                    </DateRangePicker> */}
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  onChange={this.handleChange}
                  style={
                    toggleDarkMode
                      ? {
                          backgroundColor: "#495057",
                          color: "white",
                        }
                      : null
                  }
                />
              </Col>
            </Row>
            <br />
            {whatRoleIsThis() === "Broker" ? (
              <Row className="justify-content-end">
                <Col md={2} style={{ fontFamily: "Poppins" }}>
                  <button
                    className={
                      toggleDarkMode ? "button-search dark" : "button-search"
                    }
                    onClick={this.fetchInstructionHistory}
                  >
                    Reset
                  </button>
                </Col>
                <Col
                  md={2}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  <button
                    className={
                      toggleDarkMode ? "button-search dark" : "button-search"
                    }
                    onClick={this.search}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col>
                    <Label>
                      <span
                        style={{
                          color: toggleDarkMode ? "white" : null,
                        }}
                      >
                        Commodity
                      </span>
                    </Label>
                    <div>
                      <select
                        className={
                          toggleDarkMode
                            ? "select-options dark"
                            : "select-options"
                        }
                        value={this.state.commoditySelect}
                        onChange={this.handleCommodityChange}
                      >
                        <option value="All">All</option>
                        <option value="R1">Rice</option>
                        <option value="R1-PADDY">Rice-PADDY</option>
                        <option value="Millet">Millet</option>
                        <option value="Beans">Beans</option>
                        <option value="Wheat">Wheat</option>
                        <option value="Corn">Corn</option>
                        <option value="Sorghum">Sorghum</option>
                        <option value="SB">Soya Beans</option>
                        <option value="SS">Sesame Seeds</option>
                        <option value="Maize">Maize</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <Label>
                      <span
                        style={{
                          color: toggleDarkMode ? "white" : null,
                        }}
                      >
                        Market
                      </span>
                    </Label>
                    <div>
                      <select
                        className={
                          toggleDarkMode
                            ? "select-options dark"
                            : "select-options"
                        }
                        value={this.state.marketSelect}
                        onChange={this.handleMarketChange}
                      >
                        <option value="All">All</option>
                        <option value="Spot Market">Spot Market</option>
                        <option value="Futures Market">Futures Market</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <Label>
                      <span
                        style={{
                          color: toggleDarkMode ? "white" : null,
                        }}
                      >
                        Trade Side
                      </span>
                    </Label>
                    <div>
                      <select
                        className={
                          toggleDarkMode
                            ? "select-options dark"
                            : "select-options"
                        }
                        value={this.state.tradeSideSelect}
                        onChange={this.handleTradeSideChange}
                      >
                        <option value="All">All</option>
                        <option value="Sell">Sell</option>
                        <option value="Buy">Buy</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2} style={{ fontFamily: "Poppins" }}>
                    <button
                      className={
                        toggleDarkMode ? "button-search dark" : "button-search"
                      }
                      onClick={this.fetchInstructionHistory}
                    >
                      Reset
                    </button>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      fontFamily: "Poppins",
                    }}
                  >
                    <button
                      className={
                        toggleDarkMode ? "button-search dark" : "button-search"
                      }
                      onClick={this.search}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </>
            )}
          </Card>
          <Card style={toggleDarkMode ? { backgroundColor: "black" } : null}>
            <InstructionHistoryTable
              data={currentPosts}
              marketInfo={this.state.marketData}
            />
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
            />
            <br />
          </Card>
        </Container>
      </>
    );
  }
}
// class InstructionHistory extends Component {
//   getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/admin") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             component={prop.component}
//             key={key}
//           />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   render() {

//   }
// }

export default InstructionHistory;
