import React, { Component } from "react";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Row, Col } from "reactstrap";

import "../css/MyAccount.css";
import axios from "axios";
import cookie from "js-cookie";
import { AppContext } from "../../AppContext/AppContext";

class CurrencyOption extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.secondHandleChange = this.secondHandleChange.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.convertCurrency = this.convertCurrency.bind(this);
        this.state = {
            data: [],
            currency: null,
            currencyEquivalent: null,   
            selectedCurrency: "NGN", 
            secondSelectedCurrency: "NGN" 
        };
    }
    
    async secondHandleChange(event) {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleChange(event) {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeCurrency = (e) => {
        this.setState({
            currency: e.target.value
        })
    }

    async convertCurrency(){
        try {
            const response = await axios.get(
                `https://server.gcmx.bluetag.tech/api/Common/CurrencyConverter?currenctFrom=${this.state.selectedCurrency}&currencyTo=${this.state.secondSelectedCurrency}&amount=${this.state.currency}`
            );

            if (response.status === 200) {
                //console.log("Success");
                this.setState({currencyEquivalent: response.data})
                
            } else {
                //console.log("Failure");
                this.setState({ errorMessage: "Failed" });
                let error = new Error(response.statusText);
                // @ts-ignore
                error.response = response;
                throw error;
            }
        } catch (error) {
            this.setState({ errorMessage: "Failed" });
            /* console.error(
              "You have an error in your code or there are Network issues.",
              error
            ); */
        }
    }
    
    async componentDidMount() {
        const token = cookie.get("token");
        try {
            const response = await axios.get(
                "https://server.gcmx.bluetag.tech/api/Transaction/CurrencyList",
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );

            if (response.status === 200) {
                //console.log("Success");
                this.setState({ data: response.data });
            } else {
                //console.log("Failure");
                this.setState({ errorMessage: "Failed" });
                let error = new Error(response.statusText);
                // @ts-ignore
                error.response = response;
                throw error;
            }
        } catch (error) {
            this.setState({ errorMessage: "Failed" });
            /* console.error(
              "You have an error in your code or there are Network issues.",
              error
            ); */
        }
    }

    static contextType = AppContext;

    render() {
        const { toggleDarkMode } = this.context;
        const currencyPlaceholder = this.state.selectedCurrency ? this.state.selectedCurrency : "NGN"
        const secondCurrencyPlaceholder = this.state.secondSelectedCurrency ? this.state.secondSelectedCurrency : "NGN"

        return (
            <>
                <Card className={toggleDarkMode ? "dark" : ""} id="currency-option">
                    <span className={toggleDarkMode ? "cardHeader dark" : "cardHeader"}>
                        <Row>
                            <Col>Currency Converter</Col>
                            <Col className="col-2">
                                <i
                                    className={
                                        toggleDarkMode ? "far fa-edit dark" : "far fa-edit"
                                    }
                                ></i>
                            </Col>
                        </Row>
                    </span>
                    <div
                        className={toggleDarkMode ? "card-content dark" : "card-content"}
                    >
                        <Row>
                            <Col className="col-6">
                                <select
                                    name="selectedCurrency"
                                    value={this.state.selectedCurrency}
                                    onChange={this.handleChange}
                                    className="dropdown-custom"
                                    style={
                                        toggleDarkMode
                                            ? {
                                                backgroundColor: "#495057",
                                                color: "white",
                                            }
                                            : null
                                    }
                                >
                                    {this.state.data.map((d) => (
                                        <option key={d.name} value={d.name}>
                                            {d.name}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col className="col-6">
                                <select
                                    name="secondSelectedCurrency"
                                    value={this.state.secondSelectedCurrency}
                                    onChange={this.secondHandleChange}
                                    className="dropdown-custom"
                                    style={
                                        toggleDarkMode
                                            ? {
                                                backgroundColor: "#495057",
                                                color: "white",
                                            }
                                            : null
                                    }
                                >
                                    {this.state.data.map((d) => (
                                        <option key={d.name} value={d.name}>
                                            {d.name}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="col-6">
                                <input
                                    type="number"
                                    placeholder={`Enter value in ${currencyPlaceholder}`}
                                    className="form-control textbox-custom"
                                    value={this.state.currency}
                                    onChange={this.onChangeCurrency}
                                    style={
                                        toggleDarkMode
                                            ? {
                                                backgroundColor: "#495057",
                                                color: "white",
                                            }
                                            : null
                                    }
                                />
                            </Col>
                            <Col className="col-6">
                                <input
                                    type="number"
                                    disabled
                                    placeholder={`Equivalent in ${secondCurrencyPlaceholder}`}
                                    className="form-control textbox-custom"
                                    value={this.state.currencyEquivalent}
                                    style={
                                        toggleDarkMode
                                            ? {
                                                backgroundColor: "#495057",
                                                color: "white",
                                            }
                                            : null
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="row ml-6">
                            <div className="col-2 offset-8 currency-save">
                                <button
                                    className="btn button-default"
                                    id="currency-convert"
                                    onClick={() => this.convertCurrency()}
                                >
                                    <span style={{ fontSize: "14px" }}>Convert</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        );
    }
}

export default CurrencyOption;
