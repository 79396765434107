/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Row, Container, Col, NavLink } from "reactstrap";
import "./overview.css";
import SpotTable from "../spotmarket/SpotTable";
// @ts-ignore
import { AppContext } from "AppContext/AppContext";
import Spinner from "reactstrap/lib/Spinner";

const CustomerWallet = (props) => {
  const { customersWalletSummary, customersWallet, toggleDarkMode } =
    useContext(AppContext);
  const [consolidated, setconsolidated] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    // //console.log(customersWalletSummary);
    //console.log(customersWallet);
    if (customersWalletSummary != null) {
      setloading(false);
      //console.log(customersWalletSummary);
      const arr = customersWalletSummary?.map((e) => {
        return {
          name: e.customer,
          customer_id: e.customer_id,
          spot: "Spot Market",
          future: "Future Market",
          amountNaira: e.spot_market,
          amountDollar: e.futures_market,
        };
      });
      setconsolidated(arr);
    }
  }, [customersWalletSummary, customersWallet, loading]);

  const viewDetails = (data) => {
    //console.log(data);
    props.history.push({
      pathname: "/admin/customer-wallet-details",
      customer: data,
    });
  };

  // const { consolidated } = state;
  return (
    <>
      <Container
        fluid
        className={toggleDarkMode ? "bg-dark pb-5" : "bg-light pb-5"}
      >
        <Row>
          <h3
            className={
              toggleDarkMode ? "page-title dark ml-3" : "page-title ml-3"
            }
          >
            Customers Wallet
          </h3>
          <p
            className={
              toggleDarkMode
                ? "page-title export ml-auto mr-4 dark"
                : "page-title export ml-auto mr-4"
            }
          >
            <i className="fa fa-download mr-1" />
            Export
          </p>
          <div className="col-12 mb-5 mb-xl-0">
            <Card
              className={
                toggleDarkMode ? "overview-card dark" : "overview-card"
              }
            >
              <small className="overview-card-title">Estimated Balance</small>
              <Row>
                <Col md="9">
                  <p>
                    <span
                      className="balance-naira"
                      style={toggleDarkMode ? { color: "white" } : null}
                    >
                      ₦ {customersWallet?.estimated_balance} |{" "}
                    </span>{" "}
                    <span
                      className="balance-dollar"
                      style={toggleDarkMode ? { color: "white" } : null}
                    >
                      ${customersWallet?.futures_market}
                    </span>
                  </p>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="col-12 mt-4 mb-5">
            <div
              className={
                toggleDarkMode
                  ? "overview-card dark overflow p-0"
                  : "overview-card overflow p-0"
              }
            >
              <p
                className="overview-card-title ob-1 p-24"
                style={toggleDarkMode ? { color: "white" } : null}
              >
                Consolidated
              </p>
              {!loading ? (
                <table className="table">
                  <tbody>
                    {consolidated.length != 0 ? (
                      <>
                        {consolidated.map((d, idx) => (
                          <tr key={idx}>
                            <th scope="row">{d.name}</th>
                            <td>{d.customer_id}</td>
                            <td>{d.spot}</td>
                            <td>{d.future}</td>
                            <td>
                              N{d.amountNaira} |$ {d.amountDollar}
                            </td>
                            <td>
                              <div className="dropdown dropdown-action text-right">
                                <a
                                  href="#"
                                  className="action-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  Action
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname:
                                        "/admin/customer-wallet-details",
                                      state: { customer: d },
                                    }}
                                  >
                                    {" "}
                                    View Details
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: "/",
                                      state: { customer: d },
                                    }}
                                  >
                                    {" "}
                                    View Profile
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <td className="text-center pb-2">
                        <p>No Data</p>
                      </td>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center pb-3">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CustomerWallet;
