import { AppContext } from "../../AppContext/AppContext";
import { nodeName } from "jquery";
import React, { useContext, useState } from "react";
import { Table } from "reactstrap";
import { whatRoleIsThis } from "../../services/auth.service";
import GINModal from "./GINModal";

export default function OrderHistoryTable({ data }) {
  const [showInfo, setShowInfo] = useState(false);
  const [theKey, setTheKey] = useState();
  const [toggle, setToggle] = useState(false);
  const { toggleDarkMode } = useContext(AppContext);
  const print = (string) => {
    //console.log(string);
  };
  return (
    <>
      <Table responsive>
        <thead
          className={
            toggleDarkMode
              ? "bg-dark text-transform-lowercase"
              : "bg-light text-transform-lowercase"
          }
          style={{
            fontFamily: "Poppins",
            color: toggleDarkMode ? "white" : null,
          }}
        >
          <tr>
            <th>Date</th>
            {whatRoleIsThis() === "Broker" ? (
              <>
                <th>Customer Name</th>
                <th>Customer ID</th>
              </>
            ) : null}
            <th>Instruction ID</th>
            {whatRoleIsThis() === "Broker" ? (
              <th>Commodities(kg)</th>
            ) : (
              <>
                {" "}
                <th>Commodities</th>
                <th>UoM</th>{" "}
              </>
            )}
            {whatRoleIsThis() === "Broker" ? <th>Grade</th> : null}
            <th>Market</th>
            <th>Trade side</th>
            <th>Quantity</th>
            <th>Price(NGN)</th>
            <th>Amount</th>
            <th>Executed</th>
            <th>GIN</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody
          className={toggleDarkMode ? null : "bg-light"}
          style={{
            color: toggleDarkMode ? "white" : null,
            backgroundColor: toggleDarkMode ? "black" : null,
          }}
        >
          {/* <tr style={{ fontFamily: "Poppins" }}>
            <td>09-08-2020</td>
            {whatRoleIsThis() === "Broker" ? (
              <>
                <td>Onyedikachi Ugwu</td>
                <td>GEZAWA/CUS/00257</td>
              </>
            ) : null}
            <td>GEZAWA/CUS/00257/INS/SELL/001</td>
            {whatRoleIsThis() === "Broker" ? (
              <td>Rice</td>
            ) : (
              <>
                {" "}
                <th>Rice</th>
                <th>Tonnes</th>{" "}
              </>
            )}
            {whatRoleIsThis() === "Broker" ? <td>A</td> : null}
            <td>Spot</td>
            <td className={"Sell" == "Sell" ? "text-danger" : "text-success"}>
              Sell
            </td>
            <td className="text-danger">20,000</td>
            <td>350</td>

            <td>7,000,000</td>
            <td>20,000</td>
            <td style={{ textAlign: "center" }}>
              {"Completed" === "Completed" ? (
                <button
                  style={{
                    border: "none",
                    color: "#24b086",
                    backgroundColor: toggleDarkMode ? "black" : "white",
                  }}
                  onClick={() => setToggle(!toggle)}
                >
                  View
                </button>
              ) : (
                "-"
              )}
            </td>
            <td>
              Completed{" "}
              <button
                className="history-dropdown"
                onClick={() => {
                  setShowInfo(!showInfo);
                }}
              >
                {" "}
                <i className="fas fa-caret-down"></i>
              </button>
            </td>
          </tr>
          {showInfo ? (
            <tr>
              <td colSpan={whatRoleIsThis() === "Broker" ? 13 : 11}>
                <p className="dropdown-copy">
                  Hello Ade & Sons, please do not exceed the sell quantity of
                  20,000 tonnes for Rice.
                </p>
                <table className="dropdown-table">
                  <tr>
                    <th rowSpan={3}>Trading Total: NGN 7,000,000</th>
                    <td>Time</td>
                    <td>Trading Price</td>
                    <td>Executed</td>
                    <td>Transaction Fee(NGN)</td>
                    <td>Total</td>
                  </tr>
                  <tr>
                    <td>03:45pm</td>
                    <td>350</td>
                    <td>15,000</td>
                    <td>150</td>
                    <td>5,250,000</td>
                  </tr>
                  <tr>
                    <td>09:45am</td>
                    <td>350</td>
                    <td>5,000</td>
                    <td>50</td>
                    <td>1,750,000</td>
                  </tr>
                </table>
              </td>
            </tr>
          ) : null} */}
          {data.map((d, key) => (
            <>
              <tr style={{ fontFamily: "Poppins" }}>
                <td>{d.date}</td>
                {whatRoleIsThis() === "Broker" ? (
                  <>
                    <td>{d.customer_name}</td>
                    <td>{d.customer_id}</td>
                  </>
                ) : null}
                <td>{d.instruction_id}</td>
                {whatRoleIsThis() === "Broker" ? (
                  <td>{d.commodity}</td>
                ) : (
                  <>
                    {" "}
                    <th>{d.commodity}</th>
                    <th>{d.uom}</th>{" "}
                  </>
                )}
                {whatRoleIsThis() === "Broker" ? <td>{d.grade}</td> : null}
                <td>{d.market_type}</td>
                <td
                  className={
                    d.trade_side == "Sell" ? "text-danger" : "text-success"
                  }
                >
                  {d.trade_side}
                </td>
                <td className="text-danger">{d.quantity}</td>
                <td>{d.price}</td>

                <td>{d.amount}</td>
                <td>{d.quantity_fulfilled}</td>
                <td style={{ textAlign: "center" }}>
                  {d.status === "Completed" ? (
                    <button
                      style={{
                        border: "none",
                        color: "#24b086",
                        backgroundColor: toggleDarkMode ? "black" : "white",
                      }}
                      onClick={() => setToggle(!toggle)}
                    >
                      View
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {d.status}{" "}
                  <button
                    className="history-dropdown"
                    onClick={() => {
                      setShowInfo(!showInfo);
                      setTheKey(key);
                    }}
                  >
                    {" "}
                    <i className="fas fa-caret-down"></i>
                  </button>
                </td>
              </tr>
              {showInfo && theKey === key ? (
                <tr>
                  <td colSpan={whatRoleIsThis() === "Broker" ? 13 : 11}>
                    <p className="dropdown-copy">
                      Hello Ade & Sons, please do not exceed the sell quantity
                      of {d.quantity} tonnes for {d.commodity}.
                    </p>
                    <table className="dropdown-table">
                      <tr>
                        <th rowSpan={3}>Trading Total: NGN {d.amount}</th>
                        <td>Time</td>
                        <td>Trading Price</td>
                        <td>Executed</td>
                        <td>Transaction Fee(NGN)</td>
                        <td>Total</td>
                      </tr>
                      <tr>
                        <td>03:45pm</td>
                        <td>350</td>
                        <td>15,000</td>
                        <td>150</td>
                        <td>5,250,000</td>
                      </tr>
                      <tr>
                        <td>09:45am</td>
                        <td>350</td>
                        <td>5,000</td>
                        <td>50</td>
                        <td>1,750,000</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </tbody>
      </Table>
      <GINModal toggle={toggle} setToggle={setToggle} />
    </>
  );
}
