/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import logo from "../img/logo.svg";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { AppContext } from "../../AppContext/AppContext";

class AdminFooter extends React.Component {
  static contextType = AppContext;
  render() {
    const { toggleDarkMode } = this.context;
    return (
      <footer
        className="py-4 footer"
        style={{
          fontFamily: "Poppins",
          background: toggleDarkMode ? "#495057" : "#F0F0F0",
          color: toggleDarkMode ? "white" : "",
        }}
        expand="md"
      >
        <Col className="d-flex">
          <Col md="8">
            <Nav className=" justify-content-md-left">
              <NavItem>
                <NavLink
                  href="https://www.creative-tim.com?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  About Us
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.creative-tim.com/presentation?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Commodities
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="http://blog.creative-tim.com?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Services
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.creative-tim.com/presentation?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="4">
            <div className="copyright text-center text-xs-end justify-content-md-end">
              <img src={logo} />
            </div>
          </Col>
        </Col>
      </footer>
    );
  }
}

export default AdminFooter;
