import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import "./Spots.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASEURL } from "./baseUrl";
import { getToken } from "../../services/auth.service";
import { AppContext } from "../../AppContext/AppContext";

const CustomerTradeInstruction = ({ product, fetchInstruction }) => {
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [commodityQuantity, setCommodityQuantity] = useState();
  const { register, handleSubmit, errors, reset: buyReset } = useForm();
  const {
    register: sellRegister,
    handleSubmit: handleSellSubmit,
    reset: sellReset,
  } = useForm();

  const token = getToken();

  const { customersWallet, fetchCustomerWallet, toggleDarkMode } = useContext(AppContext);

  const fetchCommodityQuantity = () => {
    axios
      .get(
        `${BASEURL}/TotalQuantityForCommodityGrade?CommodityID=${product.commodity_id}&Grade=${product.grade}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        //console.log(res);
        setCommodityQuantity(res.data.quantity);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchCustomerWallet();
    fetchCommodityQuantity();
  }, [commodityQuantity]);

  const onBuySubmit = (data) => {
    data["commodity_id"] = product.commodity_id;
    data["grade"] = product.grade;
    //console.log(data);
    setLoading(true);
    axios
      .post(`${BASEURL}/BuyInstruction`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res);
        setMessage("Buy Instruction Sent");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        setLoading(false);
        fetchInstruction();
      })
      .catch((e) => {
        //console.log(e);
        setError(true);
      });
    buyReset();
  };

  const onSellSubmit = (data) => {
    data["commodity_id"] = product.commodity_id;
    data["grade"] = product.grade;
    //console.log(data);
    setSellLoading(true);
    axios
      .post(`${BASEURL}/SellInstruction`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        setMessage("Sell Instruction Sent");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        setSellLoading(false);
        fetchInstruction();
      })
      .catch((e) => {
        //console.log(e);
        setError(true);
      });
    sellReset();
  };

  //console.log(product.trade_side);
  //console.log(customersWallet)
  
  return (
    <div>
      {showAlert && (
        <div
          className={error ? "alert alert-danger" : "alert alert-success"}
          role="alert"
        >
          {message}
        </div>
      )}
      <Card
        className="mb-3 mb-xl-0"
        style={
          toggleDarkMode
            ? {
                color: "white",
                backgroundColor: "black",
              }
            : null
        }
      >
        <CardBody>
          <p
            style={{
              fontSize: "1.0em",
              fontWeight: 500,
              marginBottom: "3px",
              color: toggleDarkMode ? "white" : "",
            }}
          >
            Customer Trade Instruction
          </p>
          <div className="line"></div>
          <div className="container">
            <div className="row" style={{ marginRight: 0 }}>
              <div className="sm-6" style={{ marginRight: 25, width: "47%" }}>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "3px",
                    marginTop: 11,
                    color: toggleDarkMode ? "white" : "",
                  }}
                >
                  Instruct Broker to Buy
                </p>
                <form onSubmit={handleSubmit(onBuySubmit)}>
                  <div className="form-group">
                    <div className="customerBody">
                      <span
                        className="customerTitle"
                        style={{ color: toggleDarkMode ? "white" : "" }}
                      >
                        Buy {product.commodity} (NGN)
                      </span>
                      <span className="customerTitle">
                        {customersWallet?.estimated_balance
                          ? `${parseInt(customersWallet?.estimated_balance) * 10000}`
                          : "0"}{/* estimated balance is in kobo */}
                      </span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      className="MyformControl"
                      id="exampleInputEmail1"
                      placeholder="Price (NGN)"
                      aria-describedby="emailHelp"
                      ref={register({ required: "Field is required" })}
                      style={
                        toggleDarkMode
                          ? {
                              color: "white",
                              backgroundColor: "#495057",
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="quantity"
                      placeholder="Quantity (Tons)"
                      className="MyformControl"
                      id="exampleInputPassword1"
                      style={{
                        marginTop: -8,
                        color: toggleDarkMode ? "white" : "",
                        backgroundColor: toggleDarkMode ? "#495057" : "",
                      }}
                      ref={register({ required: "Field is required" })}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: -10 }}>
                    <label htmlFor="comment" className="customerTitle">
                      <span style={{ color: toggleDarkMode ? "white" : "" }}>
                        Comment
                      </span>
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        name="comment"
                        className="MyformControl"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        ref={register}
                        style={
                          toggleDarkMode
                            ? {
                                color: "white",
                                backgroundColor: "#495057",
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                  <button type="submit" className="submitBtn ">
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      " Submit Buy Instruction"
                    )}
                  </button>
                </form>
              </div>
              <div className="sm-6" style={{ marginLeft: 0, width: "47%" }}>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "3px",
                    marginTop: 11,
                    color: toggleDarkMode ? "white" : "",
                  }}
                >
                  Instruct Broker to Sell
                </p>
                <form onSubmit={handleSellSubmit(onSellSubmit)}>
                  <div className="form-group">
                    <div className="customerBody">
                      <span
                        className="customerTitle"
                        style={{ color: toggleDarkMode ? "white" : "" }}
                      >
                        Sell {product.commodity} (Tons)
                      </span>
                      <span className="customerTitle">{commodityQuantity}</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      className="MyformControl"
                      id="exampleInputEmail1"
                      placeholder="Price (NGN)"
                      aria-describedby="emailHelp"
                      ref={sellRegister({ required: "Field is required" })}
                      style={
                        toggleDarkMode
                          ? {
                              color: "white",
                              backgroundColor: "#495057",
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="quantity"
                      className="MyformControl"
                      placeholder="Quantity (Tons)"
                      id="exampleInputPassword1"
                      style={{
                        marginTop: -8,
                        color: toggleDarkMode ? "white" : "",
                        backgroundColor: toggleDarkMode ? "#495057" : "",
                      }}
                      ref={sellRegister({ required: "Field is required" })}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: -10 }}>
                    <label htmlFor="comment" className="customerTitle">
                      <span style={{ color: toggleDarkMode ? "white" : "" }}>
                        Comment
                      </span>
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        name="comment"
                        className="MyformControl"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        ref={sellRegister}
                        style={
                          toggleDarkMode
                            ? {
                                color: "white",
                                backgroundColor: "#495057",
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                  <button type="submit" className="submitSellBtn">
                    {sellLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      " Submit Sell Instruction"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CustomerTradeInstruction;
